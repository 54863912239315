/**
  * Forms
  * @description Common styles for forms across the platform
  **/


/** Styles for forms within the settings sublayout */
.settings-form {
  .ant-form-item {
    &-control {
      max-width: 22rem;

      &:has(.address-input) {
        max-width: 40rem;
      }

      &:has(.ant-form-item-explain-error),
      &:has(.ant-form-item-extra) {
        margin-bottom: 1rem;
      }

      &-input {
        .ant-input-affix-wrapper {
          &.ant-input-affix-wrapper-focused {
            border-color: var(--clr-border-info);
          }

          &.ant-input-affix-wrapper-status-error {
            border-color: var(--clr-border-danger);

            .ant-input-suffix {
              color: var(--clr-btn-fill-danger);
            }
          }
        }
      }
    }

    &-label {
      label {
        margin: 0;
        @include font-format(small, bold);
        align-content: center;
      }

      label.ant-form-item-required-mark-optional {
        @include between(xs, lg) {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: right;
          margin-right: 0.5rem;
        }

      }

      .ant-form-item-optional {
        @include font-format(small, regular);
        text-align: right;
      }
    }

    .ant-form-item-explain {
      &-error {
        color: var(--clr-text-danger);
      }
    }

    &-extra {
      @include font-format(small, regular);
    }

    &+.ant-row {
      margin-top: 3rem;
    }
  }
}

.ant-select-status-error {
  .ant-select-arrow {
    color: var(--clr-danger);
  }
}

.ant-notification {
  .ant-notification-notice {
    .anticon {
      &.ant-notification-notice-icon-success {
        color: var(--clr-btn-fill-success);
      }

      &.ant-notification-notice-icon-warning {
        color: var(--clr-btn-fill-warning);
      }

      &.ant-notification-notice-icon-danger {
        color: var(--clr-btn-fill-danger);
      }

      &.ant-notification-notice-icon-info {
        color: var(--clr-btn-fill-info);
      }
    }
  }
}

.ant-alert {
  &.ant-alert-info {
    background-color: var(--clr-bg-info);
    border-color: var(--clr-border-info);
    color: var(--clr-text-info);

    .anticon {
      color: var(--clr-border-info);
    }
  }
}


/** Overwrite Ant Design Header Border Radius Start */
:where(.css-dev-only-do-not-override-h0zdtt).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0px;
}

:where(.css-dev-only-do-not-override-h0zdtt).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0px;
}

:where(.css-dev-only-do-not-override-h0zdtt).ant-table-wrapper .ant-table .ant-table-header {
  border-radius: 0px;
}
/** Overwrite Ant Design Header Border Radius End */

/** Overwrite Ant Design Header Cell Left Border */
:where(.css-dev-only-do-not-override-h0zdtt).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0;
}

.aider-pagination.ant-pagination {
  @include font-format(small, regular);
  align-items: center;
  .ant-pagination-total-text {
    display: inline-flex;
    margin-inline: 1rem 0.5rem;
    align-items: center;
    @include font-format(small, regular);
  }
  .ant-pagination-item {
    a {
      @include font-format(small, regular);
    }

    &:hover {
      background-color: var(--clr-bg-blue);
    }
    @include font-format(small, regular);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-text-link);


    &-active {
      background-color: var(--clr-bg-blue-selected);
      border-color: var(--clr-bg-blue-selected);
      a {
        color: var(--clr-text-title);
        @include font-format(small, bold);
      }
    }
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    .ant-pagination-item-link {
      &:hover {
        background-color: var(--clr-bg-blue);
      }
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--clr-text-link);
      @include font-format(small, regular);
    }

    &.ant-pagination-disabled {
      .ant-pagination-item-link {
        &:hover {
          background-color: transparent;
        }
        color: var(--clr-text-disabled);
      }
    }
  }
}


.ant-tooltip {
  &.light-tooltip {
    background-color: var(--clr-bg-white);
  }
}

.aider-dropdown {
  .ant-dropdown-menu-item {
    &.ant-dropdown-menu-item-selected {
      .ant-dropdown-menu-title-content {
        background-color: inherit;
        color: var(--clr-text-title);
      }
      background-color: var(--clr-bg-blue-selected);
    }
    &.ant-dropdown-menu-item-selected:hover,
    &.ant-dropdown-menu-item-active:hover {
      background-color: var(--clr-bg-blue);
    }
  }
}
