$theme-colors: (
    'primary': #0A1E70,
    'secondary': #4263EA,
    'white': #FFF
);

// fonts
$font-family-base: 'Nunito', sans-serif;
$font-body: $font-family-base;
$font-heading: $font-family-base;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

.button:focus{
    background-color: inherit;
}
.btn-aider{
    padding: 16px 24px;
    height: 52px;
}
.btn-aider:focus{
    background-color: #4263EA;
}
.white-button{
    width: 130px;
    background-color: #FFF !important;
}
.white-button:hover{
    background-color: #4263EA !important;
}
.white-button:focus{
    background-color: #FFF;
}
.blue-button{
    width: 209px;
}
.blue-button:focus{
    background-color: #4263EA;
}

h1.diplay{
    font-size: 30px;
    line-height: 36px;
    color: #0A1E70;
    font-weight: 700;
}

.p-small{
    font-size: 14px;
    line-height: 20px;
}

.p-small.faded{
    font-weight: 400;
    color: #767677;
}

.p-light {
    color: $grey;
}

.TextCTA{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #4263EA;
}

.CTATab{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0A1E70;
}

.collapseCheckList{
    max-height: 0px;
    transition: all 0.25s ease-out;
    opacity: 0;
    z-index: -1;
    max-width: 160rem;
}

.collapseCheckList.opened{
    max-height: 2500px !important;
    transition: all 0.25s ease-in;
    opacity: 1;
    z-index: 0;
    max-width: 160rem;
}

.insightTrendText{
  max-width: min(60ch, 50%);
  display: flex; 
  flex-direction: column;
  gap: 1rem;
}

@import "~bootstrap/scss/bootstrap";
