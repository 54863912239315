.aider-modal {
  .ant-modal-body {
    .centered-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &:has(.navigation-modal) {
      padding: 0;
    }
  }

  &:has(.centered-modal) {
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      padding-bottom: 4rem;
    }
  }

  .ant-modal-footer {
    border-top: none;
  }

  &:has(.add-client-modal) {
    .ant-modal-footer {
      gap: 2rem;

      .ant-btn {
        width: 10ch;
      }
    }
  }

  .modal-illustration,
  .illustration {
    svg {
      width: 120px;
      height: 120px;
    }
  }

  .modal-illustration {
    margin-block: 2rem;
  }

  &.navigation-modal {
    display: flex;

    nav {
      display: flex;
      flex-direction: column;
      min-width: 200px;
      padding-right: 1rem;
      background-color: var(--clr-bg-blue);
      padding: 1rem;
      border-bottom-left-radius: 1rem;

      .nav-link {
        padding: 0.25rem 1.5rem 0.25rem 0.5rem;
      }
    }

    .tab-content {
      padding: 1rem;
      flex-grow: 1;
    }
  }

  &.advisory-assistant-modal {
    .ant-modal-body {
      padding: 0;
    }

    .advisory-ai {
      padding: 0;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;

      &__content {
        height: 60vh;
        min-height: 300px;

        &--main {
          border-width: 0;
          border-left-width: 1px;
        }
      }
    }
  }

  .client-settings {
    &__content {
      position: relative;

      &--main {
        padding-right: 1rem;
        max-height: 60vh;
        overflow: auto;
      }

      &--description {
        display: flex;
        justify-content: center;
        width: 80%;
        align-items: center;
        text-align: left;

        ul {
          margin-top: 1rem;
        }

        li,
        a {
          font-weight: 400;
          font-size: 16px;
        }
      }

      h3 {
        margin-bottom: 1rem;
      }

      h4 {
        background-color: var(--clr-bg-blue);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.3rem;
        border: solid 1px var(--clr-gray-4);
        border-radius: 0.25rem;
      }

      h3,
      h4,
      h5 {
        font-size: 20px;
      }

      h6 {
        text-transform: none;
      }

      .ant-row {
        &.bottom-align {
          margin-top: 1rem;
          align-items: flex-end;
        }

        margin-bottom: 1rem;
      }

      ul {
        @include font-format(footnote, regular);
        margin-bottom: 0;
        text-align: left;
      }

      .ant-form-item {
        margin-bottom: 0.5rem;

        .ant-row {
          margin-bottom: 0;
        }
      }

      .ant-form-item-label {
        padding-bottom: 0.25rem;

        label {
          @include font-format(small, bold);
        }
      }

      &--input {
        width: 100%;
      }

      .bankSelect,
      .superannuationSelect {
        width: 100%;
      }

      .bas-lodgement {
        border: solid 1px var(--clr-border-info);
        padding: 1rem;
        border-radius: 0.25rem;

        .ant-radio-wrapper {
          position: relative;

          .ant-radio {
            margin-top: 0.25rem;
            align-self: flex-start;
          }

          &-checked {

            h6,
            p {
              color: var(--clr-text-info);
            }
          }

          p {
            margin-bottom: 0;
          }
        }

        .ant-col {
          align-content: flex-start;
        }
      }

      &.remove-client {
        .client-settings__content--main {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          text-align: center;
          gap: 1rem;
          padding-block: 2rem;
        }

        .remove-client__terms {
          @include font-format(small, regular);
          width: 80%;
          padding: 0.5rem 1rem;
          background: var(--clr-bg-info);
          border: solid 1px var(--clr-border-info);
          color: var(--clr-text-main);
          border-radius: 0.25rem;
        }
      }

      footer {
        position: sticky;
        background: var(--clr-bg-white);
        padding-top: 1rem;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 1rem;
      }
    }
  }

  &.rule-modal {
    &__new {
      .ant-modal-body {
        background-color: var(--clr-bg-blue);
      }
    }

    &__delete {
      .rule-modal__body {
        padding-top: 1rem;
      }

      .ant-modal-footer {
        padding-bottom: 2rem;
      }
    }

    .rule-modal {
      &__title {
        display: flex;
        gap: 0.5rem;
      }

      &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;

        h3 {
          @include font-format(h3, bold);
        }
      }
    }

    .rule-form {
      max-height: 80vh;

      .ant-form-item {
        margin-bottom: 0px;

        &-label {
          label {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5rem;
            color: var(--clr-text-title);
          }
        }

        &.hidden-label {
          label {
            color: transparent;
          }
        }
      }

      .ant-input-prefix {
        color: var(--clr-text-primary);
      }
    }
  }

  .paywall-modal {
    &__body {
      h3 {
        @include font-format(h3, bold);
      }

      padding: 4rem;
      gap: 1rem;
    }
  }

  &.client-advisor-contacts-modal {
    .advisor-contacts {
      &__header {
        display: flex;
        justify-content: space-between;

        h2 {
          @include font-format(h4, semibold);
        }

        img {
          height: 150px;
        }
      }

      &__table {
        max-height: 40svh;
      }

      &__footer {
        display: flex;
        padding-block: 1rem;
        justify-content: space-between;
      }
    }
  }
}

.add-client-modal {
  gap: 1rem;
  padding: 4rem 2rem 0rem 2rem;

  h2 {
    margin-top: 2rem;
    @include font-format(h2, bold);
  }
}

.aider-rule-dropdown {
  .ant-select-tree-node-content-wrapper {
    position: relative;
  }

  .ant-select-tree-treenode-disabled:hover {
    .ant-select-tree-node-content-wrapper {
      pointer-events: none;

      &::after {
        content: 'This has been selected by default, to remove it, please deselect the parent category';
        position: absolute;
        left: 0;
        top: 2.2em;
        background-color: var(--clr-gray-13);
        background-color: color-mix(in srgb,
            var(--clr-gray-13) 80%,
            transparent);
        color: var(--clr-text-darkmode);
        @include font-format(footnote, light);
        padding: 0.5rem;
        border-radius: 0.25rem;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 0.2rem;
        height: 0.2rem;
        left: 0.5rem;
        top: 0.75em;
        border: 0.5rem solid transparent;
        border-bottom-color: var(--clr-gray-13);
        border-bottom-color: color-mix(in srgb,
            var(--clr-gray-13) 80%,
            transparent);
      }
    }
  }
}
.report-assistant-modal {
  .ant-modal-body {
    padding: 0;
    height: 80vh;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    justify-content: space-between;
    gap: 1rem;
  }
  .ant-modal-footer {
    margin-top: 1rem;
  }

  &__header {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    border-bottom: solid 1px var(--clr-border-divider);
    @include font-format(h3, regular);
    color: var(--clr-text-title);

    &--dropdown {
      display: flex;
      gap: 0.5em;
      cursor: pointer;

      color: var(--clr-text-blue);
      @include font-format(h3, regular);

      .anticon {
        @include font-format(h5, regular);
      }
    }

  }
}

.advisory-llm-modal {
  display: flex;
  gap: 1rem;
  flex-shrink: 1;
  flex-grow: 1;
  justify-content: space-between;
  align-items: space-between;
  padding: 0 1rem;
  max-height: 100%;
  overflow: scroll;

  &__footer {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    min-height: 12rem;
    background-color: var(--clr-bg-blue);
    border-top: solid 1px var(--clr-border-divider);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .place-center {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;

    .assistant-loader {
      gap: 1rem;
    }
  }


  .aider-alert {
    flex-grow: 0;
    flex-shrink: 1;
    position: relative;
    &__content--body {
      height: calc(100% - 1rem);
      padding: 3.5rem 0 1rem;
      max-width: initial;

      .scrollbar {
        height: 100%;
        overflow-y: auto;
      }
    }
  }

  &__button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__checkbox {
    position: absolute;
    top: 60px;
    right: 10px;
  }
}

.insight-graph-modal {
  .ant-modal {
    width: 50% !important;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    margin-top: 1rem;
  }
}

.add-insight-graph-modal {
  height: 80%;
  display: flex;
  flex-direction: column;

  &__body {
    padding: 1rem;
    flex-grow: 1;
    margin-bottom: 1rem;
  }

  &__footer {
    padding: 1rem;
    background-color: var(--clr-bg-blue);
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top: solid 1px var(--clr-border-blue);
  }
}

.save-as-template-modal {
  & .ant-form-item-required::before {
    visibility: hidden;
  }

  & .ant-form-item-control-input .ant-input-group-addon {
    background-color: var(--clr-gray-2);
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.cancelButton {
  border-color: #1890ff;
  color: #1890ff;
}

.okButton {
  color: #ffffff;
}
