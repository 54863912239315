.main {
  &__content {

    &:has(.client-management),
    &:has(.period-close) {
      .client-management {
        max-height: calc(100svh - 150px);
        overflow-y: auto;
        height: 100%;
        flex-grow: 1;

        .ant-table-row {

          .ant-table-cell:first-child,
          .ant-table-cell:nth-child(2) {
            background-color: var(--clr-bg-blue);
          }
        }
      }

      .period-close {
        display: flex;
        flex-direction: column;
        max-height: 100svh;
        flex-grow: 1;

        &__sort-wrapper {
          .ant-btn.ant-btn-default.button-secondary {
            width: 100%;
            justify-content: flex-start;
            --_border-color: var(--clr-text-white);

            .anticon {
              //  font-size: 1.3rem;
            }

            .dashboard-subheading__sorter {
              display: flex;
              flex-direction: column;
              justify-content: center;

              .anticon {
                font-size: 0.5rem;
              }
            }
          }
        }

        .ant-table-thead {
          tr:first-child {
            .ant-table-cell {
              @include font-format(body, semibold);
            }
          }

          .ant-table-cell {
            align-content: flex-start;
          }
        }

        &__dashboard-group-header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 0.5rem;
        }

        &__status-cell {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          @include font-format(body, semibold);


          &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.1rem;
            flex: 1;

            &>span {
              display: flex;
              gap: 0.5rem;
              flex-wrap: no-wrap;
            }

            &--start {
              justify-content: flex-start;
            }

            &--end {
              justify-content: flex-end;
            }

            &--percentage {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.5rem;
              width: calc(100% - 7ch);

              progress {
                width: calc(100% - 5ch);
              }

              span,
              &+span {
                @include font-format(small, regular);
              }

            }
          }

          .anticon {
            color: var(--clr-text-title);
            font-size: 1.1rem;
          }

          &.cell-success {
            .anticon {
              color: var(--clr-btn-fill-success);
            }
          }


          progress {
            --_height: 0.6rem;
            --_radius: calc(var(--_height) / 2);
            height: var(--_height);
            border-radius: var(--_radius);
            border: solid 1px var(--clr-border-divider);
          }

          progress::-webkit-progress-bar {
            background-color: var(--clr-bg-blue);
            border-radius: var(--_radius);
          }

          progress::-webkit-progress-value {
            background-color: var(--clr-btn-fill-success);
            border-radius: var(--_radius);
          }

          progress::-moz-progress-bar {
            /* style rules */
          }
        }

        &__body-cell {
          min-width: 150px;
        }

        &__data-cell {
          display: flex;
          justify-content: center;
          gap: 0.75rem;
          align-items: center;
          flex-grow: 0;
        }

        &__body-cell {
          &:has(.cell-danger) {
            background: var(--clr-bg-danger);
          }

          &:has(.cell-success) {
            background: var(--clr-bg-success);
          }
        }

        .compliance-subheader {
          flex-wrap: wrap;

          &__text {
            width: 90%;
            text-wrap: wrap;
          }
        }

        &__body {
          padding-top: 1rem;
          height: 100%;

          overflow: hidden;

          .ant-table-wrapper {
            max-height: 100%;
          }
        }

        .business-name {
          display: flex;
          height: 100%;
          justify-content: space-between;

          .business-search {
            max-width: 85%;
          }

          .column-sort {
            font-size: 1.8rem;
            color: var(--clr-primary-4);

            &.active {
              color: var(--clr-text-white);
            }
          }
        }

        .ant-table-body {
          tr {
            .ant-table-cell:first-child {
              background-color: var(--clr-bg-blue);
            }
          }

          .ant-table-cell {

            &:has(.cell-danger) {
              background: var(--clr-bg-danger);
            }

            &:has(.cell-success) {
              background: var(--clr-bg-success);
            }
          }

        }
      }

      .client-management,
      .period-close {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        h1 {
          @include font-format('h2', 'bold');
        }

        .ant-table-cell {
          border-radius: 0;

          &:has(.aider-column__logos) {
            & > span {
              display: flex;
            }
          }

          &:before {
            display: none;
          }
        }

        .dashboard-subheading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include font-format(body, regular);



          &__text {
            width: 90%;
            text-wrap: wrap;
          }

          &__centered {
            .dashboard-subheading__text {
              width: auto;
            }

            justify-content: center;
            gap: 1rem;
          }

          .anticon {
            color: var(--clr-primary-4);
          }

          &.active {
            .anticon {
              color: var(--clr-text-white);
            }
          }

          &__sorter {
            font-size: 0.8rem;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: var(--clr-primary-4);

            .active {
              color: var(--clr-text-white);
            }
          }
        }

        .business-name-button {
          padding: 0;
          justify-content: flex-start;
          align-items: center;
          max-width: 100%;
          border-radius: 0;
          @include font-format(regular, semibold);

          span {
            white-space:initial;
            flex-wrap: nowrap;
            overflow-x: hidden;
            width: 100%;
          }

          &__data-status {
            display: flex;
            justify-content: space-between;

            &__updated {
              color: var(--clr-text-title);
              @include font-format(small, regular);
              display: flex;
              gap: 0.25rem;
              align-items: center;
            }

            &__disconnected {
              color: var(--clr-text-danger);
              @include font-format(small, regular);
              display: flex;
              align-items: center;
              & .osp__logo {
                font-size: 1.5em;
                padding-right: 5px;
              }
            }


            .button-secondary {
              font-size: 0.9rem;
              height: 1.5rem;
              color: var(--clr-text-blue);
              border-color: var(--clr-text-blue);
            }

            .dashboard-data-status {
              color: var(--clr-text-blue);
            }
          }
        }

        .ant-table-column-title {
          flex-grow: 1;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }

        .ant-table-column-sorters,
        .ant-table-filter-column {
          color: var(--clr-text-white);
          align-items: flex-center;

          .ant-dropdown-trigger {
            .anticon-filter {
              color: var(--clr-primary-4);
            }

            &.active {
              .anticon-filter {
                color: var(--clr-text-white);
              }
            }
          }
        }

        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
          color: var(--clr-primary-4);

          &.active {
            color: var(--clr-text-white);
          }
        }

        .client-header {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;

          @include not-respond-to(xl) {

            .client-count {
              max-width: 50%;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }

            .button-secondary {
              .ant-btn-icon {
                margin-right: 0;
              }

              .import-text {
                display: none;
              }
            }
          }
        }

        .button-secondary {
          --_bg-color: transparent;
          --_text-color: var(--clr-text-white);
          --_border-color: var(--clr-text-white);

          &:hover {
            --_bg-color: var(--clr-bg-blue-selected);
            --_text-color: var(--clr-text-title);
            --_border-color: var(--clr-bg-blue-selected);
          }
        }

        .business-name-button {
          color: var(--clr-text-title) !important;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: break-spaces;
          text-align: left;
          height: auto;
          vertical-align: top;
        }

        // Move the checkbox to the bottom of the column header
        .ant-table th.ant-table-selection-column {
          vertical-align: bottom;
        }

        .ant-table th.ant-table-cell {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .ant-table-wrapper {
          flex-grow: 1;

          .ant-table-thead {
            background: var(--clr-bg-title);

            tr {
              background: var(--clr-bg-title);

              .aider-column {
                background: var(--clr-bg-title);
                border-right: solid 3px var(--clr-border-grid-th);
                overflow: hidden;
              }
            }

            .ant-table-cell,
            .ant-table-column-sort {
              padding: 0.8rem;
              color: var(--clr-text-white);
              background: var(--clr-bg-title);
              border: none;
            }
          }

          .ant-table-body {
            .aider-column {
              border-right: solid 3px var(--clr-border-grid-td);

              &:has(.business-name-button) {
                border-color: var(--clr-border-grid-th);
              }

              &__logos {
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 1rem;
                min-width: 1.6rem;
                font-size: 1.3em;

                & + span {
                  white-space: initial;
                  overflow-wrap: break-word;
                }

                .anticon {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  inset: 0;
                  z-index: 2;
                  transition: transform 200ms ease;
                  transform: translateY(10%);

                  &+.anticon {
                    transform: translateY(-20%);
                    z-index: 1;
                  }

                }

                &:has(.anticon + .anticon):hover {
                  .anticon {
                    transform: translateY(50%);

                    &+.anticon {
                      transform: translateY(-50%)
                    }
                  }
                }
              }

              &__linked:hover {
                cursor: pointer;
              }

              &__activation {
                display: flex;
                justify-content: space-between;

                .activated-icon {
                  color: var(--clr-btn-fill-success);
                  font-size: 1.6rem;
                }

                .secondary-icon {
                  font-size: 1.4rem;
                  color: var(--clr-btn-line-teal);
                }

                .primary-icon {
                  font-size: 1.4rem;
                  color: var(--clr-btn-line-link);
                }

                .disconnected {
                  color: var(--clr-text-danger);
                  font-size: 15px;
                }

                .reconnect-xero {
                  width: 150px;
                  --_bg-color: var(--clr-xero);
                  &.button-secondary {
                    --_border-color: none;
                    --_text-color: rgb(from var(--clr-text-white) r g b / 0.8);
                    height: 1.5rem;
                    font-size: 2.1rem;
                    padding: 0;
                    min-width: 0;
                    width: min-content;
                  }
                }
              }
            }

            .ant-table-cell {
              padding: 12px;
              font-size: 0.9rem;
              text-transform: capitalize;
              border-bottom: solid 3px var(--clr-border-grid-td);

              &>.ant-btn {
                font-size: 0.9rem;
              }
            }

            .ant-table-row:not(:last-child) {
              .ant-table-cell {

                &:has(.business-name-button),
                &.ant-table-selection-column {
                  border-color: var(--clr-border-grid-th);
                }
              }
            }
          }

          .ant-table-summary {
            .bulk-tagging-row {
              .ant-table-cell {
                background-color: var(--clr-bg-warning);
                border-bottom: 3px solid var(--clr-border-grid-th);
                padding: 5px 12px;

                &.aider-column {
                  border-right: 3px solid var(--clr-border-grid-td);
                }

                &:nth-child(2) {
                  @include font-format(body, semibold);
                  color: var(--clr-text-title);
                  text-transform: uppercase;
                }

                .ant-select {
                  display: block;
                }

                &>.anticon {
                  color: var(--clr-text-title);
                  font-size: 19px;
                }

                .ant-select-multiple {
                  .ant-select-selection-item {
                    display: flex;
                    align-items: center;
                    border-radius: 1rem;
                    padding: 0.05rem 0.5rem;
                    margin: 0.01rem;
                    margin-right: 0.5rem;
                    @include font-format(small, bold);
                    background-color: var(--clr-text-blue);
                    color: var(--clr-bg-white);
                    border: solid 1px var(--clr-border-info);
                    height: auto;

                    .ant-select-selection-item-remove {
                      color: var(--clr-bg-white);
                    }
                  }
                }
              }
            }
          }

          .ant-table-row-selected {
            .ant-table-cell {
              background-color: var(--clr-bg-white);
            }
          }
        }

        &__header {
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &--links {
            display: flex;
            flex-direction: column;
          }
        }

        &__footer {
          display: flex;
          gap: 10px;
          justify-content: space-between;
          position: sticky;
          bottom: 0;
          background: var(--clr-bg-title);
          padding: 1rem;

          &--remove-group {
            flex-grow: 1;
            justify-content: end;
            display: flex;
            gap: 1rem;
            align-items: center;
          }
        }
      }
    }
  }
}

.dashboard-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8em;
  flex: 0;
  height: 1.6em;
  @include font-format(body, bold);
  color: var(--clr-text-white);

  width: auto;
  min-width: auto;
  padding-inline: 0.5em;

  &--small {
    @include font-format(small, bold);
  }

  &--danger {
    background-color: var(--clr-btn-fill-danger);
  }

  &--muted {
    background-color: var(--clr-btn-line-disabled);
  }
}

.ant-tooltip:has(.period-close__tooltip-wrapper) {
  --bg_color: color-mix(in srgb, var(--clr-text-white) 95%, transparent);
  --antd-arrow-background-color: var(--bg_color);
  @include font-format(small, regular);
  max-width: 47ch;

  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: var(--bg_color);

      .tooltip-header {
        color: var(--clr-text-title);
        @include font-format(body, semibold);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.5rem;
      }

      .period-close {
        &__tooltip-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          color: var(--clr-text-aux);

          .anticon {
            font-size: 0.8rem;
            margin-inline-end: 0;
          }

          &--header {
            .button-secondary {
              width: 100%;
              white-space: break-spaces;
            }
          }

          .button-secondary {
            @include font-format(small, regular);
            padding: 0 0.5rem;
          }

          .button-comment-group {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-grow: 1;

            .comment-count {
              width: 50px;
            }

            .compliance-tooltip-button {
              width: calc(100% - 50px);

              .anticon {
                font-size: 0.8rem;
              }

              span:not(.ant-btn-icon) {
                max-width: 95%;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }

  .ant-btn.ant-btn-default.button-secondary {
    justify-content: flex-start;
    --_border-color: none;
    --_bg-color: transparent;

    &.active {
      --_bg-color: var(--clr-bg-blue-selected);
      --_text-color: var(--clr-text-title);
    }

    &:hover,
    &:active {
      --_bg-color: var(--clr-btn-fill-link);
      --_text-color: var(--clr-text-white);
    }

    .anticon {
      font-size: 1.3rem;
    }

    .dashboard-subheading__sorter {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0;

      .anticon {
        margin: 0;
        padding: 0;
        color: var(--clr-primary-4);
        font-size: 0.7rem;

        &.active {
          color: inherit;
        }
      }
    }
  }
}

.import-button {
  &__dropdown {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        h6 {
          margin: 0;
        }

        &:hover {
          background-color: transparent;
          cursor: default;
          ;
        }
      }
    }
  }
}
