.tier__confirm__form {
  @extend .flex--column;
  row-gap: 2rem;

  .input__wrapper {
    @extend .flex--space;
    column-gap: 1.5rem;

    .input {
      width: calc(50% - 1.5rem);
    }
  }
  .blue-button,
  .white-button {
    width: 10rem;
  }
}

.config-form {
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 1.6rem;
}
