.ant-btn {
  --border-width: 2px;
  --_border-style: solid;
  &-default,
  &-primary {
    &.button-primary {
      --_bg-color: var(--clr-btn-fill-link);
      --_text-color: var(--clr-text-white);
      --_border-color: var(--clr-btn-fill-link);

      &:hover,
      &:active {
        --_border-color: var(--clr-btn-fill-hover);
        --_bg-color: var(--clr-btn-fill-hover);
        --_text-color: var(--clr-btn-fill-white);
      }

      &.ant-btn-dangerous {
        --_text-color: var(--clr-btn-fill-white);
        --_bg-color: var(--clr-btn-fill-danger);
        --_border-color: var(--clr-border-danger);

        &:hover,
        &:active {
          --_border-color: var(--clr-border-danger-hover);
          --_bg-color: var(--clr-btn-fill-danger-hover);
          --_text-color: var(--clr-btn-fill-white);
        }
      }

      &.warning-button {
        --_text-color: var(--clr-btn-fill-white);
        --_bg-color: var(--clr-btn-fill-warning);
        --_border-color: var(--clr-border-warning);

        &:hover,
        &:active {
          --_border-color: var(--clr-text-warning);
          --_bg-color: var(--clr-text-warning);
          --_text-color: var(--clr-btn-fill-white);
        }
      }
      &:disabled,
      &.ant-btn-disabled,
      &.greyed-out:not(:disabled):not(.ant-btn-disabled) {
        --_bg-color: var(--clr-btn-fill-disabled);
        --_text-color: var(--clr-btn-fill-white);
        --_border-color: var(--clr-btn-fill-disabled);

        &:hover,
        &:active {
          --_bg-color: var(--clr-text-disabled);
          --_text-color: var(--clr-btn-fill-white);
          --_border-color: var(--clr-text-disabled);
        }
      }


      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &.nav-link {
        justify-content: flex-start;
      }
      flex-grow: 0;


      &:not(:disabled):not(.ant-btn-disabled), & {
        background-color: var(--_bg-color);
        color: var(--_text-color);
        border: var(--border-width) var(--_border-style) var(--_border-color);

        &:hover,
        &:active {
          background-color: var(--_bg-color);
          color: var(--_text-color);
          border: var(--border-width) var(--_border-style) var(--_border-color);
        }
      }


    }

    &.button-secondary {
      --_bg-color: var(--clr-bg-white);
      --_text-color: var(--clr-text-blue);
      --_border-color: var(--clr-text-blue);

      &:hover,
      &:active {
        --_bg-color: var(--clr-bg-blue-selected);
        --_text-color: var(--clr-btn-fill-hover);
        --_border-color: var(--clr-btn-fill-hover);
      }

      &.ant-btn-dangerous {
        --_bg-color: var(--clr-bg-white);
        --_text-color: var(--clr-btn-fill-danger-hover);
        --_border-color: var(--clr-btn-fill-danger);

        &:hover,
        &:active {
          --_border-color: var(--clr-border-danger-hover);
          --_bg-color: var(--clr-bg-danger);
          --_text-color: var(--clr-btn-fill-danger-hover);
        }
      }

      &.warning-button {
        --_bg-color: var(--clr-bg-white);
        --_text-color: var(--clr-btn-fill-warning-hover);
        --_border-color: var(--clr-border-warning);
        &:hover,
        &:active {
          --_border-color: var(--clr-text-warning);
          --_bg-color: var(--clr-bg-warning);
          --_text-color: var(--clr-text-warning);
        }
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &.nav-link {
        justify-content: flex-start;
      }

      &:not(:disabled):not(.ant-btn-disabled),
      & {
        background-color: var(--_bg-color);
        color: var(--_text-color);
        border: var(--border-width) var(--_border-style) var(--_border-color);

        &:hover,
        &:active {
          background-color: var(--_bg-color);
          color: var(--_text-color);
          border: var(--border-width) var(--_border-style) var(--_border-color);
        }
      }

      &:disabled,
      &.ant-btn-disabled,
      &.greyed-out:not(:disabled):not(.ant-btn-disabled) {
        --_bg-color: var(--clr-bg-disabled);
        --_text-color: var(--clr-text-disabled);
        --_border-color: var(--clr-text-disabled);

        &:hover,
        &:active {
          --_bg-color: var(--clr-bg-disabled);
          --_text-color: var(--clr-text-disabled);
          --_border-color: var(--clr-text-disabled);
        }
      }

      &.inverted-colors {
        --_bg-color: var(--clr-text-blue);
        --_text-color: var(--clr-bg-white);
        --_border-color: var(--clr-bg-white);

        &:disabled,
        &.ant-btn-disabled,
        &.greyed-out:not(:disabled):not(.ant-btn-disabled) {
          --_text-color: var(--clr-bg-white);
          .anticon {
            color: var(--clr-bg-white);
          }
        }

        &:hover,
        &:active {
          --_bg-color: var(--clr-bg-blue-selected);
          --_text-color: var(--clr-text-title);
          --_border-color: var(--clr-bg-blue-selected);
        }
      }

      &.reversed {
        flex-direction: row-reverse;
        .ant-btn-icon {
          margin-inline-start: 0.5rem;
          margin-inline-end: 0;
        }
      }

      &.title-colors {
        --_text-color: var(--clr-text-main);
        --_border-color: var(--clr-text-title);

        &:disabled,
        &.ant-btn-disabled,
        &.greyed-out:not(:disabled):not(.ant-btn-disabled) {
          --_text-color: var(--clr-bg-white);
          .anticon {
            color: var(--clr-bg-white);
          }
        }

        &:hover,
        &:active {
          --_bg-color: var(--clr-bg-white);
          --_text-color: var(--clr-text-title);
          --_border-color: var(--clr-primary-5);
          --_border-width: 1px;
          --_border-style: inset;
        }
      }


      &.ant-btn-background-ghost {
        --_bg-color: transparent;

        &:hover,
        &:active {
          --_bg-color: var(--clr-bg-blue-selected);
        }
      }

      &.generative-button {
        width: 300px !important;
        justify-content: left;
      }
    }

    &.btn-hidden {
      display: none;
    }
  }

  &.rule-button {
    &__edit {
      .anticon {
        font-size: 1.4rem;
      }
      --border-width: 0px;
    }
  }

  &.ant-btn-link {
    &:not(:disabled):not(.ant-btn-disabled) {
      &.button-primary,
      &.button-secondary {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-grow: 0;

        &.nav-link {
          justify-content: flex-start;
        }

      }

      &.button-primary {
        &:has(.ant-btn-icon){
          flex-direction: row-reverse;
          .anticon {
            font-size: 0.8em;
          }
          gap: 0.2em;
        }
      }

      color: var(--clr-btn-fill-link);

      &:hover,
      &:active {
        color: var(--clr-btn-fill-hover);
      }
    }
    &.ant-btn-dangerous {
      color: var(--clr-btn-fill-danger);
      &:hover,
      &:active {
        color: var(--clr-text-danger);
      }
    }

    &.warning-button {
      color: var(--clr-btn-fill-warning);
      &:hover,
      &:active {
        color: var(--clr-text-warning);
      }
    }

    &:disabled,
    &.ant-btn-disabled,
    &.greyed-out:not(:disabled):not(.ant-btn-disabled) {
      color: var(--clr-btn-fill-disabled);
      &:hover,
      &:active {
        color: var(--clr-text-disabled);
      }
    }

    &.nav-link {
      padding-inline: 1.5rem;
      display: flex;
      border-radius: 0.25rem;
      width: 100%;
      justify-content: flex-start;
      text-align: left;
      &.active {
        background: var(--clr-bg-blue-selected);
        color: var(--clr-text-title);
        @include font-format(small, bold);
      }

      &:not(.active) {
        @include font-format(small, regular);
        color: var(--clr-text-main);
        &:hover,
        &:active {
          background: var(--clr-bg-blue);
        }
      }
    }
  }

  &.borderless {
    &.button-primary,
    &.button-secondary {
      &:disabled {
        --_border-color: transparent;
        --_bg-color: transparent;
      }
      --_border-color: transparent;
      --_bg-color: transparent;

      &.ant-btn-icon-only {
        padding-block: 0;
        min-width: auto;
        padding: 0;
        .anticon {
          font-size: 1.2em;
        }
      }

      &.save-button {
        color: var(--clr-green-5);

        &:hover {
          color: var(--clr-green-7);
        }
      }
    }
  }

  &.no-padding {
    padding: 0;
  }

  &.btn-link {
    text-decoration: none;
  }
}

.button-dropdown {
  .ant-btn {
    border-radius: 1em;
  }
  &.button-secondary {
    .ant-btn {
      --_bg-color: var(--clr-bg-white);
      --_text-color: var(--clr-text-blue);
      --_border-color: var(--clr-text-blue);
      padding-inline: 1rem 0.5rem;
      background-color: var(--_bg-color);
      color: var(--_text-color);
      border: var(--border-width) var(--_border-style) var(--_border-color);

      &:hover,
      &:active {
        --_bg-color: var(--clr-bg-blue-selected);
        --_text-color: var(--clr-btn-fill-hover);
        --_border-color: var(--clr-btn-fill-hover);
      }

      &:last-child {
        padding-left: 0.3rem;
        border-left: 0;
      }

      &:disabled,
      &.ant-btn-disabled,
      &.greyed-out:not(:disabled):not(.ant-btn-disabled) {
        --_bg-color: var(--clr-bg-disabled);
        --_text-color: var(--clr-text-disabled);
        --_border-color: var(--clr-text-disabled);

        &:hover,
        &:active {
          --_bg-color: var(--clr-bg-disabled);
          --_text-color: var(--clr-text-disabled);
          --_border-color: var(--clr-text-disabled);
        }
      }
    }
  }
}
