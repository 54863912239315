.bounce-ellipsis {
  color: var(--clr-btn-fill-white);

  .first, .second, .third {
    animation: smallBounce 1s steps(5, end) infinite;
  }
  .second {
    animation-delay: 25ms;
  }
  .third {
    animation-delay: 50ms;
  }

}
