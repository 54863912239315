.client-disconnect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 2rem;

  text-align: center;

  &__illustration {
    width: 120px;
  }

  h1 {
    @include font-format(h4, bold);
  }

  h2 {
    @include font-format(h5, bold);
  }

}
