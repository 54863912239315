.overviewScreen {
  height: 100vh;

  &__main {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 999;
    min-width: 1100px;
    width: 99%;
  }

  &__loading {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    max-width: 96vw;
    overflow: auto;
    margin-left: 1vw;

    &--none {
      padding-top: 85px;
      padding-left: 155px;
      padding-right: 0;
      height: 90vh;

      &__list {
        text-align: left;

        li {
          list-style-type: initial;
        }
      }
    }
  }

  &__topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    min-height: 90px;
    padding: 0 5px;

    &__add {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      color: #8e8e8e;
      background-color: #fff;
      height: 45px;
      width: 45px;
      min-width: 45px;
      border: 1px solid #e8e7ee;
      border-radius: 50%;
      cursor: pointer;
    }

    &__add__icon {
      font-size: 32px;
      font-weight: 100;
      color: $blue;
    }

    &__filter {
      display: flex;
      align-items: center;
      gap: 10px;

      &__title {
        color: $darkBlue;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  &__tags {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 0.75rem 0 0.75rem 5rem;
    flex-wrap: wrap;
  }

  &__table {
    &--blur {
      opacity: 0.6;
    }

    &__header {
      background-color: $blue;
      border-bottom: none;
      &__row {
        color: #fff;
        font-size: 14px;
        height: 30px;
        padding-right: 20px;
      }
      &__row--sub {
        height: 70px;
        padding-right: 20px;

        .subheader {
          & > .flex-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 1rem 1.5rem 1rem 1rem;
            height: 100%;

            & > .bp-select {
              width: 25px !important;
              margin-top: 1rem;
            }
          }
        }

        .overviewTableHeader__childWrapper {
          display: flex;
          justify-content: space-around;
        }

        .b-left {
          border-left: solid 2px $white;
        }

        .b-right {
          border-right: solid 2px $white;
        }
      }
      &__column {
        text-align: left;
        padding-left: 25px;
      }
    }

    &__row {
      border-bottom: 1px solid $lightCream;
      width: 100%;
      max-height: 60px;
      height: 60px;
      cursor: pointer;

      .overviewCell__title--error {
        width: 23.6%;
        background-color: $lightBlue;
        color: $darkBlue;
        font-weight: bold;
        border: 2px solid white;
        padding-left: 1.25rem;

        svg {
          margin-right: 0.5rem;
        }
      }
      .overviewCell__message--error {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        background-color: $lightCream;
        pointer-events: none;
        p {
          white-space: pre-wrap;
          margin: 0;
        }
        span {
          font-size: 0.8rem;
        }
      }
    }
  }

  &__body {
    position: relative;
    &__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
