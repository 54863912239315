speech-bubble__arrow_left {
  bottom: -20px;
  position: absolute;
  left: 8px;

  &::before {
    border-right: 23px solid transparent;
    border-top: 23px solid #fff;
    bottom: 10px;
    content: "";
    position: absolute;
    right: -15px;
  }
  &::after {
    border-right: 21px solid transparent;
    border-top: 21px solid #fff;
    bottom: 10px;
    content: "";
    position: absolute;
    right: -14px;
  }
}
.speech-bubble-ds_left {
  margin-top: 60px;
  background: #f5f4fa;
  border-bottom: 1px solid #d5d7ea;
  border-radius: 12px;
  box-shadow: -4px 2px 2px 0 #d5d7ea;
  position: relative;

  p {
    margin-bottom: 10px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
}
.speech-bubble-ds_left_loader {
  margin-top: 60px;
  background: #fff;
  height:40px !important;
  width:150px !important;
  border-bottom: 1px solid #d5d7ea;
  border-radius: 12px;
  box-shadow: -4px 2px 2px 0 #d5d7ea;
  position: relative;

  p {
    margin-bottom: 10px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
}

.noShadow{
  box-shadow: none !important;

}

.speech-bubble-ds__arrow_left {
  bottom: -20px;
  position: absolute;
  left: 8px;
  box-shadow: -7px -18px 3px 5px #d1d7f3;

  &::before {
    border-right: 23px solid transparent;
    border-top: 23px solid #f5f7ff;
    bottom: 6px;
    content: "";
    position: absolute;
    right: -15px;
  }
  &::after {
    border-right: 21px solid transparent;
    border-top: 21px solid #f5f7ff;
    bottom: 6px;
    content: "";
    position: absolute;
    right: -14px;
  }
}
.speech-bubble-ds__arrow_left_metaSpeech {
  bottom: -20px;
  position: absolute;
  left: 8px;
  box-shadow: -7px -18px 3px 5px #d5d7ea;

  &::before {
    border-right: 23px solid transparent;
    border-top: 23px solid #f7f7f7;
    bottom: 10px;
    content: "";
    position: absolute;
    right: -15px;
  }
  &::after {
    border-right: 21px solid transparent;
    border-top: 21px solid #f7f7f7;
    bottom: 10px;
    content: "";
    position: absolute;
    right: -14px;
  }
}
.speech-bubble-ds__arrow_left_loader {
  bottom: -20px;
  position: absolute;
  right: 142px;
  box-shadow: -7px -18px 3px 5px #d5d7ea;

  &::before {
    border-right: 23px solid transparent;
    border-top: 23px solid #fff;
    bottom: 10px;
    content: "";
    position: absolute;
    right: -15px;
  }
  &::after {
    border-right: 21px solid transparent;
    border-top: 21px solid #fff;
    bottom: 10px;
    content: "";
    position: absolute;
    right: -14px;
  }
}

.spinner1 {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner1 > div {
  width: 12px;
  height: 12px;
  background-color: #4264ea;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner1 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner1 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}