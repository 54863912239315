.main {
  .page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;


    &--brand {
      font-size: 4.9rem;
      margin-bottom: 4.9rem;
    }

    &--warning {
      font-size: 7.5rem;
      margin-bottom: 3rem;
    }

    h1 {
      @include font-format(h1, semibold);
      text-align: center;
      margin-bottom: 0.5em;
    }
  }

  .errorCode {
    max-height: 500px;
    overflow: auto;
    white-space: pre;
    font-family: 'Consolas';
    background: var(--clr-gray-3);
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
  }
}
