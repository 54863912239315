.ant-notification {
  --color: var(--clr-primary-6);
  --bg-color: var(--clr-primary-1);


  &-notice-icon {
    .anticon {
      color: var(--color);
    }
  }

  .notification {
    font-family: $font-family;
    background: var(--bg-color);
    border: solid 1px var(--color);

    &.ant-notification-notice {
      .ant-notification-notice-message {
        color: var(----clr-text-main);
        @include font-format(body, bold);
      }
      .ant-notification-notice-description {
        color: var(----clr-text-main);
      }
    }

    &__title-only {
      &.ant-notification-notice {
        .ant-notification-notice-message {
          margin-bottom: 0;
        }
      }
    }

    &__error {
      --color: var(--clr-text-danger);
      --bg-color: var(--clr-bg-danger);
    }

    &__warning {
      --color: var(--clr-text-warning);
      --bg-color: var(--clr-bg-warning);
    }

    &__success {
      --color: var(--clr-text-success);
      --bg-color: var(--clr-bg-success);
    }
  }
}
