:root {
  // Primary color palette
  --clr-primary-1: #F0F3FE;
  --clr-primary-2: #ebf1ff;
  --clr-primary-3: #c2d4ff;
  --clr-primary-4: #99b4ff;
  --clr-primary-5: #6d8df7;
  --clr-primary-6: #4263EA;
  --clr-primary-7: #3B52B4;
  --clr-primary-8: #1c2e9e;
  --clr-primary-9: #101a78;
  --clr-primary-10:#0A1E70;

  // Secondary Color Palette
  --clr-secondary-1: #E0FBFB;
  --clr-secondary-2: #a3fff3;
  --clr-secondary-3: #7afff2;
  --clr-secondary-4: #50faef;
  --clr-secondary-5: #26ede7;
  --clr-secondary-6: #00e0e1;
  --clr-secondary-7: #03BABB;
  --clr-secondary-8: #008a94;
  --clr-secondary-9: #00636e;
  --clr-secondary-10:#003e47;

  // Neutral Color Palette
  --clr-gray-1: #ffffff; // White
  --clr-gray-2: #f5F5F9;
  --clr-gray-3: #EEEDF3;
  --clr-gray-4: #E1E1E5;
  --clr-gray-5: #C3C4C9;
  --clr-gray-6: #bbbfc7;
  --clr-gray-7: #afb3ba;
  --clr-gray-8: #9FA0AC;
  --clr-gray-9: #9c9da0;
  --clr-gray-10: #72747a;
  --clr-gray-11: #5B5C61;
  --clr-gray-12: #272830;
  --clr-gray-13: #060608;
  --clr-gray-14: #000000; // Black

  // Green Color Palette
  --clr-green-1: #dfede2;
  --clr-green-2: #D0EDDB;
  --clr-green-3: #94d4a8;
  --clr-green-4: #6bc78b;
  --clr-green-5: #47ba73;
  --clr-green-6: #27ae60;
  --clr-green-7: #178749;
  --clr-green-8: #0c6135;
  --clr-green-9: #043b20;
  --clr-green-10: #01140c;

  // Gold Color Palette
  --clr-amber-1: #fff9f0;
  --clr-amber-2: #ffeccf;
  --clr-amber-3: #ffd8a6;
  --clr-amber-4: #ffc27d;
  --clr-amber-5: #ffaa54;
  --clr-amber-6: #FF902C;
  --clr-amber-7: #d96d1a;
  --clr-amber-8: #b34f0c;
  --clr-amber-9: #8c3503;
  --clr-amber-10: #662301;

  // Red Color Palette
  --clr-red-1: #fff2f0;
  --clr-red-2: #FAD7D7;
  --clr-red-3: #ffb3ab;
  --clr-red-4: #f7867e;
  --clr-red-5: #EB5757;
  --clr-red-6: #dd2a2a;
  --clr-red-7: #b81a1f;
  --clr-red-8: #910d16;
  --clr-red-9: #6b040f;
  --clr-red-10: #45020b;

  // Brand and aux colors
  --clr-aider-blue: var(--clr-primary-6);
  --clr-aider-teal: var(--clr-secondary-6);
  --clr-aider-dark-teal: var(--clr-secondary-7);

  // Text colors
  --clr-text-blue: var(--clr-aider-blue);
  --clr-text-teal: var(--clr-aider-teal);
  --clr-text-brand-accent: var(--clr-aider-dark-teal);
  --clr-text-link: var(--clr-primary-6);
  --clr-text-hover: var(--clr-primary-7);
  --clr-text-title: var(--clr-primary-10);
  --clr-text-main: var(--clr-gray-12);
  --clr-text-aux: var(--clr-gray-11);
  --clr-text-white: var(--clr-gray-1);
  --clr-text-disabled: var(--clr-gray-9);
  --clr-text-darkmode: var(--clr-gray-1);
  --clr-text-success: var(--clr-green-7);
  --clr-text-danger: var(--clr-red-7);
  --clr-text-warning: var(--clr-amber-8);
  --clr-text-info: var(--clr-primary-6);

  // Button and Icon colors
  --clr-btn-fill-teal: var(--clr-aider-teal);
  --clr-btn-fill-link: var(--clr-primary-6);
  --clr-btn-fill-hover: var(--clr-primary-7);
  --clr-btn-fill-disabled: var(--clr-gray-5);
  --clr-btn-line-darkmode: var(--clr-gray-1);
  --clr-btn-fill-success: var(--clr-green-6);
  --clr-btn-fill-success-hover: var(--clr-green-7);
  --clr-btn-fill-danger: var(--clr-red-5);
  --clr-btn-fill-danger-hover: var(--clr-red-7);
  --clr-btn-fill-warning: var(--clr-amber-6);
  --clr-btn-fill-warning-hover: var(--clr-amber-7);
  --clr-btn-fill-white: var(--clr-gray-1);

  --clr-btn-line-teal: var(--clr-aider-dark-teal);
  --clr-btn-line-link: var(--clr-primary-6);
  --clr-btn-line-hover: var(--clr-primary-7);
  --clr-btn-line-title: var(--clr-primary-10);
  --clr-btn-line-body-aux: var(--clr-gray-11);
  --clr-btn-line-disabled: var(--clr-gray-9);
  --clr-btn-line-darkmode: var(--clr-gray-1);
  --clr-btn-line-white: var(--clr-gray-1);

  --clr-btn-line-success: var(--clr-green-7);
  --clr-btn-line-danger: var(--clr-red-7);
  --clr-btn-line-warning: var(--clr-amber-8);

  // Border Colors
  --clr-border-blue: var(--clr-primary-3);
  --clr-border-teal: var(--clr-aider-teal);
  --clr-border-dark-teal: var(--clr-aider-dark-teal);
  --clr-border-info: var(--clr-primary-6);
  --clr-border-emphasis: var(--clr-gray-9);
  --clr-border-divider: var(--clr-gray-5);
  --clr-border-grid-th: var(--clr-gray-1);
  --clr-border-grid-td: var(--clr-gray-2);
  --clr-border-success: var(--clr-green-6);
  --clr-border-success-hover: var(--clr-green-7);
  --clr-border-danger: var(--clr-red-5);
  --clr-border-danger-hover: var(--clr-red-7);
  --clr-border-warning: var(--clr-amber-6);
  --clr-border-warning-hover: var(--clr-amber-7);

  // Background Colors
  --clr-bg-blue: var(--clr-primary-1);
  --clr-bg-blue-selected: var(--clr-primary-3);
  --clr-bg-teal: var(--clr-secondary-1);
  --clr-bg-title: var(--clr-primary-10);
  --clr-bg-disabled: var(--clr-gray-2);
  --clr-bg-th: var(--clr-gray-2);
  --clr-bg-white: var(--clr-gray-1);
  --clr-bg-info: var(--clr-bg-blue);
  --clr-bg-success: var(--clr-green-2);
  --clr-bg-danger: var(--clr-red-2);
  --clr-bg-warning: var(--clr-amber-2);

  // OSP Colors
  --clr-intuit: #2ba01d;
  --clr-xero: #1084ab;

  // Chart Colors TBC
}
