.main {
  &__content {
    &:has(.loadingZone){
      justify-content: flex-start;
      .loadingZone {
        padding: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h1 {
          @include font-format("h1", "bold");
        }

        .info-circle {
          vertical-align: middle;
          font-size: 1.25rem;
        }

        &__imported {
          color: var(--clr-text-main);
          
          @include font-format(h4, regular);
          display: flex;
          align-items: center;
          gap: 0.5rem;
          
          .strong {
            @include font-format(h4, bold);
          }
        }

        &__import-more,
        &__initial-osp {
        }

        &__add-business {
          margin-top: 2rem;
        }

        &__import-more {
          margin-top: 2rem;
          display: flex;
          gap: 1rem;
          justify-content: flex-start;

          &--label {
            width: max-content;
            max-width: 20ch;
            white-space: nowrap;
            padding-top: 0.5rem;
            flex-wrap: nowrap;
            @include font-format(small, bold);
          }

          &__action-column {
            min-width: 50ch;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 0.5rem;
            align-items: center;
            justify-content: flex-start;
            @include font-format("body", "regular");
            color: var(--clr-text-aux);

            p {
              margin-bottom: 0;
            }
          }
        }

        p {
          color: var(--clr-text-aux);
          @include font-format("body", "regular");
          white-space: pre-line;
          &:not(:last-of-type) {
            margin-bottom: 0rem;
          }
        }

        .activate-disclaimer {
          margin-bottom: 3rem;
        }

        .illustration {
          width: 300px;
        }
        
        &__initial-osp {
          justify-content: center;
          margin-bottom: 2rem;
        }


        &__seperator {
          width: clamp(50px, 10dvw, 150px);
          height: 1px;
          background: var(--clr-border-divider);
        }

        &__steps {
          margin-block: 2rem;
          display: flex;
          align-items: center;
          gap: 1rem;

          --step-num-bg-color: var(--clr-text-white);
          --step-num-border-color: var(--clr-btn-line-disabled);
          --step-num-text-color: var(--clr-btn-line-disabled);
          --step-text-color: var(--clr-text-disabled);

          &__step-number {
            background: var(--step-num-bg-color);
            color: var(--step-num-text-color);
            border: solid 1px var(--step-num-border-color);
            justify-content: center;
            display: flex;
            align-items: center;
            width: 2rem;
            height: 2rem;
            padding: 0.5rem;
            border-radius: 50%;
            aspect-ratio: 1/1;
            @include font-format("body", "black");
          }

          &__step-text {
            color: var(--step-text-color);
            @include font-format("h4", "bold");
          }

          &__step {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 0.5rem;


            &--active {
              --step-num-bg-color: var(--clr-btn-fill-link);
              --step-num-border-color: var(--clr-btn-fill-link);
              --step-num-text-color: var(--clr-text-white);
              --step-text-color: var(--clr-btn-fill-link);
            }

            &--complete {
              --step-num-bg-color: var(--clr-bg-white);
              --step-num-border-color: var(--clr-btn-fill-link);
              --step-num-text-color: var(--clr-btn-fill-link);
              --step-text-color: var(--clr-btn-fill-link);
              @include font-format("regular", "semibold");

              .loadingZone {
                &__steps {
                  &__step-number,
                  &__step-text {
                    @include font-format("h4", "semibold");
                  }
                }
              }
            }
          }
        }

        &__clients {
          margin-top: 2rem;
          display: flex;
          text-align: left;
          justify-content: space-between;
          gap: 1rem;
          width: 100%;
          margin-bottom: 1.5rem;


          .aider-alert {
            &__content {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              &--title{
                @include font-format("h4", "bold");
              }

              &--info {
                display: flex;
                align-items: center;
                gap: 1rem;
              }

              &--body {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: flex-start;
                @include font-format("body", "regular");

                ul {
                  padding-inline-start: 1.2rem;
                  li {
                    list-style: disc;
                  }
                }
                ul, ol {
                  margin-block: 0;

                  .client-name {
                    text-overflow: ellipsis;
                    max-width: 95%;
                    overflow-x: hidden;
                    white-space: nowrap;
                  }

                  .client--activated {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                    .client-name {
                      max-width: 60%;
                    }

                    .activated-flag {
                      white-space: nowrap;
                      color: var(--clr-btn-line-success);
                      padding-right: 0.5rem;
                    }
                  }
                }
              }
            }
          }

          &--activated:is(p) {
            @include font-format("h5", "bold");
          }
          &--customize {
            &.ant-btn.ant-btn-link:not(:disabled):not(.ant-btn-disabled).button-primary:has(.ant-btn-icon) {
              justify-self: flex-start;
              @include font-format("footnote", "regular");
              padding: 0
            };
          }
        }

        .clientListDisplay {
          width: 100%;

          .ant-collapse {
            &-header {
              padding: 0;
              color: var(--clr-text-blue);
              &:hover {
                color: var(--clr-text-hover);
              }
            }

            &-content {
              .ant-collapse-content-box {
                @include font-format("small", "regular");
                padding: 0 0 0 1rem;
                .clientListDisplay__list {
                  max-height: 150px;
                  overflow-y: auto;
                }
              }
            }
          }
        }

        &__manage-clients {
          align-self: flex-start;
        }
      }
    }
  }
}

.osp-selector {
  display: flex;
  width: 100%;
  gap: 1rem;

  &__initial {
    margin-top: 2rem;
  }

  width: min(100%, 60ch);

  &__tooltip {
    max-width: min(80ch, 80%);
    @include font-format(small, regular);

    .ant-tooltip-content,
    .ant-tooltip-inner {
      width: 100%;

      p {
        margin-bottom: 0;
      }
    }
  }
}
.osp-buttons {
  &.xero {
    &.button-secondary {
      width: 150px;
      --_bg-color: var(--clr-xero);
    }
  }
  &.intuit {
    &.button-secondary {
      --_bg-color: var(--clr-intuit);
      width: 208px;
    }
    }

    &.button-secondary {
      --_border-color: none;
      --_text-color: rgb(from var(--clr-text-white) r g b / 0.8);
      height: 2.1rem;
      font-size: 2.1rem;

      &.loading {
        font-size: 1.5rem;
      }
      padding: 0;
      min-width: 0;
      width: min-content;
    }
  }
