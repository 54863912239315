// to replace react-materialize' input
.bp3-input input,
.modalFormItem,
.overviewScreen input
{
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 0 15px 0;
  padding: 0;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  transition: all .3s;

  margin: 0;
}

#periodSelectionDropdown .bp3-input input {
  height: auto;
}
