.main {
  .manageBranding {
    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      justify-content: flex-start;
      flex-grow: 0;

      .descriptions > p {
        margin-bottom: 0;
        line-height: 1.5rem;
      }

      @include respond-to(md) {
        flex-direction: row;

        .ant-upload-wrapper {
          width: auto;
        }
      }

      .avatar__uploader {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;



        .practice-initials-logo {
          position: absolute;
          color: var(--clr-text-blue);
          @include font-format(h1, bold);
          background-color: transparent;
          padding: 0.5rem;
          aspect-ratio: 1;
          border-radius: 0.25rem;
        }

        .practice-logo, .practice-initials-logo {
          border-radius: 0.25rem;
          opacity: 1;
          transition: opacity 250ms ease-out;
        }

        .upload-button {
          position: absolute;
          color: var(--clr-text-blue);
          @include font-format(h4, bold);
          opacity: 0;
          transition: opacity 250ms ease-out;
        }

        .ant-upload {
          border: 1px solid var(--clr-aider-blue);
          &:has(.practice-initials-logo){
            border: 1px dashed var(--clr-aider-blue);
          }
          &:hover {
            .practice-initials-logo {
              opacity: 0.1;
            }
            .practice-logo {
              opacity: 0.3;
            }
            .upload-button {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
