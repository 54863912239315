.performance-report {
  display: flex;
  flex-direction: row;

  .insight-selector {
    overflow-y: auto;
    padding: 1rem;
    padding-top: 0;
    min-width: 220px;
    color: #262626;
    background-color: $clr-bg-blue;

    .insights-tab {
      font-size: 14px !important;
      font-family: 'Nunito', sans-serif !important;
      font-weight: 700;
      line-height: 1.8em;
      color: #101A78;
      padding: 4px 0px;
    }
    .group-separator {
      display: flex;
      padding: 0.5em 0;
      position: relative;
      z-index: 2;
      color: var(--clr-text-white);
      font-weight: 700;
      margin-bottom: 0.5rem;

      &::before {
        content: '';
        display: block;
        height: 100%;
        background: var(--clr-bg-title);
        position: absolute;
        width: calc(100% + 2rem); 
        left: -1rem;
        top: 0;
        z-index: -1;
      }
    }

    .insight {
      display: flex;
      flex-direction: column;
      margin-inline-start: 0px !important;
      font-size: 14px !important;
      font-family: 'Nunito', sans-serif !important;
      font-weight: 400;
    }
  }

  .loading-spinner {
    height: 30px;
  }

  .report-main {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .report-content {
    padding: 1em;
  }

  .report-download {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: sticky;
    bottom: -1rem;
    background-color: var(--clr-bg-disabled);
    padding: 1rem;
    padding-bottom: 1.5rem;
  }
}
