.filter {
  position: relative;
  &__title {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background: #fff;
    border: 1px solid $lightGrey;
    border-radius: 4px;
    padding: 1rem;
    min-width: 15rem;
    color: $grey;
    font: 1em sans-serif;
    text-transform: capitalize;
  }

  input[type='text'] {
    height: auto;
    margin: auto;
  }
  &__arrow {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    padding: 1rem;
    font-size: 10px;

    svg {
      width: 0.75rem;
      color: $grey;
    }
  }

  &__options {
    position: absolute;
    z-index: 1000;
    border: 1px solid #dddddd;
    background: #fff;
    width: 15rem;
    padding: 10px;
  }

  &__option {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: $lightBlue;
      font-weight: bold;
    }
    &--selected {
      svg {
        width: 0.75rem;
        position: absolute;
        left: 2%;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    &--error {
      cursor: none;
      padding-left: 0.5rem;
      pointer-events: none;
    }
  }
}
