.main {
  .manage-permissions {
    max-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: clamp(1rem, 5vw, 10rem);

    &__column {
      width: 100%;
      max-width: 484px;
      flex-shrink: 1;
    }

    &__panel {
      height: calc(100% - 1.5rem);
      overflow-y: auto;
      overflow-x: hidden;
      border: solid 1px var(--clr-border-divider);
      border-radius: 0.25rem;

      &--items {
        &.ant-menu-vertical {
          .ant-menu {
            border: 0;
            &-item {
              border-radius: 0;
              margin: 0;
              padding: 0.5rem 1rem;
              margin-block-end: 0;
              margin-inline-end: 0;
              margin-bottom: 0;
              width: 100%;
              height: auto;
              color: var(--clr-text-main);

              &:not(:last-child){
                border-bottom: solid 1px var(--clr-border-divider);
              }

              &-selected {
                background: var(--clr-bg-blue-selected);
              }

              /**
                * Disabled state in AntD is using the !important flag, and unfortunately
                * we can't override it with a higher specificity selector. So we have to
                * use the !important flag as well.
                */
              &-disabled {
                color: var(--clr-text-aux) !important;
                background-color: var(--clr-bg-disabled) !important;
              }

              &:not(.ant-menu-item-selected):hover {
                background: var(--clr-bg-blue);
                color: var(--clr-aider-blue);
              }

              h4 {
                @include font-format(small, semibold, capitalize);
                color: inherit;
                padding: 0;
                margin: 0;
              }

              p {
                @include font-format(small, regular);
                padding: 0;
                margin: 0;
              }

            }
          }
        }
      }

      &__form {
        position: relative;
        .ant-form-item {
          margin-bottom: 0;
        }

        &__header {
          position: sticky;
          top: 0;
          z-index: 1;
          padding: 0.5rem 0.75rem 0.25rem;
          background-color: var(--clr-bg-th);
          border-bottom: solid 1px var(--clr-border-divider);
          &--checkbox {
            @include font-format(small, bold, uppercase);
            color: var(--clr-text-title);
            line-height: 1.8;
          }
        }
        &__items {
          padding: 0 0.75rem;

          &--item {
            @include font-format(small, light);
            line-height: 1.8;
          }
        }
      }
    }

    &__alert {
      height: 100%;

      .aider-alert__content {
        &--body {
          @include font-format(small, regular);
        }
      }

      &--subtext {
        @include font-format(footnote, light);
      }
    }
  }
}
