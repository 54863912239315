.loading-spinner, .global-loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  &__overlay {
    @include font-format(h2, bold);
    color: var(--clr-text-title);
  }
}

.main {
  .loading-spinner, .global-loader {
    &__overlay {
      @include font-format(h2, semibold);
    }
  }
}

.global-loader {
  &--loading {
    display: none;
  }
  &--loaded {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }
  .loading-spinner {
    height: auto;
  }
}
