/**
  * SubLayoutSettings
  * Defines a layout with a full width header, a responsive
  * navbar and a main content area
  */

/**
  * The ant design layout has a class called `ant-layout` which is the main wrapper
  * for the layout. This is where we will add our custom styles.
  */
.ant-layout {
  .subLayout-settings {
    flex-grow: 1;
    flex-shrink: 1;


    &__header {
      background: #fff;
      padding: 1rem;
      position: sticky;
      top: 0;
      z-index: 1;
      height: auto;

      h1 {
        padding: 0;
        margin: 0;
      }
    }

    &__content {
      @include respond-to(md) {
        flex-direction: row;
      }

      &--subNav {
        background: var(--clr-bg-blue);
      }

      &--main {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        background: var(--clr-bg-white);
      }
      .sub-content {
        &__header {
          padding: 1.5rem;
          background: inherit;

          &--assistant-settings,
          &--title {
            padding: 0;
          }

          h2 {
            &.flex-center__vertical {
              gap: 0.5rem;
            }
          }
        }

        &__content {
          padding: 0 1.5rem 1.5rem 1.5rem;
          background: inherit;


        }

        &__content {
          flex: 1;

          &:not(.manageAdvisors){
            overflow-y: auto;
          }
        }

        &__footer {
          background: inherit;
          padding: 1.5rem ;
          display: flex;
          justify-content: flex-end;
          border-top: solid 1px var(--clr-border-divider);
        }
      }
    }
  }
}
