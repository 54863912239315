.main {
  $nameWidth: clamp(230px, 30vw, 400px);
  $colWidth: clamp(190px, 10vw, 275px);

  .dashboard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    overflow: auto;
    flex: 1;

    &__filters {
      padding: 2rem 1rem 0 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 1rem;
      }

      &__items {
        h5 {
          margin: 0;
          padding: 0;
          @include font-format(h5, bold);
        }

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: 0.5rem;
        flex-shrink: 0;

        &:first-child {
          flex-grow: 1;
        }

        &--input {
          flex-shrink: 0;
          width: 100%;

          &.ant-select-multiple {
            .ant-select-selection-item {
              display: flex;
              align-items: center;
              border-radius: 1rem;
              padding: 0.2rem 0.5rem;
              margin: 0;
              margin-right: 0.5rem;
              @include font-format(small, regular);
              background-color: var(--clr-text-blue);
              color: var(--clr-bg-white);
              border: solid 1px var(--clr-border-info);
              height: auto;

              .ant-select-selection-item-remove {
                color: var(--clr-bg-white);
              }
            }
          }
        }

        @include respond-to(sm) {
          flex-direction: row;
          align-items: center;

          &--input {
            width: clamp(200px, 25vw, 400px);
          }
        }
      }

      &--active {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
      }
    }

    &-table {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      overflow: auto;
      flex-shrink: 1;
      flex: 1;

      &__header {
        width: max-content;
        min-width: 100%;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        top: 0;
        background-color: var(--clr-bg-title);
        color: var(--clr-text-white);
        position: -webkit-sticky;
        position: sticky;
        z-index: 2;

        &__column {
          position: relative;
          display: flex;
          flex-direction: column;
          padding-block: 1rem;
          gap: 1rem;
          box-sizing: border-box;

          &--action {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          &:not(:first-of-type) {
            box-shadow: inset 3px 0px var(--clr-border-grid-th);
          }

          &:has(.dashboard-table__header__column--subtext:empty) {
            width: $colWidth;
          }

          flex-shrink: 0;

          .dashboard-table__header__column--title,
          .dashboard-table__header__column--action {
            padding-inline: 1rem;
          }

          &--subtext {
            display: flex;
            flex-wrap: nowrap;
            @include font-format(small, regular);
            line-height: 1.5rem;

            .anticon[aria-label='ews'] {
              color: var(--clr-text-teal);
              font-size: 1.25rem;
              padding-right: 0.3rem;
              position: relative;
              bottom: -0.2rem;
            }

            &__column {
              width: $colWidth;
              padding-inline: 1rem;
              flex-shrink: 0;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              gap: 0.5rem;
            }
          }

          &:first-of-type:not(:empty) {
            width: $nameWidth;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            left: 0;
            background-color: var(--clr-bg-title);
            z-index: 3;
          }

          &--title {
            color: var(--clr-text-white);
            margin: 0;
            @include font-format(small, semibold);
          }

          .dashboardSort {
            border: none;

            .dashboardSort__button {
              font-size: 1.6rem;
              color: var(--clr-primary-4);

              &.active {
                svg {
                  stroke-width: 2;
                }

                &.client {
                  color: var(--clr-primary-1);
                }

                &.desc {
                  .icon-up {
                    stroke: var(--clr-primary-1);
                  }
                }

                &.asc {
                  .icon-down {
                    stroke: var(--clr-primary-1);
                  }
                }
              }
            }
          }
        }
      }

      &__body {
        position: relative;
        width: max-content;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-shrink: 1;
        flex-grow: 1;

        &--row {
          overflow-x: visible;
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          min-width: 100%;

          &:not(:last-child) {
            box-shadow: 0px -3px 0px 0px var(--clr-border-grid-td) inset;
          }

          background-color: var(--clr-bg-white);

          &:not(:empty):not(.loading):not(.dashboard-table__body--row__empty):after {
            content: '';
            display: flex;
            flex-grow: 1;
            position: absolute;
            background-color: var(--clr-bg-white);
            background: repeating-linear-gradient(90deg, var(--clr-bg-white), var(--clr-bg-white) 200px, var(--clr-border-grid-td) 203px);
            box-shadow: 3px 0px 0px 0px var(--clr-border-grid-td) inset,
              0px -3px 0px 0px var(--clr-border-grid-td) inset;
          }


          &:not(:last-child) {
            .dashboardCell {
              box-shadow: 0px -3px 0px 0px var(--clr-border-grid-td) inset;

              &.firstPos {
                box-shadow: 3px 0px 0px 0px var(--clr-border-grid-td) inset,
                  0px -3px 0px 0px var(--clr-border-grid-td) inset;
              }


              &.client {
                box-shadow: 0px -3px 0px 0px var(--clr-border-grid-th) inset;
              }
            }
          }

          &:last-child {
            .dashboardCell {

              &.firstPos {
                box-shadow: 3px 0px 0px 0px var(--clr-border-grid-td) inset,
              }


            }
          }

          .dashboardCell {
            cursor: pointer;
            position: relative;
            width: $colWidth;
            background-color: var(--clr-bg-white);
            padding: 1rem;

            @include font-format(small, regular);
            display: flex;
            justify-content: space-between;
            flex-basis: 1;
            gap: 0.5rem;

            &.type-trend {
              justify-content: flex-end;
              text-align: right;
            }

            .accent {
              @include font-format(small, semibold);
            }

            &.status {
              &-good {
                background-color: var(--clr-bg-success);

                .dashboardCell__icon {
                  color: var(--clr-text-success);
                }

                &.type-alert {
                  .dashboardCell__value {
                    @include font-format(small, semibold);
                  }

                  .dashboardCell__icon {
                    .anticon {
                      stroke: var(--clr-btn-line-white);
                      stroke-width: 25;
                    }
                  }
                }

                &.type-trend {
                  .dashboardCell__subtext {
                    @include font-format(small, bold);
                  }

                  .dashboardCell__icon {
                    .anticon {
                      stroke: var(--clr-btn-line-success);
                      stroke-width: 50;
                    }
                  }
                }

              }

              &-bad {
                background-color: var(--clr-bg-danger);

                .dashboardCell__icon {
                  color: var(--clr-text-danger);
                }

                &.type-alert {
                  .dashboardCell__subtext {
                    @include font-format(small, semibold);
                  }

                  .dashboardCell__icon {
                    .anticon {
                      stroke: var(--clr-btn-line-white);
                      stroke-width: 25;
                    }
                  }
                }

                &.type-trend {
                  .dashboardCell__subtext {
                    @include font-format(small, bold);
                  }

                  .dashboardCell__icon {
                    .anticon {
                      stroke: var(--clr-btn-line-danger);
                      stroke-width: 50;
                    }
                  }
                }

                &.type-gst {
                  .dashboardCell__value {
                    @include font-format(small, semibold);
                  }
                }
              }
            }

            .dashboardCell__icon:empty {
              width: 1rem;
            }

            &.client {
              position: relative;
              background-color: var(--clr-bg-blue);
              color: var(--clr-text-title);
              position: -webkit-sticky;
              position: sticky;
              left: 0;
              z-index: 1;

              width: $nameWidth;

              & .dashboardCell {
                &__data {
                  max-width: calc(100% - 1rem);

                }

                &__value {
                  color: var(--clr-text-title);
                  @include font-format(regular, semibold);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                &__subtext {
                  color: var(--clr-text-title);
                  @include font-format(footnote, light);
                  display: flex;
                  gap: 0.25rem;
                  align-items: center;
                }

                &__icon {
                  position: absolute;
                  right: 1rem;
                  .anticon {
                    color: var(--clr-text-blue);
                    @include font-format(body, semibold);
                  }

                }
              }

            }

            &.disconnected {
              & .dashboardCell__subtext {
                color: var(--clr-text-danger);
                @include font-format(regular, regular);
                justify-content: flex-start;
                align-items: center;
                & .osp__logo {
                  font-size: 1.2rem;
                }
              }
              & .dashboardCell__icon {
                & .button-secondary {
                  font-size: clamp(0.7rem, 1.3vw, 0.9rem);
                  height: auto;
                }
              }
            }




            flex-shrink: 0;

            &.full-width {
              position: sticky;
              left: $nameWidth;
            }

            &__icon {
              &--top {
                align-self: flex-start;
              }

              &--bottom {
                align-self: flex-end;
              }
            }

            &.dashboard-skeleton {

              .dashboardCell__data {
                display: flex;
                flex-direction: column;
                gap: 0.2rem;
              }

              .ant-skeleton-input {
                height: 1.2rem;
              }

              .ant-skeleton-button {
                height: 1.2rem;
                width: 1.2rem;
                aspect-ratio: 1;
                min-width: auto;
              }

              &.no-cols {
                flex-grow: 1;

                .dashboardCell__data {
                  width: 100%;
                }

                .ant-skeleton-input {
                  width: 100%;
                }
              }
            }
          }

          &--unreconciled {
            .bank-rec-dependant {
              &.dashboardCell {
                background-color: var(--clr-bg-white);

                .dashboardCell__subtext {
                  @include font-format(small, regular);
                  color: var(--clr-text-disabled);
                }

                .dashboardCell__icon {
                  color: var(--clr-text-disabled);

                  svg {
                    stroke-width: 1.5;
                  }
                }
              }
            }
          }

          &__empty {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            min-width: 0;
            max-width: calc(100dvw - 6rem);

            &--icon {
              width: 2rem;
              color: var(--clr-btn-fill-danger);
              @include font-format(h4, semibold);

              path {
                stroke-width: 25;
                stroke: var(--clr-bg-white);
              }
            }

            &--header {
              max-width: 100%;
              display: flex;
              align-items: center;

              h5 {
                margin: 0;
                padding: 0;
                color: var(--clr-text-main);
              }
            }

            &--body {
              padding-left: 2rem;
              @include font-format(small, regular);

              p {
                display: flex;
                flex-grow: 0;
                flex-shrink: 0;
                flex-wrap: wrap;
                gap: 0.3rem;
                align-items: center;
                margin: 0 0 0.5rem 0;
                padding: 0;
                color: var(--clr-text-main);
              }

              &--list {
                margin-left: 2rem;
                display: block;

                &::before {
                  content: "•";
                  margin-right: 0.5rem;
                  display: block;
                }
              }

              .emphasis {
                @include font-format(small, bold);
              }
            }
          }
        }
      }

      .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        position: sticky;
        top: 149px;
        z-index: 2;
        background: color-mix(in srgb, var(--clr-bg-white) 80%, transparent);

        .loading-spinner {
          width: 100%;
        }
      }
    }

    &-icon {
      display: block;
      fill: var(--clr-bg-white);
      font-size: 1.2rem;
      width: 1.3rem;
      height: 1.3rem;

      &__draft {
        color: var(--clr-btn-line-danger);
      }

      &__final {
        color: var(--clr-btn-line-success);
      }

      &__estimate {
        color: var(--clr-btn-line-info);

        path {
          stroke-width: 1;

          &:not(:first-of-type) {
            stroke-width: 1.5;
          }
        }
      }
    }
  }
}

.dashboardSort__dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      color: var(--clr-text-blue);
      @include font-format(small, light);

      &.ant-dropdown-menu-item-disabled {
        color: var(--clr-text-main);
        background-color: var(--clr-bg-blue-selected);
        @include font-format(small, regular);
      }

      &:not(.ant-dropdown-menu-item-disabled):hover {
        color: var(--clr-text-main);
        background-color: var(--clr-bg-blue);
      }

      .anticon {
        font-size: 1.25rem;
      }
    }
  }
}

.ant-tooltip:has(.dashboard-tooltip),
.ant-tooltip:has(.aider-tooltip) {
  --fallback-bg: var(--clr-gray-13);
  --tooltip-bg: color-mix(in srgb, var(--fallback-bg) 90%, transparent);

  .aider-tooltip {
    --_title-color: var(--clr-text-white);
  }

  .ant-tooltip-arrow:before {
    background: var(--fallback-bg);
    background: var(--tooltip-bg);
  }

  .ant-tooltip-inner {
    background-color: var(--fallback-bg);
    background-color: var(--tooltip-bg);
    color: var(--clr-text-white);
    padding: 0.5rem 1rem;
    @include font-format(small, light);

    h4 {
      margin: 0;
      padding: 0;
      @include font-format(small, semibold);

      .anticon {
        color: var(--clr-text-teal);
        font-size: 1.5rem;
        padding-right: 0.3rem;
        position: relative;
        bottom: -0.2rem;
      }
    }

    p {
      padding: 0;
      margin: 0;
    }

    h4+p,
    p+p {
      margin-top: 0.5rem;
    }
  }
}

.ant-select-dropdown:has(.dashboard__filters__dropdown) {
  .ant-select-item-option {
    &:not(&-selected) {
      padding-left: 1.6em;
    }
  }

  .dashboard__filters__empty {
    color: var(--clr-text-disabled);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;

    * {
      margin: 0;
      padding: 0;
    }

    &__icon {
      font-size: 2rem;
      color: color-mix(in srgb, var(--clr-text-disabled) 60%, transparent);
      margin-bottom: 0.5rem;
    }

    h5 {
      @include font-format(h5, regular);

    }

    p {
      @include font-format(small, light)
    }
  }
}
