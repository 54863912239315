.manageAdvisors {
  a {
    margin-left: 5px;
  }

  &__table {
    padding-inline: 1.5rem;
    max-height: calc(100% - 5rem);
    overflow-y: auto;

  }

  .table__manageAdvisor {
    .ant-table-cell {
      border-bottom: none;
      border-top: 1px solid var(--clr-border-divider);
      color: var(--clr-text-main);
    }
  }

  &__nameAndRole{
    margin: 0;
    span:first-child {
      margin-left: .5rem;
    }
  }

  &__status--error {
    span {
      margin-right: 0.2rem;
    }
    color: var(--clr-text-danger);
  }

  &__footer {
    width: 100%;

    &__form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__fullName {
        flex: 1.5 1 0;
      }
      &__email {
        flex: 2.5 1 0
      }

      &__fullName,
      &__email {
        flex: 1 1 100%;
      }


      .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;
      }

      @include respond-to('sm') {
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;
        .ant-form-item {
          margin-bottom: 0;
        }
        &__fullName {
          flex: 1.5 1 0;
        }
        &__email {
          flex: 2.5 1 0
        }

        &__button {
          margin-top: 2rem;
        }
      }
    }
  }
}
