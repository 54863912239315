// global style for reuse
$base-space: 5px;

.global {
  &__space {
    margin: $base-space;

    &--right {
      margin-right: $base-space;
    }
    &--left {
      margin-left: $base-space;
    }
  }
}

.bg {
  &__blue {
    background: $blue;
  }
}

.icon {
  &--none {
    margin-left: 10px;
  }
  &__red {
    color: $red;
  }
  &__green {
    color: $green;
  }
  &__neutral {
    color: $neutral;
  }
}

.text {
  &__bold {
    font-weight: bold;
  }
}

.flex--space {
  display: flex;
  justify-content: space-between;
}
.flex--around {
  display: flex;
  justify-content: space-around;
}
.flex--center {
  display: flex;
  justify-content: center;
}
.flex--column {
  display: flex;
  flex-direction: column;
}
.flex--align__center {
  display: flex;
  align-items: center;
}
.text--error {
  color: $red;
}
.text--bold {
  font-weight: bold;
}
.hr--bold {
  border: 1px solid currentColor;
}

//notes: row-gap / column-gap and flex should be conjunct to use
.gutter--x {
  row-gap: 1.5rem;
}

// Replaced the materialize's CardPanel
.card--panel {
  transition: box-shadow .25s;
  padding: 20px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 /12%);
}

.w-full {
  width: 100%;
}
