.email-form{
  display: flex;
  justify-content: center;
}
.user-form{
  justify-content: space-evenly !important;
}
.setInline{
  display: inline !important;
  justify-content: inherit;
}
.login-form{
  display: flex;
}
.caret{
  cursor:default;
  position: absolute;
  top: 30px;
  right: 0px;
}
.headerText{
  font-size:12px;
  line-height:19px !important;
}
.feedback-select{width:100%;
}
.option{
  outline:none !important;
}:focus{
   outline: none !important;
 }
.keyboard-focused{
  outline:none !important;
}
.feedback-option{
  outline:none !important;
}
feedback-option-initial{
  color:black !important;
}
.selected{
  outline:#4264ea 0px !important;
}
.select-wrapper input.select-dropdown {
  margin-bottom: 0px !important;
  padding-left: 5px !important;
  border-bottom:none !important;
  font-size:18px;
  text-align: center;
}
.select-dropdown{
  height:100% !important;
}
.fillBackground{
  color:#4264ea;
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;
  background-color: #f4f2fb !important;
  border: 2px solid #fff;
  border-radius: 16px;
}
.fillBackground-AC{
  color:#4264ea;
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;
  background-color: #f4f2fb !important;
  border: 2px solid #fff;
  border-radius: 8px;
}
.fillBackground-AC-2{
  max-width: 300px !important;
  color:#4264ea;
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;
  background-color: #fff !important;
  border: 1px solid #9b9b9b;
  border-radius: 6px;
  text-align: left!important;
  padding-left: 20px !important;
}
.big-trigger>input{
  height:100% !important;
}
.big-trigger>input>ul{
  height:90px !important;
}
.alertRed{
  background-color: #eb5757 !important;
  color: #ffffff;
}
.aiderNavy{
  color:#0D206C !important;
}
.modalHeader{
  color:#0D206C !important;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.aiderModal{
  padding:60px !important;
}
.collapseEase{
  max-height: 0px;
  transition: max-height 0.15s ease-out;
}
.collapseEase.easeOpen{
  max-height: 2500px !important;
  transition: max-height 0.25s ease-in;

}
.disc-item{
  white-space: initial !important;
  list-style-type:disc !important;
}
.aiderModalHelp{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin:0px !important;
  width:100% !important;
  height:100% !important;
  visibility: visible;
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  min-height: 100vh;
}

.aiderModal-new{
  padding-top: 130px !important;
  padding-bottom: 130px !important;
  max-height:700px !important;
  padding:60px;
}
.cancelButton{
  background-color: #fff !important;
  border:1px solid #4264ea !important;
  color:#4264ea !important;
}
.aiderModalButton{
  font-weight: 700 !important;
  height:55px !important;
  line-height: 23px !important;
}
.insightBlue{
  background-color: #1b1bad !important;
}
.bp-input{
  width:auto !important;
  min-width: 135px;
}
.med-trigger>input{
  height:100% !important;
}
.med-trigger>input>ul{
  height:60px !important;
}
.std-trigger>input{
  height:100% !important;
}
.fillBackground-AC-2>input{
  text-align:left !important;
}
.std-trigger>input>ul{
  height:60px !important;
}
.feedbackDropdown{
  border:1px #4264ea solid !important;
  border-radius:8px;
  padding-left: 5px;
  outline:none !important;
  overflow-y: auto;
  overflow-x:hidden;
  background-color: #f5f4fa;

}
.seeInAider{
  width:250px;
  opacity:0.9;
  height: 55px;
  border-radius: 30px !important;
  background-color: #fff;
  border:2px solid #4264ea !important;
  font-weight: 600;
  color: #505050;
}
.hoverOverImage{
  opacity:0.5!important;
}
.hoverOverButton{
  background-color: #e8e8e8;
  cursor:pointer;
  color:#4264ea;
  opacity:1;
}
.noZ{
  z-index: 0;
  box-shadow: none !important;
}
.pane-split-left{
  border-left: 0.5px #d8d8d8 solid
}
.pane-split-bottom{
  border-bottom: 0.5px #d8d8d8 solid
}
.feedback-form-button{
  cursor:pointer;
  width:150px;
  height:40px;
  line-height:32px;
  border:2px solid #4264ea;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  color:#4264ea;
  background-color: white;
}
.feedback-card{
  width:100% !important;
  height:100% !important;
  visibility: visible;
}
.big-trigger{
  height:90px !important;
}med-trigger{
   height:60px !important;
 }
.std-trigger{
  height:42px !important;
}
.fillBackground-AC>.caret{
  visibility: hidden;
}
.fillBackground-AC-2>.caret{
  fill: #505050;
  top: 8px;
}
.fillBackground-AC-2>.select-wrapper{
  text-align: left !important;
}
.caret{
  fill: #4264ea;
}
.dropdown-content{
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 180px !important;
  font-size: 18px !important;
  color: #4264ea !important;
  top: 0px !important;
  background-color: #f5f4fa;
  border-radius: 5px;
  outline: none !important;

}.dropdown-content li>span{
   text-overflow: ellipsis;
   font-size:18px !important;

   overflow-y: auto;
   overflow-x: hidden;
   min-height:30px !important;
   height:30px !important;
   padding:0px !important;
   color:#4264ea !important;
   line-height: 2rem !important;
   outline:none !important;
   text-align: center !important}
.dropdown-content li{
  margin-left: 0px !important;
  min-height:30px !important;
  line-height: 2rem;
  background-color: #f5f4fa;
}
.disabled{
  color:#4264ea !important;

}
.feedback-modal-container{
  height: 650px !important;
  width: 600px !important;
  top:100px;
  z-index: 1001;
  position: absolute;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.buyButton{
  width: '100%';
  background-color: '#4264ea';
  font-weight:600;
  border-radius: 12;
  color: 'white';
  height: 60;
  text-align: 'center';
  padding-top: 20;
  cursor: 'pointer';
  border:'1px solid white';
  margin-top:70px;
  margin-left:250px;
}
.buyButton:hover{
  background-color: white;
  color:#3f65fb;
}
.subscribeButton{

  color:#4264ea !important;

  font-weight:700;

  background-color: #fff;

  border:2px solid #4264ea !important;

}
.payment-modal-container{

  height: 700px !important;
  width: 1000px !important;
  top:100px;
  z-index: 1001;
  position: absolute;
  background-color: #FAFAFA;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.paymentButton:hover{
  background-color: #fff;
  color:#4264ea;
}
.smallIcon{
  font-size: 1rem !important;
}
.disabledBackground{
  background: #f5f4fa !important;
  color: #5b78cb !important;

}
.aiderBlue{
  padding:0px !important;
  background-color: #fff !important;
  border-radius: 12px !important;
  //background-image url("https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg")
}
.paymentTier{
  height:240px;
  width:180px;
  color:#4264ea;
  background-color:#fff;
  margin:5px;
  border-radius:4px;
  border: 2px solid #4264ea;
}
.paymentTierGray{
  border: 1px solid #e6e6e6;
  color:#797979;
}
.tierDesc{
  font-size:18px;
  padding:20px;
  text-align:center;
}
.tierName{
  height:40px;
  text-align:center;
  font-size: 20px;
  padding-top: 10px;
}
.band{
  height:60px;
  text-align: center;
  padding-top: 10px;
  width:100%;
  background-color: #4264ea;
  color:#fff;
  font-size: 24px;
  margin-top: 10px;
}
.bandGray{
  background-color: #e6e6e6;
  color: #797979;

}
.feedback-form{
  display: flex;
  justify-content: center;
}
.feedback-form-detail{
  width:100%;
  box-shadow: 0 1px 0 0 #4264ea;
  border-radius: 4px;
  border: 1px #4264ea solid !important;
  padding: 20px;
}
//client form
.client-form-button{
  cursor:pointer;
  width:150px;
  height:40px;
  line-height:32px;
  border:2px solid #4264ea;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  color:#4264ea;
  background-color: white;
}
.modalBlue{
  background-color: #fafaff !important;
}
.inviteTab{
  padding: 10px;
  float: left;
  background-color: rgb(255, 255, 255);
  width: 33.33%;
  height: 60px;
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  cursor:pointer;
}
.client-card{
  width:100% !important;
  height:100% !important;
  visibility: visible;
  border-radius: 8px;
}
.client-card-welcome{
  width:100% !important;
  height:100% !important;
  visibility: visible;
  border-radius: 16px !important;
}
.client-modal-container{
  height: 700px !important;
  width: 600px !important;
  z-index: 1001;
  position: absolute;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.user-modal-container{
  height: 665px !important;
  width: 855px !important;
  z-index: 1001;
  position: absolute;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
  top:160px;
}
.actionCenterModal{
  width: 100% !important;
  height: 100% !important;
  visibility: visible !important;
  border-radius: 0px;
}
.client-modal-container-edit{
  height: 665px !important;
  width: 855px !important;
  z-index: 1001;
  position: absolute;
  background-color: #FAFAFA;
  border: 0.5px solid #505050;
  top:160px;
  border-radius: 18px;
  visibility:hidden;
}
.optionHeight{
  height:650px !important;
}
.normalHeight{
  height:550px !important;
}
//.client-modal-container-full {
//  height: 550px;
//  width: 900px !important;
//  z-index: 900;
//  top:13vh;
//  position: absolute;
//  background-color: #FAFAFA;
//  border-radius: 10px !important;
//  border: none !important;
//  visibility:hidden;
//}

.client-modal-container-full{
  height: 100vh !important;
  width: 100vw !important;
  z-index: 1001;
  position: absolute;
  background-color: #FAFAFA;
  top:0px;
  left:0px;
  border-radius: 18px;
  visibility:hidden;
}

.flex-payment{
  display: flex;
  justify-content: space-evenly;
  padding: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
}
.flex-payment{
  display: flex;
  justify-content: space-evenly;
  padding: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
}
.payment-container-full{
  height: 93% !important;
  width: 100% !important;
  z-index: 1001;
  position: absolute;
  background-color: #FAFAFA;
  top:6.5vh;
  visibility:hidden;
}
.nomargin{
  margin:0 !important;
}

.client-invite-modal-container{
  height: 640px !important;
  width: 850px !important;
  top:200px;
  z-index: 1001 !important;
  position: absolute;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.client-invite-modal-container-advisor{
  height: 600px !important;
  width: 850px !important;
  top:100px;
  z-index: 1001 !important;
  position: absolute;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}

.info-modal-container{
  height: 560px !important;
  width: 600px !important;
  top:100px;
  z-index: 1001;
  position: absolute;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.placeOption{
  cursor:pointer;
  border-bottom:1px solid #eeeeee;
  padding:5px;
  padding-bottom: 0px;
  padding-top:10px;

}
.placeOption:hover{
  background-color: #eeeeee;
}
.client-form{
  display: flex;
  justify-content: center;
}
.client-form-detail{
  width:100%;
  box-shadow: 0 1px 0 0 #4264ea;
  border-radius: 4px;
  border: 1px #4264ea solid !important;
  padding: 20px;
}
//metadata
.whiteCardBorder{
  border-radius: 10px 0px 0px 10px;

}
.blueCardBorder{
  border-radius: 10px 10px 10px 10px;
}
.actionCard{
  height:600px !important;
}
.z-depth-5{
  box-shadow: 0px 8px 24px 0 rgba(0,0,0,0.2), 0px 3px 77px 0 rgba(0,0,0,0.22);
}
.metadata-card{
  margin: 0px !important;
  width:100% !important;
  height:100% !important;
  visibility: visible;
  border-radius:8px
}
.upload-card{
  width:100% !important;
  height:100% !important;
  border-radius:8px;
  z-index:1002 !important;
  padding-top:0px !important;
  overflow-x: hidden;
}
.metadata-glossary-item{
  font-size: 11px;
}
.metadata-glossary-container{
  max-height:200px;
  min-height:200px;
  overflow-y: auto;
}
.overviewPanel{
  min-width:800px;
  max-width:1200px;
  width: 1200px;
}
.selectedOption{
  border:2px solid #4264ea !important;
}
.cardHasOptions{
  height:600px !important;
}
.cardHasNoOptions{
  max-height: 520px !important;
  height:520px !important;
}
.metadata-modal-container{
  height: auto !important;
  width: 700px !important;
  z-index: 1001;
  position: absolute;
  top:142px;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.subscribeButton{
  color:#4264ea !important;
  font-weight:700;
  background-color: #fff;
  border:2px solid #4264ea !important;
}
.done{
  border-style: solid !important;
}
.empty{
  border: none !important;
}
.uploadedImageContainer{
  color: #4264ea;
  border-radius: 50%;
  border:2px dashed #4264ea;
  cursor:pointer !important;
  background-color: transparent;
}
.massive{
  width:256px !important;
  height:256px !important;
}
.highlightedInput{
  border-color: #E2E8FF !important;
  background-color: #f5f8ff !important;
  color: #9dbcea !important;
}
.upload-modal-container{
  height: 56vh !important;
  width: auto;
  z-index: 1001 !important;
  position: absolute;
  top:250px;
  background-color: transparent;
  border-radius: 8px;
  visibility: hidden;
  outline:none;
  overflow-y: auto;
}
.ac-modal-container{
  min-height: 520px !important;
  width: 950px !important;
  z-index: 1001;
  position: absolute;
  top:142px;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}

.confirm-modal-container {
  height: auto !important;
  width: 600px !important;
  z-index: 1002 !important;
  position: absolute;
  top: 200px;
  background-color: #FAFAFA;
  border-radius: 4;
}
.confirm-modal-container-new{
  min-height: 70vh !important;
  height: auto;
  width: 900px !important;
  z-index: 1002 !important;
  position: absolute;
  top:100px;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.help-modal-container{
  height: auto !important;
  width: 260px !important;
  z-index: 1002 !important;
  position: absolute;
  top:0px;
  right:0px;
  background-color: #FAFAFA;

  border: 0.5px solid #505050;
  visibility:hidden;
}
.confirm-ac-modal-container{
  height: 620px !important;
  min-width: 700px !important;
  width: 80vw;
  z-index: 1002 !important;
  position: absolute;
  top:12vh !important;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.confirm-modal-container-welcome{
  height: 560px !important;
  width: 600px !important;
  z-index: 1003 !important;
  position: absolute;
  top:200px;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.confirm-modal-bank-account{
  height: 500px !important;
  width: 500px !important;
  z-index: 1002 !important;
  position: absolute;
  top:200px;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.confirm-modal-container-dc{
  height: auto !important;
  width: auto !important;
  min-width: 500px !important;
  z-index: 1002 !important;
  position: absolute;
  top:350px;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}
.removal{
  max-width: 600px !important;
  top:350px !important;

}
.kickback{
  top:300px !important;
}

.confirm-modal-container-advisor{
  height: auto !important;
  width: 600px !important;
  z-index: 1002 !important;
  position: absolute;
  top:440px;
  background-color: #FAFAFA;
  border-radius: 4;
  border: 0.5px solid #505050;
  visibility:hidden;
}


//close
.closeModal{
  font-size: 15px !important;
  cursor:pointer;
  width:150px!important;
  height:40px!important;
  line-height:32px!important;
  border:2px solid #4264ea!important;
  border-radius: 30px!important;
  text-align: center!important;
  font-weight: 500!important;
  color:#4264ea!important;
  background-color: white!important;
}
.closeModal:hover{
  background-color:white!important;
  color:#4264ea!important;
}

.modalContainer {
  .ant-modal {
    width: 80%;
  }
  .ant-modal-content {
    padding:4rem 6rem;
  }
  .ant-modal-body {
    @extend .flex--column;
    row-gap: 2rem;
  }
  .ant-modal-footer {
    @extend .flex--center;
    margin-top: 2rem;
    column-gap: 2rem;
    .blue-button,
    .white-button {
      width: 10rem;
    }
  }

  .modal__title {
    color: $darkBlue;
    font-weight: 700;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .modal__content {
    color: $neutral;
    white-space: pre-wrap;
    font-size: 1.15rem;
    font-weight: 400;
    &--center {
      text-align: center;
    }
  }
}


.confirmation-modal {
  .ant-modal-content {
    padding: 2rem;
  }
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;

    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--clr-text-title);
      margin-bottom: 2rem;
    }
  }
  .ant-modal-footer{
    display: flex;
    justify-content: center;
    border-top: none;
  }
}
.share-insight-graph-preview {
  max-width: 600px;
  height: auto;
}
