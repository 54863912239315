.main {
  .onboarding {
    &__header {
      background-color: var(--clr-bg-blue);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3rem;

      &--content {
        width: 100%;
        max-width: 480px;

        p {
          color: var(--clr-text-blue);
          @include font-format(h5, bold, uppercase);
          margin-bottom: 0;
        }
      }

      &--logo {
        width: 100%;
        svg {
        width: 100%;
        height: auto;
        }
      }
    }

    &__body {
      @include not-respond-to(xs){
        min-width: 0;
        padding: 2rem;
      }
      @include respond-to(xs){
        padding: 4rem;
      }
    }
  }
}
