@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;1000&display=swap');

$font-family: 'Nunito', sans-serif;
$base-font-size: 16px;
$max-text-width: 85ch;
$min-text-width: 20ch;

:root {
  font-size: $base-font-size;
  font-family: $font-family;
  color: var(--clr-text-main);
}

.main,
.aider-modal,
.aider-tooltip {
  @include font-format('body', 'regular');
  --_link-color: var(--clr-text-link);
  --_hover-color: var(--clr-text-hover);
  --_title-color: var(--clr-text-title);
  p, ul, ol, div, table, tr, td, th, input, textarea, button, label, a {
    font-family: $font-family;
  }

  a {

    font-weight: map-get($fontWeights, 'bold');
    text-decoration: none;
    color: var(--_link-color);

    &:hover {
      color: var(--_hover-color);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family;
    color: var(--_title-color);

    .anticon {
      font-size: 1em;
    }
  }

  h1 {
    @include font-format('h1', 'regular');
  }

  h2 {
    @include font-format('h2', 'regular');
  }

  h3 {
    @include font-format('h3', 'regular');
  }

  h4 {
    @include font-format('h4', 'regular');
  }

  h5 {
    @include font-format('h5', 'bold', 'capitalize');
  }

  h6 {
    @include font-format('h6', 'bold', 'capitalize');
  }

  .text-bold {
    font-weight: map-get($fontWeights, 'bold');
  }
}
