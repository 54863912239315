.scrollbar:has(.report-editor) {
  display: flex;
  flex-grow: 1;
}

#insightContainer:has(.report-editor) {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.report-editor {
  --sidebar-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-height: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  &__logo {
    width: 72px;
    height: auto;
  }

  &__granularity-dropdown {
    min-width: 15ch;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    background-color: var(--clr-bg-title);
    border-radius: 5px;
    width: 100%;
    padding: 1rem;

    .report-editor__header-template {
      width: 50%;
    }

    .report-editor__header-text {
      @include font-format(small, bold);
      color: var(--clr-text-white);
      margin: 0;
    }

    .report-editor__header-template-selector {
      width: 100%;
    }

    .ant-form {
      width: 100%;
      .ant-form-item {
        margin: 0;
        margin-bottom: 0;
        width: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, var(--clr-bg-blue) 0px var(--sidebar-width), var(--clr-bg-white) var(--sidebar-width) 100%);

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      box-sizing: border-box;
      flex: 0;

      &--actions {
        padding: 0.5rem;
        display: flex;
        width: var(--sidebar-width);
        justify-content: flex-end;
        gap: 0.5rem;

        &--left-aligned {
          justify-content: flex-start;
          height: 3.5rem;
          align-items: center;
        }
      }

      &--content {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        width: calc(100% - var(--sidebar-width));

        &:not(.outdent) {
          padding-inline: 2.5rem;
        }

        &__page {
          display: flex;
          flex-grow: 1;
          width: 100%;
          align-items: center;
          gap: 0.2rem;

          hr {
            width: 100%;
          }
        }

        &.ant-form-item {
          margin-bottom: inherit;
        }

        &__no-content {
          display: flex;
          padding: 0.4rem;
          gap: 0.6rem;
          outline: 1px solid var(--clr-border-divider);
          border-radius: 0.25rem;
          background: var(--clr-bg-disabled);

        }
      }
    }

  }

  &__add-component {
    display: flex;
    gap: 0.5rem;
    flex-wrap: nowrap;
    @include font-format(small, regular);
  }

  &__footer {
    background: var(--clr-bg-disabled);
    box-sizing: border-box;
    border: 1px solid var(--clr-bg-blue-selected);
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;

    &__actions {
      display: flex;
      gap: 1rem;
    }
  }
}

.reportInsightChartComponent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  width: 100%;
  
  &__child {
    width: min(100%, 600px);
    flex-grow: 0;
  }
}

.reportInsightTableComponent__table {
  width: 100%;
  &.ant-table-wrapper {
    .ant-table {
      .ant-table-thead {
        & > tr > th {
          &.reportInsightChartComponent__table {
            &--header {
              background-color: var(--clr-bg-th);
              color: var(--clr-text-title);
              @include font-format(small, bold);

              .ant-table-column-sorter > .ant-table-column-sorter-inner > .anticon.active {
                color: var(--clr-text-title);
              }
            }
          }
        }
      }
      .ant-table-tbody {
        & > tr > td {
          background-color: var(--clr-bg-white);
          color: var(--clr-text-main);
          @include font-format(small, regular);

          &.ant-table-cell-row-hover {
            background-color: var(--clr-bg-blue);
          }
        }
      }
    }
  }

  &--description {
    color: var(--clr-text-aux);
    background-color: var(--clr-bg-th);
    @include font-format(small, regular);
    font-style: italic;
    padding: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
