.grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.grid-cell {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-basis: auto;
    flex-grow: 1;
}

.grid-flexCells > .grid-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.grid-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.grid-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.grid-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.grid-cell-center-both {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.grid-justifyCenter {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.grid-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.grid-cell-top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.grid-cell-bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.grid-cell-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.grid-cell-autoSize {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
}

.grid-fit > .grid-cell {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.grid-full > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}

.grid-1of2 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
}

.grid-1of3 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333%;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
}

.grid-1of4 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
}

.grid-1of5 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
}

.grid-1of6 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6667%;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
}

@media (min-width: 384px) {
    .sm-grid-fit > .grid-cell {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .sm-grid-fit > .grid-cell-autoSize {
        -webkit-box-flex: 0;
        -webkit-flex: none;
        -ms-flex: none;
        flex: none;
    }

    .sm-grid-full > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .sm-grid-1of2 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    }

    .sm-grid-1of3 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.3333%;
        -ms-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
    }

    .sm-grid-1of4 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    }

    .sm-grid-1of5 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    }

    .sm-grid-1of6 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.6667%;
        -ms-flex: 0 0 16.6667%;
        flex: 0 0 16.6667%;
    }
}

@media (min-width: 576px) {
    .md-grid-fit > .grid-cell {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .md-grid-fit > .grid-cell-autoSize {
        -webkit-box-flex: 0;
        -webkit-flex: none;
        -ms-flex: none;
        flex: none;
    }

    .md-grid-full > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .md-grid-1of2 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    }

    .md-grid-1of3 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.3333%;
        -ms-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
    }

    .md-grid-1of4 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    }

    .md-grid-1of5 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    }

    .md-grid-1of6 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.6667%;
        -ms-flex: 0 0 16.6667%;
        flex: 0 0 16.6667%;
    }
}

@media (min-width: 768px) {
    .lg-grid-fit > .grid-cell {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .lg-grid-fit > .grid-cell-autoSize {
        -webkit-box-flex: 0;
        -webkit-flex: none;
        -ms-flex: none;
        flex: none;
    }

    .lg-grid-full > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .lg-grid-1of2 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    }

    .lg-grid-1of3 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.3333%;
        -ms-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
    }

    .lg-grid-1of4 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    }

    .lg-grid-1of5 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    }

    .lg-grid-1of6 > .grid-cell {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.6667%;
        -ms-flex: 0 0 16.6667%;
        flex: 0 0 16.6667%;
    }
}

.grid-gutters {
    margin: -1em 0 0 -1em;
}

.grid-gutters.grid-gutters-bottom {
    margin-bottom: 1em;
}

.grid-gutters > .grid-cell {
    padding: 1em 0 0 1em;
}

.grid-guttersLg {
    margin: -1.5em 0 0 -1.5em;
}

.grid-guttersLg.grid-gutters-bottom {
    margin-bottom: 1.5em;
}

.grid-guttersLg > .grid-cell {
    padding: 1.5em 0 0 1.5em;
}

.grid-guttersXl {
    margin: -2em 0 0 -2em;
}

.grid-guttersXl.grid-gutters-bottom {
    margin-bottom: 2em;
}

.grid-guttersXl > .grid-cell {
    padding: 2em 0 0 2em;
}

@media (min-width: 384px) {
    .sm-grid-gutters {
        margin: -1em 0 0 -1em;
    }

    .sm-grid-gutters.grid-gutters-bottom {
        margin-bottom: 1em;
    }

    .sm-grid-gutters > .grid-cell {
        padding: 1em 0 0 1em;
    }

    .sm-grid-guttersLg {
        margin: -1.5em 0 0 -1.5em;
    }

    .sm-grid-guttersLg.grid-gutters-bottom {
        margin-bottom: 1.5em;
    }

    .sm-grid-guttersLg > .grid-cell {
        padding: 1.5em 0 0 1.5em;
    }

    .sm-grid-guttersXl {
        margin: -2em 0 0 -2em;
    }

    .sm-grid-guttersXl.grid-gutters-bottom {
        margin-bottom: 2em;
    }

    .sm-grid-guttersXl > .grid-cell {
        padding: 2em 0 0 2em;
    }
}

@media (min-width: 576px) {
    .md-grid-gutters {
        margin: -1em 0 0 -1em;
    }

    .md-grid-gutters.grid-gutters-bottom {
        margin-bottom: 1em;
    }

    .md-grid-gutters > .grid-cell {
        padding: 1em 0 0 1em;
    }

    .md-grid-guttersLg {
        margin: -1.5em 0 0 -1.5em;
    }

    .md-grid-guttersLg.grid-gutters-bottom {
        margin-bottom: 1.5em;
    }

    .md-grid-guttersLg > .grid-cell {
        padding: 1.5em 0 0 1.5em;
    }

    .md-grid-guttersXl {
        margin: -2em 0 0 -2em;
    }

    .md-grid-guttersXl.grid-gutters-bottom {
        margin-bottom: 2em;
    }

    .md-grid-guttersXl > .grid-cell {
        padding: 2em 0 0 2em;
    }
}

@media (min-width: 768px) {
    .lg-grid-gutters {
        margin: -1em 0 0 -1em;
    }

    .lg-grid-gutters.grid-gutters-bottom {
        margin-bottom: 1em;
    }

    .lg-grid-gutters > .grid-cell {
        padding: 1em 0 0 1em;
    }

    .lg-grid-guttersLg {
        margin: -1.5em 0 0 -1.5em;
    }

    .lg-grid-guttersLg.grid-gutters-bottom {
        margin-bottom: 1.5em;
    }

    .lg-grid-guttersLg > .grid-cell {
        padding: 1.5em 0 0 1.5em;
    }

    .lg-grid-guttersXl {
        margin: -2em 0 0 -2em;
    }

    .lg-grid-guttersXl.grid-gutters-bottom {
        margin-bottom: 2em;
    }

    .lg-grid-guttersXl > .grid-cell {
        padding: 2em 0 0 2em;
    }
}
.stats .color.green {
    color: #50B83C !important;
}
.stats .color.orange {
    color: #ED6347 !important;
}

.stats .color.gray {
    color: #C4CDD5 !important;
}

.stats .color.dark-blue {
    color: #3AA4D5 !important;
}
.stats .color.in-progress {
    color: #2e80eb !important;
}

.stats .color.blue {
    color: #2DD3DA !important;
}
.stats .color.lower {
    color: #3e4dda !important;
}
.stats .color.upper {
    color: #ED6347 !important;
}

.stats .color.yellow {
    color: #EDC200 !important;
}

.stats .border-color.green {
    border-color: #50B83C !important;
}

.stats .border-color.gray {
    border-color: #C4CDD5 !important;
}

.stats .border-color.orange {
    border-color: #EDC200 !important;
}

.stats .border-color.blue {
    border-color: #2DD3DA !important;
}

.stats .border-color.dark-blue {
    border-color: #3AA4D5 !important;
}

.stats .background.green {
    background-color: #50B83C !important;
}

.stats .background.gray {
    background-color: #C4CDD5 !important;
}

.stats .background.red {
    background-color: #ED6347 !important;
}

.stats .background.yellow {
    background-color: #EDC200 !important;
}

.stats .background.orange {
    background-color: #ED6347 !important;
}
.stats .background.incomplete {
    background-color: #eb6347 !important;
}

.stats .background.blue {
    background-color: #2DD3DA !important;
}

.stats .background.dark-blue {
    background-color: #3AA4D5 !important;
}

.stats .stats-grid {
    height: 100%;
}

.stats .stats-item .name {
    font-size: 12px;
    line-height: 16px;
    color: #000;
    padding-bottom: 2px;
    border-bottom: 2px dashed #979797;
    display: table;
    margin: 0 auto;
    text-align: center;
}

.stats .stats-item .amount {
    font-size: 48px;
    line-height: 48px;
    color: #384750;
    text-align: center;
}

.stats .stats-item-grid .stats-item .amount {
    font-size: 48px;
    line-height: 48px;
    color: #384750;
    text-align: center;
}

.stats .stats-item-list-holder {
    min-width: 160px;
}

.stats .stats-item-list .grid-cell > .stats-item .grid-cell {
    min-width: 80px;
}

.stats .stats-item-list .stats-item .amount {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #FFF;
    display: inline-block;
    padding: 2px 14px;
    border-radius: 3px;
}

@media (min-width: 1024px) {

    .stats .stats-item-holder-border {
        border-right: solid 1px #DFE3E8;
    }

}

@media (max-width: 960px) {

    .stats .stats-chart-holder.stats-chart-holder-full {
        min-width: 100%;
    }

    .stats .stats-item-holder-border:last-child {
        border-right: none;
    }

}
