.warningAlert {
  width: calc(100% - 1rem);
  margin-top: 1.5rem;
  border: 1px solid $clr-warning;
  background-color: $lightOrange;
  box-shadow: 0px 3px 7px rgba(69, 70, 78, 0.12), 0px 7px 10px -2px rgba(69, 70, 78, 0.2);
  border-radius: 8px;

  .alert {

    &Title,
    &Content {
      margin-bottom: 0;
    }
  }

  ul.alertContent {
    padding-left: 1rem;
  }

  .alertContent {
    .payroll-permission-button {
      float: right;
    }
  }

  .ant-alert-content {
    margin-top: 0.2rem;
  }

}
