.clientHeadingSortButton {
  cursor: pointer;
  position: absolute;
  top: -30px;
}
.headingSortButton {
  cursor: pointer;
  position: absolute;
  top: -30px;
}
.OSPanel {
  transition: all 0.2s linear;
}
.overviewSearchForm {
  background-color: #fff !important;
  border-radius: 4px !important;
  height: 20px !important;
  font-size: 15px;
  color: #9fa0ac;
  font-weight: 400;
  padding: 8px !important;
  min-width: 155px;
}
.overViewCell {
  width: 20%;
  text-align: center;
  border: 1px solid #efebeb;
}
.splitCell {
  padding: 0px;
}
.splitCellHolder {
  display: flex;
  justify-content: space-evenly;
  height: 76px;
  flex: 1;
}
.cellData {
  color: black;
  font-size: 14px;
}
.splitCellInner {
  padding-top: 20px;
  width: 50%;
}
.singleCellInner {
  padding-top: 20px;
  width: 100%;
}
.splitCellInner3 {
  padding-top: 20px;
  width: 33.3%;
}
.neutral-cell {
  background-color: #fff;
}
.down-cell {
  background-color: #fad7d7;
}
.up-cell {
  background-color: #d0eddb;
}
.leftText {
  text-align: left;
  white-space: pre;
  padding-left: 10px;
}
.overviewTableHeader {
  border: 2px solid #fff;
  border-bottom: none !important;
  border-top: none !important;
  min-width: 333px !important;
  padding-bottom: 0px;
  font-weight: 600;
  padding-left: 15px;
  width: 21% !important;
}
.overviewTableHeader.short {
  width: 15% !important;
  max-width: 200px;
}
.overviewTableHeader.client {
  width: 21.8% !important;
  max-width: 333px !important;
  min-width: 300px !important;
}

.overViewCell {
  min-width: 336px !important;

  &__client {
    padding: 0px;
    background-color: #F0F3FE;
    color: #0A1E70;
    border: 2px solid #fff;
    text-align: left;
    max-height: 60px;

    & &__content {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;

      &--copy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding-left: 15px;
        text-overflow: ellipsis;
        height: 100%;

        span {
          display: block;
          white-space: 'pre';
          font-size: 0.9rem;
          line-height: 1rem;

          &:first-child {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.3rem;
          }
        }
      }

      &--actions {
        padding: 0 1.3rem 1.3rem;
        display: flex;
        align-items: flex-end;
        height: 100%;

        .loading {
          animation: rotate 1s linear infinite;
        }


      }
    }
  }
}

.resync-status {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .status-icon {
    display: inline-block;
    width: 1rem;
    margin-right: 1rem;
  }
}

.overViewCell.client {
  font-size: 16px !important;
  width: 22.05% !important;
}
.overViewCell.clientNoScroll {
  font-size: 16px !important;
  width: 21.8% !important;
}
.overviewTableHeader.cFlow {
  width: 10% !important;
  max-width: 403px !important;
}
.overviewTableHeader.pCell {
  width: 40.6% !important;
  max-width: 403px !important;
}
.overviewTableHeader.gCell {
  width: 20% !important;
  max-width: 403px !important;
}

.overviewTableHeader.long {
  width: 25.3% !important;
  max-width: 300px !important;
}
.overViewCell.long {
  width: 25.5% !important;
  max-width: 300px !important;
}
.overViewCell.cFlow {
  width: 10% !important;
  max-width: 333px !important;
}
.overViewCell.short {
  width: 15% !important;
  max-width: 200px;
}

.overViewCell.pCell {
  width: 40.6% !important;
  max-width: 200px;
}
.overViewCell.gCell {
  width: 20% !important;
  max-width: 333px !important;
}
.overviewBody {
  min-height: 400px !important;
  height: 100% !important;
  max-height: 74vh !important;
  padding-bottom: 0px;
}
.muted {
  opacity: 0.35;
}
// To set tooltip overlapping
.overview__tooltip {
  position: relative;
  z-index: 9999;
}
