.wysiwyg {
  border: solid 1px var(--clr-border-divider);
  border-radius: 0.25rem;


  &__toolbar {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    flex-grow: 1;
    padding: 0.25rem;
    background-color: var(--clr-bg-blue);
    border-bottom: solid 1px var(--clr-border-divider);
    border-radius: 0.25rem 0.25rem 0 0;
    margin: 0;
    row-gap: 0.5rem;

    @include respond-to('md') {
      flex-wrap: nowrap;
    }

    .rdw-history-wrapper {
      border-left: solid 1px var(--clr-border-divider);
    }

    .rdw-text-align-wrapper,
    .rdw-inline-wrapper {
      border-right: solid 1px var(--clr-border-divider);
    }

    .wysiwyg__delete-button,
    .rdw-block-wrapper,
    .rdw-list-wrapper,
    .rdw-inline-wrapper,
    .rdw-history-wrapper,
    .rdw-text-align-wrapper,
    .rdw-link-wrapper {
      margin: 0;
      flex-wrap: nowrap;

      .rdw-option {
        &-wrapper {
          height: 100%;
          aspect-ratio: 1;
          border-radius: 0.25rem;
          background-color: var(--clr-bg-blue);
          color: var(--clr-txt-primary);
          cursor: pointer;
          border: none;

          &:hover {
            box-shadow: inset -1px -1px 2px var(--clr-border-divider);
            background-color: var(--clr-bg-blue-selected);
          }
        }

        &-active {
          background-color: var(--clr-bg-blue-selected);

          &:hover {
            box-shadow: inset -1px -1px 2px var(--clr-border-divider);
            background-color: var(--clr-bg-blue-selected);
          }
        }
      }

      .rdw-block-dropdown {
        border: solid 1px var(--clr-border-divider);
        border-radius: 0.25rem;

        .rdw-dropdown {
          &-selectedtext {
            @include font-format(body, regular);
          }

          &-optionwrapper {
            background-color: var(--clr-bg-white);
            border-radius: 0.25rem;
            border: none;
          }

          &option {
            &-default {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding: 0.5rem;
              color: var(--clr-txt-primary);
              border: none;
            }

            &-active {
              background-color: var(--clr-bg-blue);
              color: var(--clr-txt-primary);
              border: none;
            }
          }
        }
      }
    }
  }

  .rdw-center-aligned-block {
    .public-DraftStyleDefault-ltr {
      text-align: center;
    }
  }

  .rdw-link-modal {
    display: grid;
    width: min-content;
    height: min-content;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto auto;
    gap: 0.5rem;
    align-items: center;

    .rdw-link-modal-label {
      @include font-format(body, regular);
      white-space: nowrap;
      height: auto;
      margin: 0;
      padding: 0;
    }

    .rdw-link-modal-input {
      padding: 1rem 0.5rem;
      border: solid 1px var(--clr-border-divider);
      border-radius: 0.25rem;
      margin: 0;
    }

    .rdw-link-modal-target-option {
      display: none;
    }

    .rdw-link-modal-buttonsection {
      grid-column: span 2;
      justify-content: flex-end;

      .rdw-link-modal-btn {
        border-radius: 1rem;
        background: var(--_bg);
        color: var(--_txt);
        border-color: var(--_border);
        @include font-format(body, semibold);

        &:first-child {
          --_bg: var(--clr-btn-fill-link);
          --_txt: var(--clr-text-white);
          --_border: var(--clr-btn-fill-link);

          &:hover {
            --_bg: var(--clr-btn-fill-hover);
            --_border: var(--clr-btn-fill-hover);
          }
        }

        &:last-child {
          --_bg: var(--clr-bg-white);
          --_txt: var(--clr-btn-line-danger);
          --_border: var(--clr-btn-line-danger);

          &:hover {
            --_bg: var(--clr-bg-danger);
          }
        }

        &:disabled {
          --_bg: var(--clr-btn-fill-disabled);
          --_txt: var(--clr-btn-line-disabled);
          --_border: var(--clr-btn-fill-disabled);
          cursor: not-allowed;

          &:hover {
            --_bg: var(--clr-btn-fill-disabled);
            --_border: var(--clr-btn-fill-disabled);
          }

        }

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  .rdw-link-decorator-wrapper {
    margin-right: 0;

    .rdw-link-decorator-icon {
      display: none;
    }

  }

  &__editor {
    &__text {
      min-height: 15rem;
    }

    max-height: 50vh;
    padding: 0.5rem;

    .public-DraftStyleDefault-block {
      margin-top: 0;
    }
  }

  &__custom-buttons {
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
  }

  &__delete-button {
    justify-self: flex-end;
  }

  &__custom-dropdown {
    .rdw-block-dropdown {
      width: max-content;
      max-width: 20ch;

      .rdw-dropdown-selectedtext {
        span {
          width: 100%;
        }

        .label--placeholder {
          color: var(--clr-text-disabled);
        }

        padding-left: 0.5rem;
        padding-right: 1.5rem;
        white-space: nowrap;

        .rdw-dropdown-caretto {

          &open,
          &close {
            right: 0.5rem;
          }
        }
      }

    }
  }



}

.main {
  .report-editor__content {
    strong {
      @include font-format(body, bold);
    }

    a {
      @include font-format(body, regular);
    }

    ul,
    ol {
      display: flex;
      flex-direction: column;

      &:has(li[style*="text-align: center"]) {
        align-items: center;
      }

      &:has(li[style*="text-align: right"]) {
        align-items: flex-end;
      }

      .rdw-center-aligned-block {
        align-self: center;
      }

      .rdw-right-aligned-block {
        align-self: flex-end;
      }
    }

    &__row {
      &--content {
        &--header {
          .wysiwyg__editor {
            * {
              @include font-format(h1, regular);
              font-size: 4rem;
              line-height: 1.6em;
              color: var(--clr-text-title);
              padding: 0;
              margin: 0;
            }
          }

          &--content {
            padding: 0;
            @include font-format(h1, regular);
            font-size: 4rem;
            line-height: 1.6em;
            color: var(--clr-text-title);
          }
        }
      }
    }
  }


}

.rdw-suggestion-dropdown {
  position: absolute;
  z-index: 99999;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  overflow: auto;
  border: solid 1px var(--clr-border-divider);
}

.rdw-suggestion-option,
.wysiwyg__minimal a.rdw-mention-link,
.main a.rdw-mention-link,
.main .rdw-trend-wrapper,
.main .rdw-image-wrapper,
.main .rdw-mention-span,
.wysiwyg__minimal .rdw-mention-span {
  border: solid 1px var(--clr-btn-line-link);
  border-radius: 1em;
  background-color: var(--clr-bg-blue);
  padding: 0.2rem 0.5rem;
  font-size: inherit;
  line-height: 2.1em;
  font-weight: inherit;
  color: var(--clr-btn-line-link);
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;

  &:hover,
  &:focus,
  &-active {
    background-color: var(--clr-bg-blue-selected);
  }
}

.main a.rdw-mention-link,
.main .rdw-trend-wrapper {
  line-height: 2.1em;
}

.wysiwyg__minimal .rdw-mention-span {
  padding: 0 0.2rem;
  line-height: 1.6em;
  @include font-format(footnote, regular);
}

.wysiwyg__toolbar[style="visibility: hidden;"] {
  display: none;
}

.main .report-editor__content {
  & .wysiwyg-mention {
    border: solid 1px var(--clr-btn-line-link);
    border-radius: 1em;
    background-color: var(--clr-bg-blue);
    padding: 0 0.5rem;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: var(--clr-btn-line-link);
    pointer-events: none;

    &+.wysiwyg-mention {
      margin-left: 0.2rem;
    }
  }

  & .wysiwyg-trend {
    display: inline;
    border: solid 1px var(--clr-btn-line-link);
    border-radius: 1em;
    background-color: var(--clr-bg-blue);
    @include font-format(small, regular);
    color: var(--clr-btn-line-link);
    white-space: nowrap;
    width: fit-content;
    padding: 0.2rem 0.5rem;
    line-height: 2.1em;
    cursor: pointer;

    &:hover,
    &:focus,
    &-active {
      background-color: var(--clr-bg-blue-selected);
    }

    & ul {
      margin: 0;
    }

    & p {
      margin: 0;
    }

    &+.wysiwyg-trend {
      margin-top: 0.5rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:has(.wysiwyg-mention) {
      margin-bottom: 1.25em;
    }
  }

}

.wysiwyg-custom-dropdown {
  @include not-respond-to('sm') {
    max-width: 15ch;
  }

  margin-inline: 0.2rem;
  flex-shrink: 1;

  .ant-select-arrow {
    color: var(--clr-text-main);
    transition: transform 0.2s ease-out;
  }

  &.ant-select-open .ant-select-arrow {
    transform: scaleY(-1);
  }

  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: var(--clr-border-divider);
    }
  }

  &__variables {
    min-width: 30ch;
  }
}

.rdw-suggestion-option {
  padding-top: 0.2rem;
  @include font-format(small, regular);
  width: 40ch;
  text-wrap: pretty;
}

.wysiwyg__minimal {
  background: var(--clr-bg-white);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include font-format(small, regular);

  .wysiwyg__toolbar {
    flex-grow: 0;
  }

  .wysiwyg__editor {
    padding: 0;
    overflow-y: auto;
  }

  .DraftEditor-root {
    margin: 0;
  }

  .rdw-editor-main {
    height: auto;
  }

  .public-DraftEditor-content {
    padding: 0.5rem;
  }

}

