/**
  * _mixins.scss
  * This file contains all the mixins used in the project
  * Must be imported after `_common.scss` in order to have
  * access to the variables defined in there.
  */

/**
  * Mixin to manage responsive breakpoints for mobile first
  * @param {String} $breakpoint - Breakpoint name
  * @require $breakpoints
  */
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "`#{$breakpoint}` not found. Valid breakpoints are: #{map-keys($breakpoints)}.";
  }
}

/**
  * Mixin to manage responsive breakpoints for desktop first
  * @param {String} $breakpoint - Breakpoint name
  * @require $breakpoints
  */
@mixin not-respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "`#{$breakpoint}` not found. Valid breakpoints are: #{map-keys($breakpoints)}.";
  }
}

/**
  * Mixin to manage responsive breakpoints by range
  * @param {String} $breakpoint - Breakpoint name
  * @require $breakpoints
  */
@mixin between($min-breakpoint, $max-breakpoint) {
  @if map-has-key($breakpoints, $min-breakpoint) {
    @if map-has-key($breakpoints , $max-breakpoint){
      @media (min-width: map-get($breakpoints, $min-breakpoint)) and (max-width: map-get($breakpoints, $max-breakpoint)) {
        @content;
      }
    }
  }

  @else {
    @warn "`#{$breakpoint}` not found. Valid breakpoints are: #{map-keys($breakpoints)}.";
  }
}

/**
  * Mixin to manage font sizes, line heights, font weights and text transforms
  * ensuring that it complies with our style guide
  *
  * @param {String} $size - Font size name
  * @param {String} $weight - Font weight name
  * @param {String} $transform - Text transform name
  * @require $fontSizes
  * @require $lineHeights
  * @require $fontWeights
  * @require $fontTransforms
  */
@mixin font-format($size, $weight, $transform: none) {
  font-size: map-get($fontSizes, $size);
  line-height: map-get($lineHeights, $size);
  font-weight: map-get($fontWeights, $weight);
  text-transform: map-get($fontTransforms, $transform);
  text-rendering: optimizeLegibility;
}
