.actionButton {
  cursor: pointer;
  width: 150px;
  height: 40px;
  line-height: 36px;
  border: 2px solid #4264ea;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  color: #fff !important;
  background-color: #4264ea !important;
}

.actionButtonAB {
  cursor: pointer;
  width: 150px;
  height: 40px;
  line-height: 30px !important;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  color: #272830 !important;
  background-color: #e8e7ee !important;
}

.actionButtonAB-chat {
  z-index: 997;
  bottom: 10;
  position: relative;
  float: right;
  width: 25px;
  height: 25px;
  margin-right: 30;
  margin-top: 10;
  line-height: 25px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  color: #272830 !important;
  background-color: #e8e7ee !important;
}

.actionButtonAB:hover {
  background-color: #c5c4cb !important;
}

.actionButtonAB-chat:hover {
  background-color: #c5c4cb !important;
}

.CTAButton {
  width: 150px;
  background-color: #4264ea;
  border-radius: 100px;
  border: none;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
  height: 40px;
}

.rboption {
  min-width: 140px !important;
  margin: 10px;
  margin-left: 0px;
}

.rbItemOption.rbinactiveOption {
  border: 1px solid #d8d8d8 !important;
}

.rbItemOption.rbactiveOption {
  border: 1px solid #4264ea !important;
}

.radioButton {
  min-width: 200px;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  padding: 16px;
  text-align: center;
  color: #4264ea;
  line-height: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.rbItem {
  margin-right: 15px;
  display: inline-flex;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #4264ea;
  color: #4264ea;
  text-align: center;
  position: relative;
}

.rbItemOption {
  margin-right: 15px;
  display: inline-flex;
  border-radius: 50%;
  min-width: 15px;
  min-height: 15px;
  height: 15px;
  background-color: #fff;
  color: #4264ea;
  text-align: center;
  position: relative;
}

.internal {
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background-color: #4264ea;
  color: #4264ea;
  text-align: center;
  position: relative;
  left: 1px;
  top: 1px;
}

.loadingButtonBackground {
  background-color: #fff !important;
  border: 2px solid #4264ea !important;
  width: 160px !important;
  height: 60px !important;
  padding-top: 12px !important;
  display: flex;
  justify-content: center;
  cursor: progress;
}

.abn-backArrowHolder {
  position: absolute;
  left: -15px;
  top: 14px;
}

.backArrowHolder {
  position: absolute;
  left: -15px;
  top: -2px;
}

.backArrowHolder-messaging {
  position: absolute;
  left: 30px;
  top: 15px;
}

.backArrowHolder-messaging-instance {
  position: absolute;
  left: 40px;
  top: 0px;
  color: #4264ea;
}

.emptyBar {
  left: 11px !important;
}

.select-user-instance {
  color: #1d1d1d;
  height: 90px;
  margin: 0px;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  padding-top: 10px !important;
  border-bottom: 1px solid #e1e1e599;
}

.select-user-instance-sp {
  width: 230px !important;
  font-size: 14px !important;
  background-color: #fff;
  color: #0a1e70;
  font-weight: 400 !important;
  height: 50px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
}

.sp-selected {
  background-color: #ecf0fd !important;
  font-weight: 900 !important;
}

.select-user-instance-sp:hover {
  background-color: #ecf0fd !important;
}

.select-user-instance:hover {
  background-color: #f5f4fa !important;
}

.backArrowHolder>i {
  font-size: 28px !important;
  font-weight: 200 !important;
}

.buttonArrowHolder {
  position: absolute;
  right: 10px;
  top: 12px;
}

.buttonArrowHolder-link {
  position: absolute;
  right: -15px;
  top: 10px;
}

.bah-small {
  position: absolute;
  right: 10px;
  top: 14px;
}

.bah-small-invite {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #4264ea !important;
}

.buttonArrowHolder>i {
  font-size: 32px !important;
  color: #fff !important;
}

.buttonArrowHolder-link>i {
  font-size: 32px !important;
  color: #4264ea !important;
}

.bah-small>i {
  font-size: 28px;
  color: #fff !important;
}

.internal-inactive {
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background-color: #fff;
  text-align: center;
  position: relative;
  left: 1px;
  top: 1px;
}

.rbactive {
  border: 1px solid #4264ea !important;
  color: #4264ea !important;
}

.rbactiveOption {
  color: #4264ea !important;
}

.rbinactive {
  border: 1px solid #828282 !important;
  color: #8e8e8e;
}

.rbinactiveOption {
  color: #8e8e8e;
}

.inviteTableBody {
  display: block;
  height: 200px;
  overflow: auto;
}

.inviteTableBodyClient {
  display: block;
  height: 155px;
  overflow: auto;
}

.dataCell {
  height: 20px !important;
}

.buttonCell {
  height: 40px !important;
  margin-top: 20px;
}

.editButtonCell {}

.inviteTable,
.inviteRow .inviteCell {}

.inviteTableHead,
.inviteTableBody .inviteRow {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.clientInviteHead {
  margin-top: 20px !important;
  max-height: 0px !important;
}

.inviteTableHead {
  width: 100%;
  height: 5vh !important;
  max-height: 80px;
}

.inviteTable {
  width: 100%;
}

.actionCell {
  padding: 5px !important;
}

.emailCell {
  padding: 0px !important;
}

.emailCellAdvisor {
  padding-left: 15px !important;
}

.userNameCell {
  padding: 0px !important;
}

.inviteButton {
  line-height: 20px !important;
  height: 30px !important;
}

.reinvite {
  height: 30px !important;
  line-height: 18px !important;
}

.inviteButtonNew {
  width: 23% !important;
  max-width: 160px !important;
  min-width: 120px !important;
}

.actionButtonNew {
  padding-right: 25px;
  min-width: 180px;
  background-color: #4264ea;
  border-radius: 100px;
  border: none;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 20px !important;
  cursor: pointer;
  position: relative;
  height: 52px;
}

.transparent-link {
  text-align: end;
  background-color: transparent !important;
  color: #4264ea !important;
}

.alertButton {
  background-color: #eb5757 !important;
}

.disabledButtonNew {
  background-color: #c3c4c9 !important;
  color: #fff !important;
  cursor: not-allowed !important;
}

.abn-back {
  background-color: #fff !important;
  color: #4264ea !important;
}

.abn-small {
  font-size: 14px !important;
  line-height: 28px;
  min-width: 160px !important;
}

.abn-medium {
  font-size: 18px !important;
  line-height: 28px;
  min-width: 160px !important;
}

.abn-large {
  font-size: 22px !important;
  line-height: 28px;
  min-width: 160px !important;
}

.refreshButton {
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  width: 90%;
  height: 30px;
  line-height: 26px;
  background-color: #f5f4fa !important;
  border: 1px solid #fafafa !important;
  color: #4264ea !important;
  cursor: pointer;
}

.refreshButton:hover {
  background-color: #faf9ff !important;
  border: 1px solid #fafafa !important;
}

.actionButton:hover {
  background-color: #3300ff !important;
  border: 2px solid #3300ff;
  color: #fff !important;
}

.otherAction {
  cursor: pointer;
  width: 150px;
  height: 40px;
  line-height: 36px;
  border: 2px solid #4264ea;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  color: #fff !important;
  background-color: #4264ea !important;
}

.otherAction:hover {
  background-color: #3300ff !important;
  color: #fff !important;
}

.update {
  width: 170px !important;
}

.update:hover {
  font-weight: 600;
}

.warning {
  width: 170px !important;

  color: #ff902c;
  border: 2px solid #ff902c;
}

.warning:hover {
  color: #fff !important;
  font-weight: 600;
  border: 2px solid #ff902c !important;
  background-color: #ff902c !important;
}

.critical {
  width: 170px !important;
  margin-left: 20px;
  color: #ff3e03;
  border: 2px solid #ff3e03;
}

.critical:hover {
  color: #fff !important;
  font-weight: 600;
  border: 2px solid #ff3e03 !important;
  background-color: #ff3e03 !important;
}

.bigButton {
  height: 50px !important;
  width: 205px !important;
  border-width: 2.5px !important;
  font-size: 18px;
  font-weight: 600;
}

.resetMargins {
  margin-right: 12px !important;
}

.signInButton {
  line-height: 50px !important;
  height: 52px !important;
  width: 170px !important;
  font-size: 18px;
  font-weight: 400;
  background-color: #4264ea;
  color: #fff;
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
  outline: none !important;
  border: none !important;
}

.bigButtonWidth {
  height: 50px !important;
  border-width: 2.5px !important;
  font-size: 18px;
  font-weight: 600;
}

.bigButton:hover {
  background-color: #4264ea !important;
  color: #fff !important;
}

.bigButtonWidth:hover {
  background-color: #4264ea !important;
  color: #fff !important;
}

.welcomeText {
  font-size: 1.25rem;
}

.textButton {
  height: 40px;
  width: 40px;
  display: inline-block;
  cursor: pointer;
  border: 2px solid #8e8e8e;
  color: #8e8e8e;
  line-height: 34px;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  background-color: white;
}

.disabledTextArea {
  color: #8e8e8e;
}

.no-outline {
  outline: none !important;
}

.form-control {
  border-radius: 8px !important;
  border: 1px solid #fff !important;
  background-color: #4264ea !important;
  color: white !important;
}

.actionButton.addBusiness {
  float: right;
  margin-right: 10px;
  color: #fff;
  background-color: #4264ea;
}

.actionButton.addBusiness:hover {
  border: 2px solid #4264ea;
  color: #4264ea;
}

.actionButton.addBusiness-small {
  float: right;
  margin-right: 10px;
  color: #272830;
  background-color: #e8e7ee !important;
}

.actionButton.addBusiness-small:hover {
  color: #272830 !important;
}

.users {
  font-size: 15px !important;
  height: 36px !important;
  width: 110px !important;
  line-height: 28px !important;
}

.actionButtonMaterialize {
  cursor: pointer;
  width: 150px;
  height: 40px;
  line-height: 32px;
  border: 2px solid #4264ea;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  color: #4264ea;
  background-color: white;
  margin-right: 48px !important;
}

.disabledButton {
  cursor: default;
  width: 150px;
  height: 40px;
  line-height: 32px;
  border: 2px solid #c8c8c8;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  color: #c8c8c8 !important;
  background-color: white !important;
}

.selectButton {
  background-color: #fff !important;
  color: #4264ea;
}

.selectButtonDark {
  background-color: #fff !important;
  color: #0d206c;
}

.selectButton:hover {
  background-color: #fff !important;
  color: #4264ea;
}

.selectButtonDark:hover {
  background-color: #0d206c !important;
  color: #fff;
}

.disabledButton:hover {
  border: 2px solid #c8c8c8 !important;
  color: #c8c8c8 !important;
  background-color: white !important;

  .aiderDarkBlueText {
    color: #0d206c;
  }

  .lightBlueContainer {
    background-color: #e3e6f8;
  }
}

//MODALS AND FORMS
.modalMenuItem.active {
  background-color: #e3e6f8 !important;
  color: #0d206c !important;
  cursor: pointer !important;
  font-weight: 700 !important;
}

.modalMenuItem {
  background-color: #fff;
  color: #0d206c;
  cursor: pointer !important;
  padding: 8px;
  margin: 5px;
  border-radius: 4px;
  height: 34px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.modalMenuItem:hover {
  background-color: #f2f3ff !important;
}

.modalDpGrey {
  color: #8e8e95;
  font-size: 15px;
}

.modalDpDarkGrey {
  color: #272830;
  font-size: 13px;
}

.modalForm {
  display: grid;
}

.taxForm {
  min-width: 60px !important;
}

.modalFormItem {
  border: 1px solid #e1e1e5 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 100px;
  border-radius: 4px !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.modalFormItem.full {
  width: 100%;
}

.modalFormItem.half {
  width: 50%;
}

.formErrorClass {
  border: 1px solid #4264ea !important;
}

.formErrorMessage {
  padding-left: 6px;
  color: #4264ea;
}

// Client Configuration
.clientConfigModal {
  color: var(--clr-black);

  &.general {
    display: flex;
    flex-direction: column;
  }

  &.head1 {
    font-size: 2rem;
    display: flex;
    color: var(--clr-text-title);
    padding-bottom: 1rem;
    font-weight: bold;
  }

  &.head2 {
    font-size: 1.5rem;
    display: flex;
    color: var(--clr-text-title);
  }

  &.combined {
    display: inline-flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &.bullets {
    font-size: 0.9rem;
    max-width: 100ch;
    padding-right: 1rem;
  }
  .bankSelect {
    width: 40ch;

    &.ant-select-multiple {
      .ant-select-selection-item  {
        display: flex;
        align-items: center;
        border-radius: 1rem;
        padding: 0.2rem 0.5rem;
        margin: 0;
        margin-right: 0.5rem;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1rem;
        height: 1.2rem;
        background-color: var(--clr-text-blue);
        color: var(--clr-bg-white);
        border: solid 1px var(--clr-border-info);
        height: auto;

        .ant-select-selection-item-remove {
          color: var(--clr-bg-white);
        }
      }
    }


  }

  .taxRateInput {
    width: 10ch;
  }

  &.super {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;

    .select {
      width: 40ch;
    }
  }

  &.bas {
    display: flex;
    flex-direction: column;
    padding-bottom: 40;

    .basRadioGroup {
      display: inline-flex;
      border: 1px solid var(--clr-disabled);
      border-radius: 4px;
      width: calc(100% - 2rem);
      padding: 0.5rem;
      margin-bottom: 0.5rem;

      .taxAgent {
        display: inline-flex;
        padding: 0.5rem;

        .ant-radio {
          align-self: flex-start;
          margin-top: 0.5rem;
        }

        &.ant-radio-wrapper-checked {
          color: var(--clr-blue);
        }

        span:not(.ant-radio) {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}


//

.navBarItem {
  cursor: pointer;
  min-width: 50px;
  height: 80px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  position: 'relative';
}

.navBarItem:hover {
  background-color: rgba(41, 80, 203, 0.89) !important;
  -o-transition: 0.25s;
  -ms-transition: 0.25s;
  -moz-transition: 0.25s;
  -webkit-transition: 0.25s;
  /* ...and now for the proper property */
  transition: 0.25s;
}

.nvi-active {
  border-bottom: 3px solid #fff !important;
  background-color: #5b78cb !important;
}

.fullHeight {
  flex-wrap: nowrap;
  height: inherit;
  overflow-y: hidden !important;
}

.headerComponent {
  font-family: $font-heading;
  position: fixed;
  height: 100vh;
  min-width: 70px;
  background: #4264ea;
  color: #13dfe0;
  min-height: 65px;
  padding-top: 25px;
  z-index: 999;
}

.body-content {
  width: 100%;
}

.full-size-content {
  width: 100%;
  height: 100%;
}

.loginHeader {
  background-color: #ffffff !important;
}

.signup-form {
  margin: 15px 0px 0px 15px !important;
  min-width: 400px;
  max-width: 425px !important;
  border: 1px solid #c3c4c9 !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  box-shadow: 0 1px 0 0 #4264ea;
  margin-top: 40px !important;
}

.signup-form-text {
  margin: 15px 0px 0px 15px !important;
  min-width: 400px;
  max-width: 425px !important;
  padding-left: 10px !important;
  margin-top: 40px !important;
}

.signup-form-short {
  margin: 15px 0px 0px 15px !important;
  min-width: 200px;
  max-width: 200px !important;
  border: 1px solid #c3c4c9 !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  box-shadow: 0 1px 0 0 #4264ea;
  margin-top: 40px !important;
}

.invite-form {
  min-width: 140px;
  max-width: 220px;
  border-bottom: 2px solid #4264ea !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.invite-form-email {
  min-width: 140px;
  width: 290px !important;
  max-width: 300px;
  border: 2px solid #4264ea !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.invite-form-name {
  min-width: 120px;
  width: 230px !important;
  border: 2px solid #4264ea !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.edit-form-tax {
  margin-bottom: 0px !important;
  min-width: 120px;
  width: 400px !important;
  border-bottom: 2px solid #4264ea !important;
  box-shadow: none !important;
}

.edit-form-tax-settings {
  margin-bottom: 0px !important;
  min-width: 120px;
  border-radius: 4px !important;
  width: 400px !important;
  box-shadow: none !important;
}

.tax-settings-active {
  padding-left: 10px !important;
  border: 2px solid #4264ea !important;
  margin-left: 10px !important;
}

.tax-settings-disabled {
  padding-left: 10px !important;
  border: 2px solid #f1f1f1 !important;
}

/* .expand{
   height:80px;
   overflow: hidden;
   transition: height 2s ease-out;
 }*/
.disabledField {
  border-bottom: 2px solid #f1f1f1 !important;
}

.disabledFieldRound {
  border: 2px solid #f1f1f1 !important;
}

.days {
  width: 90px !important;
}

.bank {
  width: 220px !important;
}

.hiddenDiv {
  visibility: hidden;
}

.info-form-name {
  min-width: 350px;
  width: 400px !important;
  border-bottom: 2px solid #4264ea !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.info-form-name-thick {
  min-width: 350px;
  width: 390px !important;
  border: 2px solid #4264ea !important;
  padding-left: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.left25 {
  margin-left: 25px !important;
}

.info-form-count {
  min-width: 100px;
  border-bottom: 2px solid #4264ea !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.info-form-count-thick {
  min-width: 100px;
  border: 2px solid #4264ea !important;
  padding-left: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.activeFieldRound {
  border: 2px solid #4264ea !important;
}

.activeFieldRoundDark {
  border: 2px solid #0d206c !important;
}

.selectionButton {
  text-align: center !important;
  cursor: pointer;
  border: 1px solid #4264ea;
}

.selectionButtonDark {
  text-align: center !important;
  cursor: pointer;
  border: 1px solid #0d206c;
}

.warningBorder {
  border: #ff902c 2px solid !important;
  color: #ff902c !important;
}

.warningBorder:hover {
  border: #ff902c 2px solid !important;
  color: #ff902c !important;
}

.disabledBorder {
  border: #c8c8c8 2px solid !important;
  color: #c8c8c8 !important;
}

.disabledBorder:hover {
  border: #c8c8c8 2px solid !important;
  color: #c8c8c8 !important;
}

.full-border-form {
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 100px;
  border-radius: 4px !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.signup-form-create {
  border-bottom: 2px solid #4264ea !important;
  box-shadow: 0 1px 0 0 #4264ea;
}

.bp3-input input,
input.filter__input,
input[type='url'],
input[type='time'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='search'],
textarea.materialize-textarea {
  border-bottom: none !important;
  box-shadow: none !important;
  outline: none !important;
}

input[type='select'] {
  border-bottom: none !important;
  outline: none !important;
}

.focused {
  outline: none !important;
}

body {
  font-family: $font-body;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.no-padding {
  padding: 0px !important;
  margin: 0px !important;
}

.tablePanel {
  max-height: 88vh;
  height: 100%;
}

.selectedAdvisor {
  background-color: rgb(199, 199, 199);
}

.advisorRow {
  padding-left: 5px;
  border-bottom: solid 1px #e1e1e599;
}

.advisorRow:hover {
  background-color: #f1f1f1;
}

//toast

.businessUpdateToast {
  min-height: 60px !important;
  padding: 10px 15px !important;
  min-width: 400px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border: 2px solid #d8d8d8 !important;
  border-radius: 8px !important;
  text-align: left !important;
  color: #272830 !important;
  background-color: #f0f3fe !important;
}

.advisorChatToast {
  padding: 10px 15px !important;
  height: 70px !important;
  width: 400px !important;
  font-size: 12px !important;
  border: 2px solid #d8d8d8 !important;
  border-radius: 8px !important;
  text-align: left !important;
  color: #272830 !important;
  background-color: #f0f3fe !important;
}

/* The container */
.rad {
  margin-bottom: 2rem;
}

.Rcontainer {
  max-width: 200px !important;
  width: 150px;
  display: inline-block !important;
  position: relative !important;
  padding-left: 35px !important;
  margin-right: 40px !important;
  margin-bottom: 12px !important;
  cursor: pointer !important;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.Rcontainer input {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
}

/* Create a custom radio button */
.Rbox {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 25px !important;
  width: 25px !important;
  background-color: #eee !important;
  border-radius: 50% !important;
}

/* On mouse-over, add a grey background color */
.Rcontainer:hover input~.Rbox {
  background-color: #ccc !important;
}

.Rcontainer:hover input~.nohover {
  background-color: #eeeeee !important;
}

.nohover {
  cursor: default !important;
}

/* When the radio button is checked, add a blue background */
.Rcontainer input:checked~.Rbox {
  background-color: #4264ea !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Rbox:after {
  content: '' !important;
  position: absolute !important;
  display: none !important;
}

/* Show the indicator (dot/circle) when checked */
.Rcontainer input:checked~.Rbox:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.Rcontainer .Rbox:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.userinfopadded {
  margin-right: 15px !important;
  font-size: 18px !important;
}

.Ccontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Ccontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  width: 25px;
  background-color: #fff;
  border: 1px #c3c4c9 solid;
}

/* On mouse-over, add a grey background color */
.Ccontainer:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.Ccontainer input:checked~.checkmark {
  background-color: #4264ea;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Ccontainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Ccontainer .checkmark:after {
  left: 8px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1em;
  gap: 0.5rem;
  cursor: pointer;
  line-height: 100%;
}

.large-button {
  text-wrap: wrap;
  height: auto;
}

.bankSelect__dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      color: var(--clr-text-blue);
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.3rem;

      &.ant-dropdown-menu-item-disabled {
        color: var(--clr-text-main);
        background-color: var(--clr-bg-blue-selected);
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.3rem;
      }

      &:not(.ant-dropdown-menu-item-disabled):hover {
        color: var(--clr-text-main);
        background-color: var(--clr-bg-blue);
      }

      .anticon {
        font-size: 1.25rem;
      }
    }
  }

}

.ant-select-dropdown:has(.bankSelect__dropdown) {
  .ant-select-item-option {
    &:not(&-selected) {
      padding-left: 1.6em;
    }
  }
}
