$blue: #4264ea;
$darkBlue: #0a1e70;
$lightBlue: #f0f3fe;
$lightCream: #f1f1f1;
$lightGrey: #e1e1e5;
$grey: #767677;
$red: #eb5757;
$lightOrange: #FCE8D6;
$neutral: #272830;
$lightNeutral: #5B5C61;
$green: #27ae60;

// Colors named as per the design system
$clr-teal: #00e0e1;
$clr-darkTeal: #03babb;
$clr-text-primary: #9fa0ac;
$clr-text-secondary: #5b5c61;
$clr-white: #ffffff;
$clr-black: #272830;
$clr-text-heading: #0a1e70;
$clr-bg-bg1: #ffffff;
$clr-bg-bg2: #f5f5f9;
$clr-bg-bg3: #eeedf3;
$clr-bg-bg4: #e1e1e5;
$clr-bg-blue: #f0f3fe;
$clr-bg-teal: #e0fbfb;
$clr-bg-darkBlue: #dee3f6;
$clr-bg-navy: #cfd2e2;
$clr-bg-success: #d0eddb;
$clr-bg-warning: #fce8d6;
$clr-bg-danger: #fad7d7;

$clr-disabled:#C3C4C9;
$clr-hover: #0a1e70;
$clr-success: #27AE60;
$clr-warning: #F39E53;
$clr-danger: #EB5757;

/* The below code is a duplicate of the SASS vars above, but to expose
them to vanilla css we need to use the CSS custom properties syntax */
:root{
  --clr-blue: #4264ea;
  --clr-teal: #00e0e1;
  --clr-darkTeal: #03babb;
  --clr-text-primary: #9fa0ac;
  --clr-text-secondary: #5b5c61;
  --clr-white: #ffffff;
  --clr-black: #272830;
  --clr-text-title: #0a1e70;
  --clr-bg-bg1: #ffffff;
  --clr-bg-bg2: #f5f5f9;
  --clr-bg-bg3: #eeedf3;
  --clr-bg-bg4: #e1e1e5;
  --clr-bg-blue: #f0f3fe;
  --clr-bg-teal: #e0fbfb;
  --clr-bg-darkBlue: #dee3f6;
  --clr-bg-navy: #cfd2e2;
  --clr-bg-success: #d0eddb;
  --clr-bg-warning: #fce8d6;
  --clr-bg-danger: #fad7d7;
  --clr-disabled:#C3C4C9;
  --clr-hover: #0a1e70;
  --clr-success: #27AE60;
  --clr-warning: #F39E53;
  --clr-danger: #EB5757;
}
