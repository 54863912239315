
.grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.grid-cell {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-basis: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 20px !important;
  text-align: center;
}

.grid-flexCells > .grid-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.grid-top {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.grid-bottom {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.grid-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-cell-center-both {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.grid-justifyCenter {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.grid-cell-top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.grid-cell-bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.grid-cell-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.grid-cell-autoSize {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.grid-fit > .grid-cell {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.grid-full > .grid-cell {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.grid-1of2 > .grid-cell {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.grid-1of3 > .grid-cell {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.3333%;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
}

.grid-1of4 > .grid-cell {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.grid-1of5 > .grid-cell {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}

.grid-1of6 > .grid-cell {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.6667%;
  -ms-flex: 0 0 16.6667%;
  flex: 0 0 16.6667%;
}

@media (min-width: 384px) {
  .sm-grid-fit > .grid-cell {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .sm-grid-fit > .grid-cell-autoSize {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }

  .sm-grid-full > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .sm-grid-1of2 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .sm-grid-1of3 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333%;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .sm-grid-1of4 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .sm-grid-1of5 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }

  .sm-grid-1of6 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6667%;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }
}

@media (min-width: 576px) {
  .md-grid-fit > .grid-cell {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .md-grid-fit > .grid-cell-autoSize {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }

  .md-grid-full > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .md-grid-1of2 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .md-grid-1of3 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333%;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .md-grid-1of4 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .md-grid-1of5 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }

  .md-grid-1of6 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6667%;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }
}

@media (min-width: 768px) {
  .lg-grid-fit > .grid-cell {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .lg-grid-fit > .grid-cell-autoSize {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }

  .lg-grid-full > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .lg-grid-1of2 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .lg-grid-1of3 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333%;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
  }

  .lg-grid-1of4 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .lg-grid-1of5 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
  }

  .lg-grid-1of6 > .grid-cell {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6667%;
    -ms-flex: 0 0 16.6667%;
    flex: 0 0 16.6667%;
  }
}

.grid-gutters {
  margin: -1em 0 0 -1em;
}

.grid-gutters.grid-gutters-bottom {
  margin-bottom: 1em;
}

.grid-gutters > .grid-cell {
  padding: 1em 0 0 1em;
}

.grid-guttersLg {
  margin: -1.5em 0 0 -1.5em;
}

.grid-guttersLg.grid-gutters-bottom {
  margin-bottom: 1.5em;
}

.grid-guttersLg > .grid-cell {
  padding: 1.5em 0 0 1.5em;
}

.grid-guttersXl {
  margin: -2em 0 0 -2em;
}

.grid-guttersXl.grid-gutters-bottom {
  margin-bottom: 2em;
}

.grid-guttersXl > .grid-cell {
  padding: 2em 0 0 2em;
}

@media (min-width: 384px) {
  .sm-grid-gutters {
    margin: -1em 0 0 -1em;
  }

  .sm-grid-gutters.grid-gutters-bottom {
    margin-bottom: 1em;
  }

  .sm-grid-gutters > .grid-cell {
    padding: 1em 0 0 1em;
  }

  .sm-grid-guttersLg {
    margin: -1.5em 0 0 -1.5em;
  }

  .sm-grid-guttersLg.grid-gutters-bottom {
    margin-bottom: 1.5em;
  }

  .sm-grid-guttersLg > .grid-cell {
    padding: 1.5em 0 0 1.5em;
  }

  .sm-grid-guttersXl {
    margin: -2em 0 0 -2em;
  }

  .sm-grid-guttersXl.grid-gutters-bottom {
    margin-bottom: 2em;
  }

  .sm-grid-guttersXl > .grid-cell {
    padding: 2em 0 0 2em;
  }
}

@media (min-width: 576px) {
  .md-grid-gutters {
    margin: -1em 0 0 -1em;
  }

  .md-grid-gutters.grid-gutters-bottom {
    margin-bottom: 1em;
  }

  .md-grid-gutters > .grid-cell {
    padding: 1em 0 0 1em;
  }

  .md-grid-guttersLg {
    margin: -1.5em 0 0 -1.5em;
  }

  .md-grid-guttersLg.grid-gutters-bottom {
    margin-bottom: 1.5em;
  }

  .md-grid-guttersLg > .grid-cell {
    padding: 1.5em 0 0 1.5em;
  }

  .md-grid-guttersXl {
    margin: -2em 0 0 -2em;
  }

  .md-grid-guttersXl.grid-gutters-bottom {
    margin-bottom: 2em;
  }

  .md-grid-guttersXl > .grid-cell {
    padding: 2em 0 0 2em;
  }
}

@media (min-width: 768px) {
  .lg-grid-gutters {
    margin: -1em 0 0 -1em;
  }

  .lg-grid-gutters.grid-gutters-bottom {
    margin-bottom: 1em;
  }

  .lg-grid-gutters > .grid-cell {
    padding: 1em 0 0 1em;
  }

  .lg-grid-guttersLg {
    margin: -1.5em 0 0 -1.5em;
  }

  .lg-grid-guttersLg.grid-gutters-bottom {
    margin-bottom: 1.5em;
  }

  .lg-grid-guttersLg > .grid-cell {
    padding: 1.5em 0 0 1.5em;
  }

  .lg-grid-guttersXl {
    margin: -2em 0 0 -2em;
  }

  .lg-grid-guttersXl.grid-gutters-bottom {
    margin-bottom: 2em;
  }

  .lg-grid-guttersXl > .grid-cell {
    padding: 2em 0 0 2em;
  }
}

.panel-route-summary-stats {
  height: 100%;
}

.panel-route-summary-stats .chart-total-routes {
  min-width: 200px;
}

.panel-route-summary-stats .chart-total-routes canvas {
  max-height: 200px;
}

@media (max-width: 960px) {
  .panel-route-summary-stats .chart-total-routes canvas {
    height: 180px;
  }
}

.holygrail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.holygrail-footer-container, .holygrail-header-container {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.holygrail-body-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.holygrail-content-container {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
}

.holygrail-sidebar-container {
  -webkit-box-ordinal-group: 0;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;

  -webkit-box-flex: 0;
  -webkit-flex: 0 0 12em;
  -ms-flex: 0 0 12em;
  flex: 0 0 12em;
}

@media (max-width: 768px) {
  .holygrail-body-container {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }

  .holygrail-content-container {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .holygrail-sidebar-container {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

.panel {
  min-height: 100px;
  border: solid 1px #DFE3E8;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 #DFE3E8;
}

.panel .panel-header-container {
  font-size: 16px;
  font-weight: 500;
  color: #3C4144;
  background-color: #FFFFFF;
  padding: 16px 20px;
  border-bottom: solid 1px #DFE3E8;
}

.panel .panel-content-container {
  padding: 15px;
  color: #212B36;
  background-color: #FFFFFF;
}

.panel .panel-content-container .input-control-container > label {
  color: #212B36;
}

.panel.panel-container .panel-header-container {
  padding: 16px 20px;
}

.panel.panel-container .panel-header-container.panel-header-left {
  text-align: left;
}

.panel.panel-container .panel-header-container.panel-header-center {
  text-align: center;
}

.panel.panel-container .panel-header-container.panel-header-right {
  text-align: right;
}

.panel.panel-container .panel-content-container {
  padding: 20px;
  height: 100%;
  min-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.panel.panel-container .panel-content-container .spinner {
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
}

.panel.panel-container .panel-content-container .vertical-aligned-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  line-height: 20px;
}

@media (max-width: 480px){
  .panel.panel-container .panel-header-container {
    font-size: 14px;
  }
}