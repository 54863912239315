.insight-missing {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 3rem;
  flex-grow: 0;

  svg {
    width: 10rem;
    margin-bottom: 1rem;
  }

  &__illustration {
    width: 121px;
    margin-bottom: 3rem;
  }

  h4 {
    margin-bottom: 1.6rem;
  }

  &__body {
    max-width: 650px;

    &--errors {
      span {
        display: block;
        @include font-format(body, light);
        margin-bottom: 1rem;
      }
    }
    .button-primary {
      @include font-format(h4, semi-bold);
      width: auto;
      flex-grow: 0;
    }
  }
}


.insight-container {
  --_gap: 1rem;

  // These are default colors for elements within the insight container
  // These are overwritten by success / danger specific styles at the bottom of
  // this component style
  --_summary-bg-color: var(--clr-bg-info);
  --_summary-arrow-color: var(--clr-text-title);
  --_summary-trend-color: var(--clr-text-title);
  --_trend-text-color: var(--clr-text-aux);

  & + &,
  & + .insight-v1 {
    margin-top: 1rem;
    border-top: solid 1px var(--clr-primary-2);
    padding-top: 1rem;
  }

  .reconciliation-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .anticon {
      font-size: 1.2em;
      color: var(--_reconciliationColor);
    }

    &__status {
      @include font-format(body, bold);
      color: var(--_reconciliationColor);
    }
  }

  &__details {
    display: flex;
    gap: var(--_gap);

    &--column {
      flex-grow: 1;
      flex-basis: 1;
      max-width: calc(50% - var(--_gap));
      display: flex;
      flex-direction: column;
      gap: var(--_gap);
    }

    &--graphHelp {
      border-top: solid 1px var(--clr-border-divider);
      display: flex;
      flex-grow: 0;
      gap: 0.5rem;
      align-items: center;
      padding-top: 0.25rem;

      h5, p {
        color: var(--clr-text-aux);
        margin: 0;
        padding: 0;
      }

      h5 {
        @include font-format(footnote, semi-bold);
      }

      p {
        @include font-format(footnote, light);
      }
    }

    &--settings {
      p {
        @include font-format(small, light);
        color: var(--clr-text-aux);
        margin: 0;
        padding: 0;
      }
    }

    &--trend {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: flex-start;
      gap: 1rem;
      h4 {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      &--help {
        &.ant-btn.ant-btn-sm.ant-btn-icon-only {
          width: 1.2em;
          min-width: 1.2em;
          height: 1.2em;
          padding: 0;
          aspect-ratio: 1;
          border-width: 1px;
          &:hover {
            border-width: 1px;
          }
          .anticon {
            @include font-format(footnote, light);
          }
        }
      }

      &--body {
        color: var(--_trend-text-color);
        white-space: pre-wrap;

        ul {
          margin-block: 0;
          padding-left: 1rem;
        } 
      }

      footer {
        display: flex;
        gap: 1rem;
      }

    }
  }

  .insight-summaries {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: var(--_gap);

    &__summary {
      flex-grow: 1;
      background-color: var(--_summary-bg-color);
      border-radius: 0.25rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: space-between;
      position: relative;
      margin-bottom: var(--_gap);

      p {
        margin: 0;
        padding: 0;
      }

      h4 {
        @include font-format(h6, regular);
        color: var(--clr-text-aux);
        padding: 0;
        margin: 0 0 0.5rem 0;
      }

      &--value {
        @include font-format(h2, semi-bold);
        color: var(--clr-text-title);
      }

      &--trend {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        @include font-format(h4, semi-bold);
        color: var(--_summary-trend-color);
        .anticon {
          font-weight: bold;
          color: var(--_summary-arrow-color);
          svg path {
            stroke: var(--_summary-arrow-color);
            stroke-width: 20;
          }
        } 
      }

      &--dashboardIcon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        @include font-format(h3, semi-bold);
        color: var(--clr-text-title);
      }

      &__danger {
        --_summary-bg-color: var(--clr-bg-danger);
        --_summary-arrow-color: var(--clr-btn-fill-danger);
      }

      &__success {
        --_summary-bg-color: var(--clr-bg-success);
        --_summary-arrow-color: var(--clr-btn-fill-success);
      }

      &__info {
        --_summary-bg-color: var(--clr-bg-info);
        --_summary-arrow-color: var(--clr-text-title);
      }
    }
  }
  .early-warning-banner {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    background-color: var(--clr-bg-blue);
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;

    &__detail {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex-grow: 1;
      padding-top: 0.5rem;

      h4 {
        @include font-format(h5, bold);
        color: var(--clr-text-title);
        margin: 0;
        padding: 0;

        span {
          color: var(--clr-text-brand-accent);
        }
      }

      p {
        margin: 0;
        padding: 0;
        color: var(--clr-text-blue);
        @include font-format(small, regular);

        span {
          @include font-format(small, bold);
        }
      }
    }
  }

  &__missing {
    &__content {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    &__illustration {
      width: 10rem;
    }

    &__body {
      white-space: pre-wrap;
      max-width: 80ch;
    }
  }

  // Insight Reconciliation specific styles. Needs to remain at bottom of 
  // .insight-container to override other styles
  &__danger {
    --_reconciliationColor: var(--clr-btn-fill-danger);

    // Set the color of the trend direction and arrow
    .insight-summaries {
      &__summary {
        --_summary-bg-color: var(--clr-bg-info);
        --_summary-arrow-color: color-mix(in srgb, var(--clr-text-title) 35%, transparent);
        --_summary-trend-color: color-mix(in srgb, var(--clr-text-title) 35%, transparent);
      }
    }

    --_trend-text-color: var(--clr-gray-5);
  }


  &__success {
    --_reconciliationColor: var(--clr-btn-fill-success);
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  &.budget-selector-item {
    &__new {
      color: var(--clr-text-blue);
    }
    &--active {
      background-color: var(--clr-bg-blue-selected);
      color: var(--clr-text-title);
    }
  }

  &.ant-dropdown-menu-item__active {
    background-color: var(--clr-bg-blue-selected);
    color: var(--clr-text-title);
  }

  &:hover {
    background-color: var(--clr-bg-disabled);
  }

}

.periodSelecterHeader {
  &__block {
    @include font-format(small, bold);
    color: var(--clr-text-white);
  }
}

.insight-info-banner {
  margin-bottom: 1rem;
  .aider-alert__content--body {
    max-width: 100%;
  }
  &__body {
    width: 100%;
    display: flex;
    flex-grow: 1;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    &__copy {
      p:not([role='heading']) {
        @include font-format(small, regular);
      }
    }

    &__actions {
      display: flex;
      gap: 0.5rem;
      align-items: flex-start;
      justify-content: space-between;

      @media (min-width: 1100px) and (max-width: 1400px){
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }
}

.insight-tips {
  & > .ant-collapse-item:last-child {
    .ant-collapse-header {
      padding: 0;
      @include font-format(h4, semi-bold);
      color: var(--clr-text-blue);
      align-items: center;

      .ant-collapse-expand-icon {
        .anticon {
          @include font-format(h4, semi-bold);
        }
      }
    }
  }

  .ant-collapse-content {
    padding-left: 0.5rem;
  }

  &__list {
    &--header {
      color: var(--clr-text-brand-accent);
      margin-right: 0.5rem;
    }
  }
}

