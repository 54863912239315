.icon {
  &__blue {
    color: var(--clr-btn-line-link);
  }

  &__navy {
    color: var(--clr-btn-line-title);
  }

  &__white {
    color: var(--clr-btn-line-white);
  }
}
