
.monthlyReportModal {
  .ant-modal-content {
    .ant-modal-body {
      max-height: calc(100vh - 15rem);
      overflow-y: auto;
      padding: unset;
    }
  }
}

.report-select {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}

.report-sider-heading {
  color: $clr-text-heading;
  font-weight: 900;
  line-height: 18px;
}

.report-content-heading1 {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}

.report-content-heading2 {
  padding-top: 10px;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}

.report-content-heading3 {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin: unset;
}

.report-content-list {
  padding-left: 1rem;
}

.report-chart {
  width: 606px;
}
