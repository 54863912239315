.main {
  .login-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    gap: 2rem;
    padding-top: 10rem;

    &__header {
      font-size: 6rem;
    }

    &__links {
      width: 100%;

      &__link {
        a {
          @include font-format(body, regular);
        }

        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &__text {
          @include font-format(body, bold);
          color: var(--clr-text-title);
        }

        .ant-btn {
          padding-inline: 0;
        }

        @include not-respond-to(lg) {
          flex-direction: column;
          gap: 0;
        }
      }
    }
  }

  .signup-page {
    display: flex;
    height: 100%;

    &__col {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-inline: 4rem;

      &:first-child {
        padding-top: 8rem;
        align-items: flex-end;
        background: var(--clr-bg-blue);
      }

      &:last-child {
        padding-top: 12rem;
        align-items: flex-start;
        background: var(--clr-bg-white);
      }

      &__content {
        width: min(100%, 60ch);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;
      }
    }

    &__header {
      font-size: 4rem;

      svg {
        max-width: 100%;
      }
    }

    &__illustration {
      font-size: 23rem;

      svg {
        max-width: 100%;
      }
    }

    &__title {
      @include font-format(h2, bold);
    }

    &__subtitle {
      @include font-format(h3, regular);
    }

    @include not-respond-to(md) {
      flex-direction: column;
      width: 100svw;
      height: auto;

      &__col {
        padding-block: 2rem;
        padding-inline: 1rem;
        width: 100%;

        &:first-child,
        &:last-child {
          align-items: center;
          padding-top: 2rem;
        }
      }
    }
  }

  .signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    &__image {
      max-width: 20rem;

      img {
        width: 100%;
        aspect-ratio: 1/1;
      }

      @include respond-to(md) {
        max-width: 40rem;
      }
    }

    &__details {
      max-width: 50rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      &--logo {
        font-size: 3rem;
      }

      h1 {
        @include font-format(h2, semi-bold)
      }

      &--checkbox {
        margin-bottom: 0;
      }

      &--form {}
    }

    @include respond-to(md) {
      flex-direction: row-reverse;
      justify-content: space-around;
    }

    .separator-text {
      color: var(--clr-text-title);
    }
  }

}

.social-buttons {
  width: 100%;

  display: flex;
  flex-direction: column;

  .aider-alert {
    margin-bottom: 2rem;
    text-align: left;

    &.terms-checkbox {
      border-color: var(--clr-border-divider);
      padding-block: 0.5rem;
    }
  }

  &__login-buttons {
    border: 0;
    flex: 1;
  }

  &__buttons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .anticon {
      font-size: 1.5rem;

      transition: filter 0.5s ease, opacity 0.5s ease;

      &.social-buttons__disabled {
        filter: grayscale(100%);
        opacity: 0.8;
      }
    }
  }
}
