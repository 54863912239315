.ant-modal {
  $am-border-radius: 1rem;
  & &-content {
    padding: 0;
    border-radius: $am-border-radius;
  }
  & &-header, & &-title {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
    font-family: $font-heading;
    color: $clr-text-heading;
    border-bottom: solid 1px $clr-bg-blue;
    border-top-left-radius: $am-border-radius;
    border-top-right-radius: $am-border-radius;
    margin: 0;

  }
  & &-close {
    &-x {
      line-height: 0;
    }
  }
  & &-title {
    padding: 1rem 1rem 0.6rem 1rem;
  }
  & &-body {
    padding: 1rem;

    &:has(> .bg-blue) {
      background-color: $clr-bg-blue;
    }
  }

  & &-footer {
    margin: 0;
    padding: 1rem;
    border-top: solid 1px $clr-bg-blue;
    display: flex;
    justify-content: space-between;
  }

}

.ant-btn {
  font-weight: 500;
  &.ant-btn-primary {
    &:hover {
      background-color: $clr-hover;
    }
    &.ant-btn-background {
      &-ghost {
      background-color: $clr-white;
        &:not(disabled) {
          &:hover {
            background-color: $clr-white ;
            color: $clr-hover;
            border-color: $clr-hover;
          }
        }
      }
    }
  }
  &.ant-btn-default {
    &:hover {
      color: $clr-hover;
      border-color: $clr-hover;
    }
  }
  &.ant-btn-loading {
    display: flex;
    align-items: center;
    .ant-btn-loading-icon {
      display: flex;
      align-items: center;
    }
  }
}

  .ant-select {
    &.ant-select-multiple {
      .ant-select-selection-item  {
        border-radius: 1rem;
        padding-inline: 1em;
        font-size: 0.8rem;
        font-weight: 600;
        background-color: $blue;
        color: $white;

        .ant-select-selection-item-remove {
          color: $white;
        }
      }
    }
  }
.ant-tree-dropdown {
  .ant-select-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-select-tree-title {
    font-size: 1rem;
    color: var(--clr-text-title);
    font-weight: 700;
  }
  .ant-select-tree-node-content-wrapper-normal {
    .ant-select-tree-title {
      font-size: 1rem;
      color: $black;
      font-weight: 300;
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-group {
    font-size: 1rem;
    line-height: 1.3rem;
    color: $clr-text-heading;
    font-weight: 700;
    text-transform: uppercase;
    & + .ant-select-item-group {
      margin-left: 0.7rem;
      text-transform: capitalize;

    }
  }

  .ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
      &:has(> .ant-select-item-option-state){
        flex-direction: row-reverse;
        padding-left: 0;
        .ant-select-item-option-state {
          padding-inline: 0.3rem;
        }
      }
    }
  }
}

:where(.css-dev-only-do-not-override-ffrose).ant-checkbox-wrapper
  + .ant-checkbox-wrapper {
  margin-inline-start: 0px;
}

:where(.css-ffrose).ant-checkbox-wrapper
  + .ant-checkbox-wrapper {
  margin-inline-start: 0px;
}

:where(.css-dev-only-do-not-override-ffrose).ant-checkbox-disabled
  + span {
    opacity: 0.45;
  }

:where(.css-ffrose).ant-checkbox-disabled
  + span {
    opacity: 0.45;
  }

:where(.css-dev-only-do-not-override-ffrose).ant-checkbox
  + span {
    color: #262626;
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

:where(.css-ffrose).ant-checkbox
  + span {
    color: #262626;
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

.ant-checkbox-group {
  label {
    padding: 4px 0;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}
