.contact__thank {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 7rem;

  &--wrapper {
    @extend .flex--column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
  }

  .contact__title {
    color: $darkBlue;
    font-size: 2.25rem;
    font-weight: 700;
    text-align: center;
  }

  .contact__subTitle {
    color: $neutral;
    font-weight: 100;
  }
}
