.tier-modal {
  &__logo {
    margin: 0 0 2rem 0;
  }

  &__body {
    h1 {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2rem;
      color: $clr-text-heading;
    }

    h1, p {
      margin-bottom: 1.5rem;
    }

    p, h2 {
      color: $clr-black;
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.5rem;
    }

    h2 {
      font-weight: 700;
      margin-bottom: 0.2rem;
    }
  }

  .ant-modal {

    .ant-modal-content {
      padding: 4rem;
      text-align: center;
    }

    .ant-modal-footer {
      border-top: none;
      justify-content: center;
    }
  }
}
