.main,
.aider-modal {
  .advisory-ai {
    width: 100%;
    height: 100%;
    &__content {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-grow: 1;
      width: 100%;
      --sidebar-width: clamp(210px, 20%, 350px);

      &--sidebar {
        height: 100%;
        background: var(--clr-bg-blue);
        width: var(--sidebar-width);
        flex-shrink: 0;
        flex-grow: 1;
        aside {
          h3 {
            @include font-format(small, bold);
            color: var(--clr-text-title);
            margin: 0px;
            padding: 0px;
          }

          .ant-form-item {
            margin: 0;
          }

          padding: 1rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          & + aside {
            border-top: solid 1px var(--clr-border-blue);
          }

          .ant-select {
            width: 100%;
          }

          .ant-btn {
            width: 100%;
            white-space: pre-wrap;
            height: min-content;
            @include font-format(small, regular);

            &.chat-prompt {
              @include font-format(small, semibold);
              margin: 5px 0;
              &.btn-danger {
                color: var(--clr-btn-line-danger);
                border-color: var(--clr-border-danger);

                &:hover {
                  border-color: var(--clr-border-danger);
                  background-color: var(--clr-bg-danger);
                }
              }
            }
          }
          .checkbox-item {
            align-items: center;
            display: flex;
            gap: 0.5rem;
            @include font-format(small, regular);

            &__label {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
      &--main {
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
        border: solid 1px var(--clr-border-blue);
        width: calc(100% - var(--sidebar-width));

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .text-icon {
          border: solid 1px var(--clr-border-blue);
        }

        &__chat-window {
          overflow-y: auto;
          padding: 1rem;
          flex-grow: 0;
          flex-shrink: 1;
          height: 100%;
          width: 100%;

          .chat-message {
            margin-top: 1rem;
            border-radius: 0.25rem;
            display: flex;
            align-items: flex-start;
            padding: 0.5rem;
            gap: 1rem;

            .aider-icon {
              font-size: 2.5rem;
            }
            &__text {
              flex-shrink: 1;
              flex-grow: 1;
              max-width: calc(100% - 100px);
              white-space: break-spaces;
              overflow-x: auto;
            }

            &.message-type {
              &-user {
                background-color: var(--clr-bg-disabled);
              }
            }
            .copy-button {
              display: flex;
              justify-self: flex-end;
              align-items: center;
              justify-content: center;
            }

          }
        }

        .loading-spinner {
          height: auto;
          padding-block: 1rem;
        }
      }
    }
  }
  .assistant-intro {
    color: var(--clr-text-title);

    .brand-name {
      @include font-format(regular, bold);
      color: var(--clr-aider-blue);
      margin-left: 0.2em;
    }

    .secondary-brand {
      @include font-format(regular, bold);
      color: var(--clr-aider-dark-teal);
    }

    strong {
      @include font-format(small, bold);

    }

    .assistant-features {
      display: flex;
      gap: 1rem;

      &__icons {
        display: flex;
        font-size: 3.25rem;
        gap: 1rem;
      }

      &__details {
        @include font-format(small, bold);

        p {
          margin: 0;
          padding: 0
        }

        ul {
          margin: 0;
          padding: 0 0 0 1.4rem ;
        }
      }
    }
  }

  .assistant-user {
    &__form-item {
      flex-grow: 1;
      margin: 0;
      padding: 0;
      height: 100%;

      .ant-row {
        height: 100%;

        .ant-col {
          height: 100%;

          .ant-form-item-control-input {
            height: 100%;

            &-content {
              height: 100%;
            }
          }
        }
      }
    }

    &-input {
      background-color: var(--clr-bg-disabled);
      border-top: solid 1px var(--clr-border-blue);
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__form {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        align-items: center;
        flex-grow: 1;

        textarea {
          height: 100%;
          max-height: 8rem;
          color: var(--clr-text-main);
          @include font-format(small, regular);
          width: 100%;
        }

        .aider-icon {
          font-size: 2.5rem;
        }

        .ant-btn {
          padding: 1.4rem;
          .anticon{
            font-size: 1.6rem;
          }
        }
      }

      &__link {
        padding: 0;
        margin-left: 3.5rem;
      }
    }

    &-send {
      display: flex;
      justify-content: center;
      align-items: center;

      &:disabled {
        background: var(--clr-gray-5);
        color: var(--clr-btn-line-white);
      }
      &.ant-btn-loading {
        background: var(--clr-btn-line-link);
        border-color: var(--clr-btn-line-link);
        color: var(--clr-bg-white);
      }
    }
  }

  .assistant-history {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--clr-bg-blue);
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 0.25rem;
  }
}

@keyframes fade {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.assistant-loader {
  display: grid;
  place-items: center;
  padding: 1rem;

  &__message {
    color: var(--clr-text-title);
    animation: fade 2s infinite;
  }
}

.assistant-insight {
  padding: 1rem;
  flex-grow: 1;
}

.aider-modal {
  .advisory-ai {
    &__content {
      &--sidebar {
        border-bottom-left-radius: 1rem;
      }

      &--main {
        .assistant-user-input {
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
      }
    }
  }
}

.genai-button__dropdown {
  .ant-dropdown-arrow {
    transform: translateY(-90%);
  }

  .ant-dropdown-arrow::after {
    box-shadow: 1px 1px 1px var(--clr-gray-5);
  }

  .ant-dropdown-menu {
    border: 1px solid var(--clr-gray-5);
    border-radius: 6px;
  }

  .ant-dropdown-menu-item {
    color: var(--clr-aider-blue) !important;

    &::before {
      content: '>';
      padding-right: 4px;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .ant-dropdown-menu-item-group-title {
    color: var(--clr-text-main) !important;
  }

  .ant-dropdown-menu-item-group-list {
    margin: 0 !important;
  }
}

