//notification manager

.periodSelecterHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px;
  gap: 24px;
  position: sticky;
  top: 0;
  z-index: 10;
  justify-content: space-between;

  &__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  &:not(.periodSelecterHeader--simple)::before {
    content: '';
    inset: -16px 0 0 0;
    position: absolute;
    background-color: var(--clr-white);
    z-index: -1;
  }

  &::after {
    content: '';
    inset: 0;
    position: absolute;
    background-color: var(--clr-text-title);
    z-index: -1;
    border-radius: 0.25rem;
  }

  /**
  * The background color clash comes from `materialize.min.css`
  *
  *   button:focus {
  *     background-color: #2ab7a9;
  *   }
  */

  button:focus {
    background-color: $lightBlue;
  }

  .selection-dropdown {
    width: 20ch;
  }

  .ant-select-selector {
    font-family: $font-heading;
    color: var(--clr-text-title);
  }

  &__excel-icon {
    color: var(--clr-btn-fill-success);
  }

  &__word-icon {
    color: var(--clr-btn-fill-link);
  }
}



.periodSelecterHeader--relative {
  position: relative;
}

.insightContainerPadding {
  padding: 1rem;
}

.dataInsightsNav {
  border-bottom: 1px solid #e8e7ee;
  color: #999999;
  display: flex;
  align-content: flex-end;
  gap: 2rem;
  padding-inline: 1rem;
}

.disabledTabTitle {
  font-weight: 300 !important;
  color: #c3c4c9 !important;
  cursor: default !important;
}

.disabledTabTitle:hover {
  border-bottom: none !important;
}

.tabTitle {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  gap: 0.5rem;
  box-sizing: border-box;
  margin-top: 3px;
  padding-bottom: 0;
  border-bottom: 0px solid #d8d8d850;
}

.tabTitle:hover {
  margin-top: 0px;
  padding-bottom: 3px;
  border-bottom: 2px solid #d8d8d850;
  -o-transition: 0.25s;
  -ms-transition: 0.25s;
  -moz-transition: 0.25s;
  -webkit-transition: 0.25s;
  /* ...and now for the proper property */
  transition: 0.25s;
  color: #474958;
}

.ttActive {
  border-bottom: 3px solid #4264ea !important;
  font-weight: 600 !important;
  color: black !important;
}

.insightContainer {
  padding: 10px;
  overflow: auto;
  width: 100%;
}

.insightTrendContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.insightAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  position: absolute;
  width: 209px;
  height: 52px;
  right: 128px;
  bottom: 350px;
  background: #4264ea;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 40px;
  cursor: pointer;
}

.headerAction {
  float: left;
  display: flex;
  align-items: center;
  font-size: 20;
  margin-bottom: 10;
  justify-content: center;
  color: #8e8e8e;
  background-color: #fff;
  border-radius: 50%;
  height: 50;
  width: 50;
  cursor: pointer;
  margin-left: 10;
  margin-right: 20;
  border: 1px solid #e8e7ee;
}

.headerAction:hover {
  background-color: #f3f2f9 !important;
}

.insightActionContainerEmbedded {
  height: 60px;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.insightDataBlock {
  position: relative;
  background: rgba(66, 100, 234, 0.05);
  border-radius: 4px;
  flex-grow: 1;
  min-width: 300px;
  padding: 1rem 1rem 0.5rem 1rem;
  gap: 0.5rem;
  align-items: space-between;
}

.overviewIndicatorIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.friendlyAlert {
  color: #505050 !important;
  border: 1px solid #d0eddb !important;
  background-color: #d0eddb;
}

.summaryAlert {
  color: #505050 !important;
  border: 1px solid #fad7d7 !important;
  background-color: #fad7d7;
}

.selectedAlert.summaryAlert {
  color: #505050 !important;
  border: 2px solid rgb(235, 114, 130) !important;
}

.selectedAlert {
  border: 2px solid rgba(66, 100, 234, 0.15);
}

.insightDataText {
  color: #535360 !important;
  font-size: 14px;
  white-space: nowrap;
  line-height: 1.1em;
  p {
    margin-bottom: 0;
  }
}

.insightDataPoint {
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  color: #0d206c !important;
}

.insightHeader {
  width: 220px;
  height: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #0d206c;
  padding-top: 40px;
  padding-bottom: 40px;
}

.insightTextHeader {
  color: #0d206c;
  font-size: 18px;
  font-weight: 600;
  padding-top: 0px;
}

.insightBody {
  white-space: pre-line !important;
  font-size: 16px;
  color: #505050;
  font-weight: 400 !important;
}

.insightTrendSettings {
  color: #4263ea;
  font-size: 16;
  width: 200px;
}

.insightSettingsHeader {
  color: #0d206c;
  font-size: 18px;
  font-weight: 600;
  padding-top: 0px;
}

.insightSettingsBody {
  white-space: pre-line !important;
  font-size: 14px;
  color: #5b5c61;
  font-weight: lighter;
  margin-top: 15px;
  font-weight: 400 !important;
  margin-bottom: 0px;
}

.notificationManagerCard {
  background-color: #fff !important;
  height: 100%;
  font-size: 18px;
  color: #6c6c6c !important;
  position: relative;
}

.insightComponentContainer {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 0px 10px;
  font-size: 18px;
  font-weight: 600;
  justify-content: space-between;
  border-bottom: 1px solid #e8e7ee;
  position: relative;
  margin-bottom: 12px;
  flex-grow:1;
  gap: 2rem;

  &__child {
    max-width: 50%;
    flex: 1;
    .insightContainer {
      max-width: 100%;
    }
  }
}

.insightTitle {
  font-size: 26px;
  font-weight: 700;
  color: #0d206c;
  margin-bottom: 10px;
}

.dataHealthStatus {
  margin-bottom: 10px;
}

.insightStatus {
  font-size: 14px;
  color: #5b5c61;
  margin-bottom: 10px;
}

.insightSummaryContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex: 1;

  &:not(:empty) {
    margin-top: 1rem;
  }
}

.graphHelpText {
  font-size: 12px;
  color: #5b5c61;
  font-weight: lighter;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
  border-top: 1px solid rgb(202, 202, 202);
  padding-top: 5px;
}


