
.halfWidthSelector>.bp3-popover-target{
  max-width: 270px !important;
  min-width: 208px;
  width: 13vw !important;
}
.bp-input {
  background-color: #fff !important;
  padding-left: 35px !important;
  height: 45px !important;
  border-radius: 4px !important;
}
.bp3-transition-container{
  z-index: 999999 !important;
}
.hasAlertFilterActive{
  background-color:#d7deff !important;
  font-width: 900 !important;
}
.bp3-popover-target{
  // max-width: 750px;
  width: 100%;
}
.bp3-transition-container{
  margin-left: 4px;
  transition-duration: 1ms !important;
  opacity: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1011 !important;
  position: absolute;
  will-change: transform;
  top: 8px !important;
  left: 0px;
  transform: translate3d(5px, 210px, 0px);
}
.bp-select{
  width:400px !important;
}
.bp3-icon{
  color:#505050 !important;

}
.bp3-overlay{
  z-index:1005!important;;
}
.bp3-popover{
  width: 17vw !important;
}
.bp3-menu-item{
  height: 50px;
  padding-top: 15px;
  color:#333333;

}
.bp3-menu-item:hover{
  background-color: #ECF0FD;
  color:#333333;
  font-weight: 900 !important;


}
.bp3-intent-primary{
  color:#ffffff !important;
}
.bp3-button{
  width: 250px !important;
  margin-left: 10px !important;
  border: 1px solid #f5f5f9;
  background-image: none !important;
  outline: none !important;
  height: 45px !important;
  background-color: #ebebef !important;
  font-size: 16px !important;
  color:#505050 !important;
  box-shadow: none !important;
}
.bp3-popover-content{
  border-radius: 3px;
  position: relative;
}
.lobTag{
  border:1.5px solid #E1E1E5 !important;
  padding-top: 4px;
  height: 58px !important;
  overflow-x: auto;
  max-width: 600px !important;
  min-width: 600px !important;
}
.bp3-multi-select-tag-input-input{
  padding-right: 32px !important;
}
.bp3-input-ghost{
  padding-top: 3px !important;
}
.bp3-tag-input-values {
  margin-top: 0 !important;
}

.bp3-tag-input-values > * {
  margin-bottom: 0 !important;
}
.bp3-input-group{
  //border-bottom: none !important;
  //box-shadow: none !important;
}
.bp3-input{
  font-weight: 600 !important;
  border-bottom: none;
  box-shadow: none !important;
  color:#aaaaaa !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}
.popover-scroll{
  max-height: 600px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  //max-width: 400px !important;
}

.bp3-popover-wrapper .bp-select{

}
.bp3-popover-target{
  color:#aaaaaa !important;
}
.bp3-minimal{
  border: none !important;
  width: 20px !important;
  margin-bottom:0px !important;
}


.bp3-button{
  margin-bottom: 5px;

}
.multisel>.bp3-popover-wrapper>.bp3-popover-target{
  width:500px !important;
}
.accountSettingsDropdown>.bp3-popover-target{
  display: inherit !important;
}
.businessSettingsDrop{
  max-width: 220px !important;
  position: absolute;
  top: 55px;
  right: 0px !important;
  transform-origin: top right !important;
  font-weight: 600 !important;

}
.businessSettingsDropFirstItem{
  top: 60px !important;
}
.accountSettingsDrop{
  max-width: 200px !important;
  position: absolute;
  top: -130px !important;
  right: -235px !important;
  transform-origin: bottom left !important;
}
.sortingDrop{
  min-width: 260px !important;
  max-width: 350px !important;
  position: absolute;
  top: 1rem !important;
  right: -15px !important;
  transform-origin: top right !important;
}
.clientDrop{
  min-width: 260px !important;
  max-width: 350px !important;
  position: absolute;
  top: 1rem !important;
  left: -30px !important;
  transform-origin: top left !important;
}
.periodDrop{
  width: auto !important;
  position: absolute;
  top: 10px !important;
  left: -80px !important;
}
.sortingDrop>.bp3-popover{
  width: auto !important;
}
.bp3-tag {
  min-height: 30px !important;
  font-size: 18px !important;
  line-height: 20px !important;
  background-color:#4264EA !important;
  padding-right: 10px !important;
  border-radius: 12px !important;
  padding-left: 10px !important;
}
.bp3-icon .bp3-icon-small-cross{
  color: #fff !important;
}
.bp3-icon{
  color: inherit !important;
}
.multiselwrapperBank{
  height: 300px !important;
  overflow-y: auto;
  z-index: 1003 !important;
}
.multiselwrapperBank>.bp3-tag-input{
  width: 250px !important;
}
.multiselwrapperBank>.bp3-popover-target{
  width: 250px !important;
}
.multiselwrapperBank>.bp3-popover-wrapper>.bp3-popover-target{
  width: 320px !important; padding-right: 10px;
}
.multiselwrapperPeriod{
  height: 40px !important;
  overflow-y: auto;
  z-index: 1003 !important;
}
.multiselwrapperPeriod>.bp3-tag-input{
  padding-top: 3px !important;
  width: 150px !important;
}
.multiselwrapperPeriod>.bp3-popover-target{
  width: 150px !important;
}
.multiselwrapperPeriod>.bp3-popover-wrapper>.bp3-popover-target{
  width: 150px !important; padding-right: 8px;
}
.bp3-menu-item::before, .bp3-menu-item > .bp3-icon{
  margin-top: 0px !important;

}
.bp3-menu-item::before, .bp3-menu-item > .bp3-icon-mobile-phone{
  margin-top: 3px !important;

}
.bp3-menu-item::before, .bp3-menu-item > .bp3-icon-add{
  margin-top: 3px !important;

}
.bp3-menu-item::before, .bp3-menu-item > .bp3-icon-new-person{
  margin-top: 3px !important;

}
.bp3-text-overflow-ellipsis{
  padding-top: 2px !important;

}

.bankTag{
  outline: 1px solid #d8d8d8;
  border: 1px solid #d8d8d8;
}
.bankTag>.bp3-tag-input-values>.bp3-tag{
  width: 80% !important;
  height: 30px !important;
}
.lobTag>.bp3-tag-input-values{
  flex-wrap: nowrap !important;
}
.bp3-tag-input{
  width:100% !important;
}
.tagClearButton{
  width: 30px !important;
  height: 40px !important;
  margin-bottom: 2px !important;
}
.tagClearButtonAbs{
  background-color: #fff !important;
  right: 4px;
  bottom: 8px;
  position:absolute;
  width: 30px !important;
  height: 40px !important;
  margin-bottom: 2px !important;
}
.tagPopoverLOB{
  overflow-x: hidden;
  max-height: 400px !important;
  overflow-y: auto;
  max-width: 500px !important;
  z-index: 1003!important;
}
.tagPopover{
  overflow-x: hidden;
  max-height: 400px !important;
  overflow-y: auto;
  max-width: 500px !important;
  z-index: 1003!important;
  margin-top: -20px !important;
}
.tagPopoverBank{
  width: 300px !important;
  z-index: 1005 !important;
}
.tagPopoverPeriod{
  z-index: 1005 !important;
}
.bp3-popover-open{
  width: 100%;
}
.multiselwrapper{
  padding-top: 10px !important;
  z-index: 1003 !important;
}
.relativeDropdown{
  position: relative;
  top:1.5vh;
  z-index: 999;
  padding-left:10px !important;
}
.industryDropdown{
  position: relative;
  top:-0.5vh;
  z-index: 999;
  padding-left:10px !important;
  max-width: 300px !important;
}
.halfWidthButton{
  width: 150px !important;
}
.halfWidth{

}
.halfWidthSelector{
  width: 50% !important;
}
.collapseHover{
  background-color: #fff !important;
}
.collapseHover:hover{
  background-color: #fbfaff !important;
}
