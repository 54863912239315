/** animation to rotate element about the center */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes smallBounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-5px);}
  60% {transform: translateY(-2px);}
}

.animated-ellipsis {
  .first, .second, .third {
    animation: smallBounce 1s steps(5, end) infinite;
  }
  .second {
    animation-delay: 25ms;
  }
  .third {
    animation-delay: 50ms;
  }

}
