.tag {
  display: inline-flex;
  align-items: center;
  padding: 3px 10px;
  border: 1px solid $blue;
  background-color: $blue;
  border-radius: 15px;
  color: #fff;
  &__delete {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-left: 0.75rem;
    svg {
      width: 0.75rem;
    }
  }
}
