.aider-modal {
  .comments-modal {
    &__body {
      padding: 1.5rem;
      max-height: 200px;
    }
    &__count {
      padding: 0 0 1rem 0;
      margin: 0;
      @include font-format(h4, bold);
    }
    &__comment {
      padding-bottom: 1rem;
      &__header {
        display: flex;
        gap: 0.5rem;
      }
      &__user {
        @include font-format(body, semibold);
        color: var(--clr-text-title);
      }
      &__time {
        color: var(--clr-text-disabled);
      }
      &__text {
        white-space: pre-wrap;
      }
    }
    &__footer {
      background: var(--clr-bg-blue);
      flex-shrink: 1;
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      gap: 1rem;
      border-top: solid 1px var(--clr-border-blue);
      min-height: 80px;

      &--input {
        min-height: 100%;
        max-height: 100px;
      }

      .ant-form-item {
        height: 100%;
        flex-grow: 1;
        margin: 0;
      }
    }
  }
}
.comment {
  margin-top: 20px;

  &__user {
    color: var(--clr-primary-10);
  }

  &__time {
    color: var(--clr-gray-8);
  }
}

.ant-modal:has(.comments-modal) {
  .ant-modal-title {
    padding: 0.8rem 1.5rem;
  }
  .ant-modal-body {
    padding: 0;
  }
}
