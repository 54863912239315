.activity_summary {
  &-header {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin-bottom: 1rem;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--clr-text-title);
    margin-right: 1rem;
    margin-top: 0.5rem;
    font-family: $font-heading;
  }

  &-table {
    .ant-table-thead .ant-table-cell {
      border-top: 1.5px solid var(--clr-disabled);
      border-bottom: 1.5px solid var(--clr-disabled);
      background: var(--clr-bg-white);
      color: var(--clr-text-title);
      font-weight: 700;
      font-family: $font-heading;

      p {
        margin: 0;
      }
    }

    .ant-table-tbody {
      border-bottom: 1.5px solid var(--clr-bg-bg4);
    }
  }
}
