.aider-alert {
  $c: '.aider-alert'; // This is used as a grandparent selector
  width: 100%;
  padding: 1rem;
  display: flex;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  color: var(--clr-text-main);

  &--borderless {
    border: none;
  }

  &--error {
    background-color: var(--clr-bg-danger);
    border-color: var(--clr-border-danger);

    #{$c}__content--title {
      color: var(--clr-text-main);
    }

     #{$c}__content--body {
      color: var(--clr-text-main);
    }

    &#{$c}--ghost {
      #{$c}__content--title {
        color: var(--clr-text-danger);
      }

      #{$c}__content--body {
        color: var(--clr-text-danger);
      }
    }


    #{$c}__icon {
      color: var(--clr-text-danger);
    }
  }

  &--warning {
    background-color: var(--clr-bg-warning);
    border-color: var(--clr-border-warning);
    color: var(--clr-text-main);



    #{$c}__content--title {
      color: var(--clr-text-main);
    }

    #{$c}__content--body {
      color: var(--clr-text-main);
    }

    &#{$c}--ghost {
      #{$c}__content--title {
        color: var(--clr-text-warning);
      }

      #{$c}__content--body {
        color: var(--clr-text-warning);
      }
    }

    #{$c}__icon {
      color: var(--clr-btn-fill-warning);
    }
  }

  &--success {
    background-color: var(--clr-bg-success);
    border-color: var(--clr-border-success);

    #{$c}__content--title {
      color: var(--clr-text-main);
    }

    #{$c}__content--body {
      color: var(--clr-text-main);
    }

    &#{$c}--ghost {
      #{$c}__content--title {
        color: var(--clr-text-success);
      }

      #{$c}__content--body {
        color: var(--clr-text-success);
      }
    }

    #{$c}__icon {
      color: var(--clr-text-success);
    }

  }

  &--info,
  &--genai {
    background-color: var(--clr-bg-info);
    border-color: var(--clr-border-info);

    #{$c}__content--title {
      color: var(--clr-text-main);
    }

    #{$c}__icon {
      color: var(--clr-text-link);
    }

    &#{$c}--theme {
      &-secondary {
        &:not(#{$c}--ghost) {
          background-color: var(--clr-bg-teal);
        }

        border-color: var(--clr-border-dark-teal);

        #{$c}__content--title,
        #{$c}__content--body {
          color: var(--clr-aider-dark-teal);
        }

        #{$c}__icon {
          color: var(--clr-btn-line-teal);
        }
      }

      &-muted {
        &:not(#{$c}--ghost) {
          background-color: var(--clr-bg-disabled);
        }

        border-color: var(--clr-border-divider);

        #{$c}__content--title {
          color: var(--clr-text-disabled);
        }

        #{$c}__content--body {
          color: var(--clr-text-disabled);
        }


        #{$c}__icon {
          color: var(--clr-btn-line-disabled);
        }
      }
    }
  }

  &--ghost {
    background-color: transparent;
  }

  &__icon {
    font-size: 1.3rem;
    padding: 0 0.75rem 0 0;
  }

  &#{$c}--large-icon {
    #{$c}__icon {
      font-size: 2rem;
    }
  }

  &__content {
    @include font-format(body, regular);
    white-space: pre-wrap;
    width: 100%;
    min-width: $min-text-width;
    width: 100%;

    &--info {
      &__title-only {
        min-height: 100%;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &--body {
      align-items: flex-start;
      max-width: $max-text-width;

      .top-line {
        margin-bottom: 1.5rem;
      }

      p {
        @include font-format(body, small);
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &__alert-with-button {
        display: flex !important;
        justify-content: left !important;
        padding-left: 0 !important;
        margin-left: 0 !important;
      }
    }

    &--title {
      align-items: center;
      @include font-format(body, bold);
      margin-bottom: 0;
      padding-bottom: 0;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.insight-banner {
  height: auto;
  margin-block: 1rem;

  &__alert {
    .aider-alert__content {
      max-width: 100%;

      &--body {
        @include font-format(body, regular);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        p {
          flex-grow: 1;
        }
      }
    }
  }
}

.prompt-banner {
  height: auto;
  margin-block: 1rem;

  &__alert {
    .aider-alert__content {
      max-width: 100%;

      &--body {
        @include font-format(body, regular);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        p {
          flex-grow: 1;
        }
      }
    }
  }
}

.prompt-block {
  margin-bottom: 10px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
              0px 6px 16px 0px rgba(0, 0, 0, 0.08),
              0px 3px 6px -4px rgba(0, 0, 0, 0.12);

  &__prompt {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
      position: relative;
      padding-right: 2px;

      &--content::before {
        content: "\"";
      }

      &::after {
        content: "\"";
        position: relative;
      }
  }

  .aider-alert__content {
    position: relative;

    &--body {
      @include font-format(body, regular);
      margin-top: 10px;
    }
  }
}

.resync-error {
  @include font-format(size, small);

  p {
    padding-top: 1rem;
    margin: 0;
  }
}
