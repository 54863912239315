/** Main layout styles
  * This defines a layout with a main navbar and a main content area
  */
#root {
  height: 100dvh;
  width: 100dvw;
}

.main {
  --nav-width: 4rem;
  background-color: var(--clr-bg-white);

  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  &__nav {
    height: 100%;
    width: var(--nav-width);
    background-color: var(--clr-aider-blue);
  }

  &__content {
    width: calc(100% - var(--nav-width));
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100dvh;
    max-height: 100dvh;
    min-width: 20rem;
  }

  .wrapper {
    margin: 0 auto;
    width: 100%;
    min-width: map-get($breakpoints, xs);
    max-width: 1300px;
    
    &-xs {
      max-width: map-get($breakpoints, xs);
    }

    &-sm {
      max-width: map-get($breakpoints, sm);
    }

    &-lg {
      max-width: map-get($breakpoints, lg);
    }

    &-xl {
      max-width: map-get($breakpoints, xl);
    }
  }

}

.trial-banner {
  box-sizing: border-box;
  background: var(--clr-bg-warning);
  color: var(--clr-text-title);
  display: flex;
  padding: 1rem;

  &__contents {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

    &__left {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    h4 {
      color: var(--clr-text-title);
      padding: 0;
      margin: 0;
    }

    a {
      @include font-format(small, bold);
    }

    &__button {
      font-family: $font-family;
      @include font-format(body, bold);
    }
  }
}
