// TODO: Remove #root when refactor done
.main, #root {
  .navBar {
    display: flex;
    height: 100%;
  }

  .navBar-main {
    flex-direction: column;
    width: 4rem;
    align-items: center;
    background-color: var(--clr-aider-blue);
    color: var(--clr-text-white);

    &__top {
      flex-grow: 1;
    }
    &__logo,
    &__bottom {
      flex: 0 1 auto;
    }

    &__top,
    &__bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__earlyAccessFlag {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 3.5rem;
    }

    &__logo {
      margin-top: 2rem;
      color: var(--clr-btn-line-white);
      font-size: 2rem;
    }

    &__icon {
      margin-top: 1rem;
      position: relative;
      font-size: 2rem;
      color: var(--clr-primary-4);
      padding: 0.625rem;

      &.active {
        color: var(--clr-btn-line-white);
      }

      &:not(.active):hover {
        --hover-color: var(--clr-primary-5);
        color: var(--clr-btn-line-white);
        background-color: var(--hover-color);
        border-radius: 50%;
      }

      &--wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .text-pill {
          font-size: 0.8rem;
          bottom: 0;
          position: absolute;
          transform: translateY(100%);
        }
      }
    }

    &__dropdown {
      margin-block: 1rem;
      position: relative;
      .ant-btn {
        padding: 0;
      }
      &-list {
        display: block;
        position: absolute;
        background-color: var(--clr-bg-white);
        bottom: 0;
        left: calc(100% + 0.75rem);
        z-index: 9999;
        width: 16rem;
        overflow-y: auto;
        border: 1px solid var(--clr-border-divider);
        padding: 0.25rem;

        border-radius: 4px;
        list-style-type: none;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        transition: opacity 0.2s ease-in-out;

        &--hide {
          display: none;
        }
      }

      &-item {
        display: flex;
        font-weight: map-get($fontWeights,regular);
        cursor: pointer;
        color: var(--clr-text-main);
        text-decoration: none;
        white-space: nowrap;
        padding: 0.75rem 1rem;
        align-items: center;
        gap: 0.5rem;
        span {
          font-size: 1.5rem;
        }
        &:hover {
          color: var(--clr-text-hover);
          background-color: var(--clr-bg-blue);
          border-radius: 2px;
        }
      }

      &-img {
        display: inline-block;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .navBar-sub {
    gap: 0.5rem;
    padding: 0.8rem;

    @include respond-to(md) {
      width: min(20vw, 285px);
      flex-direction: column;
    }
    &__item {
      @include font-format(small, regular);
      padding: .5rem .8rem;
      color: var(--clr-text-main);
      text-decoration: none;
      border-radius: $border-radius;
      white-space: nowrap;
      @include respond-to(md) {
        white-space: pre-wrap;
      }
      span {
        @include font-format(small, regular);
        color: var(--clr-text-main);
      }

      &.external {
        display: flex;
        justify-content: space-between;
        gap: 0.5em;
      }

      &:hover {
        color: var(--clr-aider-blue);

        span {
          color: var(--clr-aider-blue);
        }
      }

      &.active {
        @include font-format(small, bold);
        color: var(--clr-text-title);
        background-color: var(--clr-bg-blue-selected);
      }

      .text-pill {
        margin-left: 0.5em;
      }
    }
  }
}
// TODO: Remove this when refactor done
#root {
  .navBar-main__icon {
    line-height: 1.5rem;
  }
}
