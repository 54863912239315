.ant-tooltip:has(.dashboard-resync-status) {
  --fallback-bg: var(--clr-gray-13);
  --tooltip-bg: color-mix(in srgb, var(--fallback-bg) 90%, transparent);
  .ant-tooltip-arrow:before {
    background: var(--fallback-bg);
    background: var(--tooltip-bg);
  }
  .ant-tooltip-inner {
    background-color: var(--fallback-bg);
    background-color: var(--tooltip-bg);
    color: var(--clr-text-white);
    padding: 0.5rem 1rem;
    @include font-format(small, light);
  }

  .status-icon {
    display: inline-block;
    width: 1rem;
    margin-right: 1rem;
  }

  .dashboard-resync-status {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .status-icon {
      display: inline-block;
      width: 1rem;
      margin-right: 1rem;
    }
  }
}

.main,
.aider-modal {

  .screen-height {
    height: 100dvh;
  }

  .user-icon {
    border-radius: 50%;
    aspect-ratio: 1;
    width: 2.5rem;
    background-color: var(--clr-bg-white);

    &.text-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      @include font-format(h4, bold);
      background-color: var(--clr-bg-white);
      color: var(--clr-text-title);
      padding: 0.5rem;
    }
  }

  .aider-tag {
    display: inline-flex;
    gap: 0.3rem;
    align-items: center;
    padding-left: 0.5rem;
    border-radius: 1rem;
    border: solid 1px var(--clr-text-blue);
    color: var(--clr-text-white);
    background-color: var(--clr-text-blue);

    &__close {
      &:not(:disabled):not(.ant-btn-disabled).ant-btn {
        display: inline-flex;
        align-items: center;
        &.ant-btn {
          .anticon {
            font-size: 0.8rem;
          }
        }
        color: var(--clr-btn-line-white);

        &:hover {
          color: var(--clr-primary-4);
        }
      }
    }

    &.ghost {
      color: var(--clr-text-blue);
      background-color: var(--clr-bg-white);

      .ant-btn.ant-btn-link.aider-tag__close:not(:disabled):not(.ant-btn-disabled) {
        color: var(--clr-text-blue);
        &:hover {
          color: var(--clr-btn-line-title);
        }
      }
    }
  }
}
.practice-initials-logo {
  font-family: $font-family;
  background: var(--clr-bg-white);
  color: var(--clr-aider-blue);
  @include font-format(h4, bold);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  &__icon {
    font-size: 5rem;
  }

  &:hover {
    color: var(--clr-text-title);
  }
}

.ant-btn:has(.practice-initials-logo) {
  display: flex;
  align-items: center;
  justify-content: center;

  .practice-initials-logo__icon.anticon {
    font-size: 2rem;
  }
}

.xero-aider-logo {
  display: flex;
  &__xero {
    font-size: 3.7rem;
  }
  &__ellipsis {
    font-size: 1.5rem;
  }
  &__aider {
    font-size: 4rem;
  }
}

.impersonate-user {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 1rem;

  &__inputs {
    outline: var(--clr-bg-white) solid 5px;
    display: flex;
    position: absolute;
    column-gap: 1rem;
    width: 400px;
    right: 3rem;
  }

  &.legacy {
    position: relative;
    top: -0.2rem;

    &.insight-page {
      margin-right: 1.5rem;
    }

    .impersonate-user__inputs {
      background: var(--clr-bg-white);
      right: 4rem;
    }
    .button-primary:not(:disabled):not(.ant-btn-disabled),
    .button-secondary:not(:disabled):not(.ant-btn-disabled) {
      padding: 1.4rem;
      border: solid 2px rgb(232, 231, 238);
    }
  }

  &.full-width {
    width: 100%;
    justify-content: flex-end;
    padding: 1rem;

    .impersonate-user__inputs {
      right: 5rem;
    }
  }
}

.text-pill {
  --border-color: var(--clr-border-divider);
  --bg-color: var(--clr-bg-white);
  --text-color: var(--clr-text-disabled);

  border: solid 1px var(--border-color);
  background-color: var(--bg-color);
  color: var(--text-color);

  padding: 0.2em 0.6em;
  border-radius: calc(2 * 0.2em + 1em);
  display: inline-flex;
  align-items: center;
  justify-content: center;


  &__primary {
    --border-color: var(--clr-text-blue);
    --bg-color: var(--clr-text-blue);
    --text-color: var(--clr-text-white);
  }

  &__secondary {
    --border-color: var(--clr-secondary-6);
    --bg-color: var(--clr-secondary-6);
    --text-color: var(--clr-text-white);
  }

  &__success {
    --border-color: var(--clr-border-success);
    --bg-color: var(--clr-bg-success);
    --text-color: var(--clr-text-success);
  }
  &__warning {
    --border-color: var(--clr-border-warning);
    --bg-color: var(--clr-bg-warning);
    --text-color: var(--clr-text-warning);
  }
  &__danger {
    --border-color: var(--clr-border-danger);
    --bg-color: var(--clr-bg-danger);
    --text-color: var(--clr-text-danger);
  }
  &__info {
    --border-color: var(--clr-border-info);
    --bg-color: var(--clr-bg-info);
    --text-color: var(--clr-text-info);
  }

  &__small {
    font-size: 0.5em;
    line-height: 0.8em;
  }
  &__medium {
    font-size: 0.8em;
    line-height: 0.8em;
  }
  &__large {
    font-size: 1em;
    line-height: 0.8em;
  }
}
  
.corner-pill {
  position: relative;
  padding-right: 0.5rem;
  .text-pill {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(15%) translateY(-50%);
  }
}

.shimmer-loader {
  .ant-row {
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .trio {
    height: 8rem;
  }
  .duo {
    height: 20rem;
    .ant-col {
      height: 9.5rem;
    }
  }
  .ant-skeleton {
    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
    width: 100%;
    height: 100%;
  }
}

.separator {
  height: 1rem;
  border-bottom: 1px solid var(--clr-border-divider);
  margin: 1rem 0 2rem;
  text-align: center;

  &-text {
    @include font-format(h3, semi-bold);
    background-color: var(--clr-bg-white);
    padding: 0 2rem;
  }
}

