.main {
  .checklist {
    &__container {
      padding: 0 0 0 0;
    }

    &__section {
      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: var(--clr-bg-blue);
        padding: 1rem;
        border-radius: 0.25rem;

        &__title {
          color: var(--clr-text-title);
          font-size: 2rem;
          font-weight: 700;
          margin: 0;
        }
      }

      &__data {
        padding: 0.1rem;


      }

    }

    &__rule {
      &__title {
        margin-left: 1rem;
      }

      &__description {
        padding: 0.1rem;
      }
    }
  }

  .practice-checklist {
    padding: 1rem;

    &+.practice-checklist {
      border-top: 1px solid var(--clr-border-divider);
    }

    &__header {
      padding: 0 0 0.5rem 0.5rem;
    }

    &__summary {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      display: flex;
      column-gap: 1rem;
      align-items: center;
      color: var(--clr-text-blue);
      margin: 0;

      h5 {
        @include font-format(h4, semibold);
        margin: 0;
        padding: 0;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
    }

    &__introduction {
      p {
        margin-bottom: 0;
      }
    }

    &__detail {
      padding-top: 1rem;
      margin-left: 2rem;
    }

    &__footer {
      position: sticky;
      bottom: 0;
    }
  }
}
.compliance-section-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background-color: var(--clr-bg-blue);
  padding: 0.75rem;
  border-radius: 0.25rem;
  border: solid 1px var(--clr-gray-4);

  &__block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.25rem;
  }

  &__title {
    color: var(--clr-text-title);
    @include font-format(h2, semibold);
    margin: 0;
  }


  &__icon {
    margin: 0;
    margin-top: 0.1rem;
    padding: 0;
    @include font-format(h3, semibold);

    &--success {
      color: var(--clr-btn-fill-success);
    }

    &--warning {
      color: var(--clr-btn-fill-warning);
    }

    &--danger {
      color: var(--clr-btn-fill-danger);
    }

    &--muted {
      color: var(--clr-btn-fill-disabled);
    }
  }

}

.section-resync {
  color: var(--clr-text-title);
  @include font-format(small, regular);
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
  align-items: center;

  &__status {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }
}

.section-reconnect {
  color: var(--clr-text-title);
  @include font-format(small, regular);
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  .osp__logo {
    font-size: 1.5em;
    padding-left: 15px;
    padding-right: 3px;
  }
  .status-text {
    color: var(--clr-text-danger);
    @include font-format(small, regular);
    padding-right: 5px
  }
  .reconnect-xero {
    width: 150px;
    --_bg-color: var(--clr-xero);
    &.button-secondary {
      --_border-color: none;
      --_text-color: rgb(from var(--clr-text-white) r g b / 0.8);
      height: 1.5rem;
      font-size: 2.1rem;
      padding: 0;
      min-width: 0;
      width: min-content;
    }
  }
}

.checklist-postfix {
  @include font-format(small, bold);
  &__danger {
    color: var(--clr-text-danger);
  }
  &__warning {
    color: var(--clr-text-warning);
  }
  &__info {
    color: var(--clr-text-info);
  }
}

.ant-tooltip:has(.period-close-cell-tooltip) {
  .ant-tooltip-inner {
    .period-close-cell-tooltip {
      --_link-color: var(--clr-text-white);
      --_hover-color: rgb(from var(--clr-text-white) r g b / 0.8);
      h4 {
        @include font-format(body, bold);
      }
      p {
        margin: 0;
        padding: 0;

        & + p {
          margin-top: 0;
        }
      }
    }
  }
}

.period-close-checklist {
  &.ant-collapse {
    & > .ant-collapse-item {
      & > .ant-collapse-header {
        @include font-format(h5, semibold);
        color: var(--clr-text-blue);
        align-items: center;
        .ant-collapse-arrow {
          @include font-format(h4, semibold);
        }
      }
    }
  }

  .ant-collapse {
    &-expand-icon {
      @include font-format(h3, semibold);
    }
  }
  &__cell:has(.checklist-table-tooltip-icon) {
    display: flex;
    flex-grow: 0;
    align-items: center;
    position: relative;
  }
}

.ant-table-wrapper .ant-table-thead >tr>th,
.ant-table-wrapper .ant-table-tbody >tr>td {
  &.cell-align {
    &__right {
      text-align: right;
      justify-content: flex-end;
      .period-close-checklist__cell {
        text-align: right;

        &:has(.checklist-input__toggle-button) {
          display: inline-flex;
          justify-content: right;
        }
      }
    }

    &__center {
      text-align: center;
      justify-content: center;
      .period-close-checklist__cell {
        text-align: center;

        &:has(.checklist-input__toggle-button) {
          display: inline-flex;
          justify-content: center;
        }
      }
    }

    &__left {
      text-align: left;
      justify-content: flex-start;
      .period-close-checklist__cell {
        text-align: left;

        &:has(.checklist-input__toggle-button) {
          display: inline-flex;
          justify-content: left;
        }
      }
    }
  }
}

.checklist-table-tooltip-icon {
  color: var(--clr-text-blue);
  font-size: 1em;
  margin-left: 0.5rem;
  cursor: pointer;

  // position: absolute;
  // right: 0;
  // transform: translateX(1.2rem);
  // z-index: 1;
}

.button-secondary.call-to-action {
  @include font-format(h4, semibold);
  font-size: 1.125rem;
}

.period-close-table {
  --antd-table-line-height: 21px;
  a {
    @include font-format(small, regular);
  }

  .ant-table-thead {
    &:has(.period-close-table__row-header__empty){
      display: none;
    }
    .ant-table-cell {
      border-color: var(--clr-border-divider);
      background-color: var(--clr-bg-white);
      color: var(--clr-text-title);
      @include font-format(h6, semibold);
      line-height: var(--antd-table-line-height); 
    }
  }

  &__row {
    &--header,
    &--subheader,
    &--summary-header {
      color: var(--clr-text-title);
      @include font-format(h6, semibold);
      line-height: var(--antd-table-line-height);
    }

  }
  .ant-table-row:has(.period-close-table__row--header),
  .ant-table-cell-fix-left:has(.period-close-table__row--header) {
    background-color: var(--clr-bg-blue);
  }

  .ant-table-cell {
    border-color: var(--clr-border-divider);
    white-space: break-spaces;
    @include font-format(small, regular);
    line-height: var(--antd-table-line-height);

    &.cell-numeric {
      text-align: right;
      flex-grow: 0;
    }
  }

  .ant-table {
    .ant-table-thead,
    .ant-table-tbody {
      & > tr > .ant-table-cell {
        &:not(:last-child)
          :not(.ant-table-selection-column)
          :not(.ant-table-row-expand-icon-cell) {
          &::before {
            background-color: transparent;
          }
        }
      }
      .ant-table-cell {
        position: relative;
        vertical-align: top;
        border-color: var(--clr-border-divider);

        &:has(+ .ant-table-cell > .period-close-table__row--subheader:empty) {
          white-space: nowrap;
        }
      }
    }


    .ant-table-tbody {
      tr:has(.ant-table-cell-row-hover):hover {
        .ant-table-cell-row-hover {
            background-color: var(--clr-bg-blue);
        }
      }
    }
  }

  &:not(.pre-head-table) {
    .ant-table-tbody {
      tr:last-child {
        .ant-table-cell {
          border-bottom: 0;
        }
      }
    }
  }

  &.pre-head-table {
    margin-bottom: 1rem;
  }
}

.period-close-item + .period-close-item {
  border-top: 1px solid var(--clr-border-divider);
}

.checklist-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ant-input-number-group-addon:has(.checklist-input__controls) {
  background: var(--clr-bg-white);
  padding-inline: 0rem;
}

.checklist-input {
  &__toggle-button {
    span {
      @include font-format(small, regular);
      color: rgba(0, 0, 0, 0.88);
    }
    &:hover {
      span {
        color: var(--clr-btn-line-hover);
      }
    }
  }

}

.ant-input-number-group {
  &:has(.checklist-input__controls) {
    .ant-input-number:has( + .ant-input-number-group-addon) {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    .ant-input-number-group-addon {
      border: 0;
      padding-left: 0.25rem;

      .checklist-input__controls {
        display: flex;
        flex-direction: row;
        flex: 0;
        gap: 0.25rem;
      }
    }
  }
}

.period-close-info-icon {
  &__info {
    color: var(--clr-btn-fill-link);
  }

  &__warning {
    color: var(--clr-btn-fill-warning);
  }

  &__danger {
    color: var(--clr-btn-fill-danger);
  }
}
.ant-table-expanded-row-fixed:has(.period-close-checklist__empty) {
  padding: 0.5rem;
  @include font-format(small, regular);
  color: var(--clr-text-main);
}

.checklistBullets{
  @include font-format(small, regular);
  color: var(--clr-text-title);
  padding-bottom: 0.5rem;

  &:has(.checklistSettings) {
    border-bottom: 1px solid var(--clr-border-divider);
  }

  ul {
    margin-left: 0;
    padding-left: 0.5rem;
    list-style-position: inside;
  }
}

.checklistSettings {
  @include font-format(small, regular);
  color: var(--clr-text-main);
  display: flex;
  gap: 5.5rem;
  align-items: center;
  padding-inline: 0.5rem;
  padding-bottom: 1.0rem;
}

.reconciliationDate {
  @include font-format(small, regular);
  color: var(--clr-text-main);
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
  padding-inline: 0.5rem;
  align-items: center;
}
