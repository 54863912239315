// to replace react-materialize' table
table {
  border-spacing: 0;
  th {
    padding: 15px;
  }
  td {
    padding: 15px 5px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
  }
}
