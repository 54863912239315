/** Variable for use with Various Mixins */

// Breakpoints for media queries
$breakpoints: (
  'xs': 576px,
  'sm': 767px,
  'md': 992px,
  'lg':  1200px,
  'xl': 1600px,
  '2xl': 1700px
) !default;

// Font Sizes per style guide
$fontSizes: (
  'h1': 2.6rem,
  'h2': 1.9rem,
  'h3': 1.5rem,
  'h4': 1.3rem,
  'h5': 1rem,
  'h6': 0.9rem,
  'body': 1rem,
  'small': 0.9rem,
  'footnote': 0.8rem,
);

// Line Heights per style guide
$lineHeights: (
  'h1': 2.6rem,
  'h2': 1.9rem,
  'h3': 1.5rem,
  'h4': 1.3rem,
  'h5': 1rem,
  'h6': 0.9rem,
  'body': 1.5rem,
  'small': 1.3rem,
  'footnote': 1.3rem,
);

// Font Weights per style guide
$fontWeights: (
  'light': 400,
  'regular': 500,
  'semibold': 700,
  'bold': 800,
  'black': 1000,
);

// Transformations per style guide
$fontTransforms: (
  'none': none,
  'uppercase': uppercase,
  'lowercase': lowercase,
  'capitalize': capitalize,
);

/**
 * Common miscellanious styles & variables for use throughout app.
 */
$border-radius: 4px;

.scrollbar {
  &::-webkit-scrollbar {
    &:vertical {
      width: 0.8rem;
    }
    &:horizontal {
      height: 0.8rem;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--clr-border-divider);
    border-radius: 0.4rem;
    border: solid 1px var(--clr-border-divider);
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    border: solid 1px transparent;
    border-radius: 0.4rem;
  }
}

.flex-center {
  &:not(.flex-center__vertical):not(.flex-center__horizontal) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__vertical {
    display: flex;
    align-items: center;
  }

  &__horizontal {
    display: flex;
    justify-content: center;
  }
}

.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

