.input {
  label {
    @extend .flex--space;
    color: $neutral;
    font-weight: 700;
    font-size: 1.15rem;
    line-height: 20px;
    margin-bottom: .75rem;

    p {
      font-weight: 700;
      color: $neutral;
      margin: 0;
    }
    .optional {
      color: $lightNeutral;
      font-weight: 400;
      font-size: 1rem;
    }
  }
  input,
  textarea {
    border: 2px solid $lightGrey;
    border-radius: 4px;
    width: 100%;
    text-indent: 10px;
    color: $neutral;
    font-weight: 400;
    font-size: 1.15rem;
  }
  input {
    border-bottom: 2px solid $lightGrey !important;
    height: 4rem;
  }
  input[type='text'] {
    text-transform: capitalize;
  }
  textarea {
    height: 8rem;
  }
}
