.insights-navigation {
  padding-block: 0.5rem;
  background: var(--clr-bg-title);

  &--label {
    padding-inline: 1rem;
    display: flex;
    flex-direction: column;
    @include font-format(small, bold);
    color: var(--clr-text-white);
  }

  &--search {
    width: max(30ch, 20%);
    min-width: max-content;

  }

  &--client-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--clr-text-disabled);
    padding: 1rem;
  }
}

.insights-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 0;
  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    @include font-format(small, regular);

    .insight-resync {
      color: var(--clr-text-title);
      display: flex;
      gap: 0.5rem;
      align-items: center;

      &__status {
        display: flex;
        gap: 0.25rem;
        align-items: center;
      }

      .date,
      .error-act {
        font-weight: 700;
      }

      .error-act {
        color: var(--clr-blue);
        cursor: pointer;
      }

      .resync-button {
        --color: var(--clr-blue);
        color: var(--color);
        font-size: 0.8rem;
        height: 1.2rem;
        line-height: 1rem;
        padding: 0 0.5rem;

        &:disabled {
          --color: var(--clr-text-primary);
        }

        .loading {
          animation: rotate 1s linear infinite;
        }

        svg {
          color: var(--color);
          stroke: var(--color);
          transition: color 0.3s ease, stroke 0.3s ease;
        }


        &:hover {
          --color: var(--clr-text-title);
        }
      }
      .DataLoadingInfo-Info {
        font-size: 1rem;
        color: var(--clr-blue);
      }
    }

    .insight-reconnect {
      color: var(--clr-text-title);
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .osp__logo {
        font-size: 1.5em;
      }

      .status-text {
        color: var(--clr-text-danger);
      }

      .reconnect {
        --color: var(--clr-blue);
        color: var(--color);
        font-size: 0.8rem;
        height: 1.2rem;
        line-height: 1rem;
        padding: 0 0.5rem;
      }
    }
  }

  &--actions {
    margin-right: 1rem;
    display: flex;
    gap: 1rem;
    .impersonate-user {
      align-items: flex-start;
    }
  }
}

.insightComponentContainer {
  @include not-respond-to(md) {
    flex-direction: column;

    &__child {
      max-width: 100%;
    }
  }
}
