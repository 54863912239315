/**
  * Forms
  * @description Common styles for forms across the platform
  **/


/** Styles for forms within the settings sublayout */
.settings-form {
  h3 {
    margin-bottom: 1rem;
  }
}

.accounts-tree-selector {
  &+.accounts-tree-selector {
    margin-top: 0rem;
  }
}
