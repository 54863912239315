.main {
  &__content:has(.splash-screen) {
    .splash-screen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 3rem;
      padding: 3rem;
      @include respond-to(md){
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
      }
      &__details {
        width: 100%;
        max-width: 85ch;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include respond-to(md){
          width: clamp(50%, 30vw, 70%);
        }

        h1 {
          @include font-format(h1, bold);
        }

        h2 {
          margin-top: 2rem;
          @include font-format(h4, bold);
        }

        h1, h2, p, ul {
          margin-bottom: 0;
        }

        &__button {
          padding: 1rem;
        }
      }

      &__preview {
        width: 100%;
        max-width: 681px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @include respond-to(md){
          width: clamp(50%, 30vw, 30%);
        }

        svg {
          width: 100%;
        }
      }
    }
  }
}
