


.error-message {
  color: $clr-danger;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.rule-pending-block,
.pending-block {
  width: 100%;
  background: $clr-bg-blue;
  border: 1px solid $blue;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  box-shadow: 0px 3px 7px rgba(69, 70, 78, 0.12), 0px 7px 10px -2px rgba(69, 70, 78, 0.2);
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .ant-col-1 {
    text-align: center;
  }

  h3,
  p {
    line-height: 1.3rem;
    font-size: 1rem;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.dataHealthChecklist__row--multi-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  &__block {
    display: flex;
    align-items: center;
  }
}

.BusinessHeaderPanel {
  margin-top: 1vh;
  margin-left: 48px;
  float: left;
  width: 56vw;
  position: relative;
  display: flex;
  flex-direction: column;

  .business-header-name {
    font-size: 36px;
    font-weight: 400;
    color: var(--clr-text-title);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
  }

  .business-header-info {
    font-size: 0.8rem;
    color: var(--clr-text-secondary);
    font-weight: 400;
    display: flex;
    gap: 1.5rem;
    line-height: 1.5rem;
  }
}

.compliance-checks {

  &__icon {
    margin-right: 0.5rem;
    margin-left: 0.25rem;
  }

  .ant-checkbox {
    position: relative; // Establish a new positioning context

    &-input {
      width: 24px;
      height: 24px;
      position: absolute; // Position absolutely within the .ant-checkbox
      top: 0; // Align to the top
      left: 0; // Align to the left
    }
  }

  .ant-checkbox::after {
    border: none;
  }

  /* Style for unticked checkbox */
  .ant-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border: transparent;
    background-image: url("../entities/icons/icon_task_checkbox_todo.svg");
    background-size: 100% auto;
  }

  /* Style for ticked checkbox */
  .ant-checkbox-checked .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    /*Remove styles*/
    background: none;
    border: none;
    /* Add custom styles to display SVG image */
    background-image: url("../entities/icons/icon_task_checkbox_done.svg");
  }

  /* Hide the default tick mark */
  .ant-checkbox-checked .ant-checkbox-inner::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    opacity: 0;
  }
}
