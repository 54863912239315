@import '~@blueprintjs/core';
@import './styles-v1/animations.scss';
@import './styles-v1/color.scss';
@import './styles-v1/util.scss';

@import './styles-v1/customize.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source%20Code%20Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source%20Code%20Pro');
@import './components-v1/custom-grid.css';

@import './styles-v1/antDesignOverrides.scss';
@import './styles-v1/overview.scss';
@import './styles-v1/components.scss';
@import './styles-v1/blueprintOverrides.scss';
@import './styles-v1/modals.scss';
@import './styles-v1/gridSystem.scss';
@import './styles-v1/aiderLegacyComponents.scss';
@import './styles-v1/insights.scss';
@import './styles-v1/performanceReport.scss';

@import './styles-v1/overviewScreen.scss';
@import './styles-v1/filter.scss';
@import './styles-v1/tag.scss';
@import './styles-v1/dataCompliance.scss';
@import './styles-v1/input.scss';
@import './styles-v1/components/form.scss';
@import './styles-v1/components/input.scss';
@import './styles-v1/components/table.scss';
@import './styles-v1/components/modal.scss';
@import './styles-v1/components/tierModal.scss';
@import './styles-v1/components/alert.scss';
@import './styles-v1/report.scss';
@import './styles-v1/clientTransactionSummary.scss';

@import './styles-v1/page/tierPriceConfirmPage.scss';
@import './styles-v1/page/contactThankYouPage.scss';

$headerSize: 24px;
#root {
  @extend .none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  scrollbar-width: none;
  display: flex;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-heading;
  font-weight: 700;
}

//overrides
.react-phone-number-input__icon {
  display: block;
}

.react-phone-number-input__icon-image {
  display: block;
}

.react-phone-number-input__row {
  height: 40px;
}

//entities
.separator {
  height: 20px;
  border-bottom: 1px solid #d1d8de;
  margin: 20px 0 40px;
  text-align: center;
}

.separator-text {
  line-height: 40px;
  background-color: #ffffff;
  padding: 0 10px;
}

//loader
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #4364e9;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.brandImage {
  max-width: 100%;
  height: auto;
  width: auto;
}
.fading {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;

  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
  -ms-animation: fadein 1.5s; /* Internet Explorer */
  -o-animation: fadein 1.5s; /* Opera < 12.1 */
  animation: fadein 1.5s;
}
.fading {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.faded {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
.fadedlong {
  -webkit-animation: fadeinDelay 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinDelay 1s; /* Firefox < 16 */
  -ms-animation: fadeinDelay 1s; /* Internet Explorer */
  -o-animation: fadeinDelay 1s; /* Opera < 12.1 */
  animation: fadeinDelay 1s;
}
.fadedshort {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeinDelay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeinDelay {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeinDelay {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeinDelay {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#root {
  @extend .none;
}
//#root{
//  @extend .scrollbar;
//  @extend .none;
//  height:100%;
//  background-color:#fff;
//}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.lobTagS::-webkit-scrollbar {
  background-color: transparent;
  width: 8px !important;
  height: 8px !important;
}

/* background of the scrollbar except button or resizer */
.lobTagS::-webkit-scrollbar-track {
  background-color: transparent;
}
.lobTagS::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.lobTagS::-webkit-scrollbar-thumb {
  background-color: #c7c7cd;
  border-radius: 16px;
  border: 1px solid #eeeeee;
}
.lobTagS::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 1px solid #f4f4f4;
}

/* total width */

/* background of the scrollbar except button or resizer */

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #c7c7cd;
  border-radius: 16px;
  border: 1px solid #eeeeee;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 1px solid #f4f4f4;
}

.lite::-webkit-scrollbar-thumb {
  border: 5px solid #fff;
}
.narrow::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
}
.narrow::-webkit-scrollbar-button {
  display: none;
}
.narrow::-webkit-scrollbar-thumb {
  border: 2px solid #eeeeee;
  background-color: transparent;
}
.narrow::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}
.none::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}
.none::-webkit-scrollbar-button {
  display: none;
}
.none::-webkit-scrollbar-thumb {
  border: none;
  background-color: transparent;
}
.none::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

.bold {
  font-weight: 300;
}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

.scrollbar {
  overflow-y: overlay;
}

.always {
  overflow-y: scroll !important;
}

.speech-bubble-ds {
  width: auto;
  background: #4264ea;
  border: 1px solid #d5d7ea;
  border-radius: 12px;
  box-shadow: -2px 2px 2px 0px #d5d7ea;
  position: relative;

  p {
    margin-bottom: 10px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
}

.speech-bubble-ds__arrow {
  bottom: -22px;
  position: absolute;
  right: -5px;
  box-shadow: -11px -17px 4px 5px #d5d7ea;

  &::before {
    border-left: 23px solid transparent;
    border-top: 23px solid #4264ea;
    bottom: 10px;
    content: '';
    position: absolute;
    right: 5px;
  }
  &::after {
    border-left: 21px solid transparent;
    border-top: 21px solid #4264ea;
    bottom: 10px;
    content: '';
    position: absolute;
    right: 6px;
  }
}
.metaSpeech {
  background-color: #f7f7f7 !important;
}
.speech-bubble-left {
  margin-top: 60px;
  background: #fff;
  border-bottom: 1px solid #d5d7ea;
  border-radius: 12px;
  position: relative;

  p {
    margin-bottom: 10px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
}

.addUserIcon {
  float: right;
  display: flex;
  align-items: center;
  font-size: 14;
  margin-bottom: 10;
  justify-content: center;
  color: #8e8e8e;
  background-color: #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding-left: 3px !important;
  padding-top: 3px !important;
}
.addUserIcon:hover {
  background-color: #fff;
  color: #0f0bff !important;
  height: 44px !important;
  width: 44px !important;
  font-size: 16px !important;
}

.disabledAdvisorRow {
  background-color: #f9f9f9 !important;
  color: #b9b9b9 !important;
  cursor: default !important;
}
.creditCard {
  width: 420px !important;
  float: left;
  padding-top: 40px;
  height: 182px;
  margin-top: 54px;
  border-radius: 12px;
}
.leftMargin {
  margin-left: 20px !important;
}
//stripe element
.StripeElement {
  border-bottom: 2px solid #4264ea !important;
}

.StripeElement--invalid {
  border-bottom: 2px solid red !important;
}
.FormGroup {
  margin: 0 0px 20px;
  padding: 20px !important;
  border: 1px solid #9b9b9b !important;
  background-color: #fff !important;
  color: #9b9b9b !important;
  outline: none !important;
  will-change: opacity, transform;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}
.promo {
  border-radius: 12px;
  width: 420px !important;
  margin-top: 120px;
  color: #4264ea !important;
  background-color: #fff;
}
.form-label-promo {
  color: #505050 !important;
}
.promo-input {
  background: #ffffff !important;
  box-shadow: none !important;
  border: 1px solid #9b9b9b !important;
  box-sizing: border-box;
  border-radius: 6px !important;
  width: 200px;
  height: 60px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.orangeAmt {
  color: orange !important;
}
.yellowAmt {
  color: #ffce0b !important;
}

.stats .stats-grid {
  height: 100%;
}

.stats .stats-item .name {
  font-size: 12px;
  line-height: 16px;
  color: #000;
  padding-bottom: 2px;
  border-bottom: 2px dashed #979797;
  display: table;
  margin: 0 auto;
  text-align: center;
}

.stats .stats-item .amount {
  font-size: 48px;
  line-height: 48px;
  color: #3c10ad;
  text-align: center;
}

.stats .stats-item-grid .stats-item .amount {
  font-size: 48px;
  line-height: 48px;
  color: #384750;
  text-align: center;
}

.stats .stats-item-list-holder {
  min-width: 160px;
}

.stats .stats-item-list .grid-cell > .stats-item .grid-cell {
  min-width: 80px;
}

.stats .stats-item-list .stats-item .amount {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  display: inline-block;
  padding: 2px 14px;
  border-radius: 3px;
}

//bounce

.animated {
  background-repeat: repeat;
  background-position: left top;
  padding-top: 95px;
  margin-bottom: 60px;
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.forever {
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
//
.initialRing {
  width: 100px !important;
  height: 100px !important;
}
.initCircle {
  border-width: 5px !important;
}
.xssu-welcome-panel {
  padding: 80px;
  padding-top: 30px;
}
.noXScroll {
  overflow-x: hidden !important;
}
.navTableHead {
  border-bottom: 0.5px solid #f1f1f1;
  background-color: #f0f3fe !important;
}
.navTableHead,
.navTableBody .navRow {
  height: 60px;
  width: 100%;
  display: table;
  table-layout: fixed;
}
.toplevelshadow {
}
.navTableHead {
  width: 100%;
}
.navTable {
  width: 100%;
}
.tableDepth {
  box-shadow: 1px 6px 7px 0 rgba(0, 0, 0, 0.2),
    1px 20px 20px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-1-half {
  box-shadow: 1px 8px 11px 0 rgba(111, 111, 111, 0.18),
    2px 20px 15px 0 rgba(89, 89, 89, 0.15);
}
.z-depth-3 {
  box-shadow: 0px 15px 11px 4px rgba(171, 171, 171, 0.24),
    -2px 0px 6px 0px rgba(211, 211, 211, 0.19);
}
.z-depth-1 {
  box-shadow: inset 2px 3px 6px 0px rgba(131, 131, 131, 0.16),
    inset 0px 1px 0px 0 rgba(0, 0, 0, 0.12);
}
.z-depth-6 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
.z-depth-item {
  box-shadow: 0px 0px 11px 4px rgba(171, 171, 171, 0.24),
    -2px 0px 6px 0px rgba(211, 211, 211, 0.19);
}
//end overrides
html,
body,
#app,
#app > div {
  height: 100%;
}
body {
  overflow-x: hidden !important;
}
option {
  outline: none !important;
}

button {
  outline: none !important;
}
::placeholder {
  color: #888888;
}
.p-5 {
  padding: 5px !important;
}

.subheader {
  font-weight: 100;
  font-size: 12px;
  color: #fff;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.yellow {
  color: #ffeb3b;
  font-size: 28px !important;
  background-color: inherit !important;
}
.clickStat {
  cursor: pointer !important;
}
.clickStat:hover {
  color: orangered !important;
}
.amount {
  font-size: 28px !important;
}
.name {
  color: #4264ea !important;
  font-size: 20px;
  font-weight: 900 !important;
}
.grid-gutters {
  margin: 10px;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 20px;
}
.stats-item-grid {
  width: 250px !important;
  height: 520px;
}
.OItemCircle {
  margin: 10px;
  height: 150px;
  width: 150px;
  background-color: #f5f4fa;
  position: relative;
  border: 8px solid #4264ea;
}
.OItemCircleText {
  color: orange;
  width: 100%;
  top: 30px;
  font-weight: 900;
  text-align: center;
  padding-top: 35px;
  font-size: 40px;
}
.collapsible-header {
  outline: none !important;
}
.row-hoverable {
  background-color: inherit;
}
.row-hoverable:hover {
}
.userPositionCard {
  margin-top: 0px !important;
}
.userPositionPromo {
  margin-top: 98px !important;
}
.navTable,
.navRow .navCell {
}

.navTableBody {
  display: block;
  height: 86.2vh;
  overflow: auto;
  background-color: #f0f3fe;
}
.businessRadius {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.row-hoverable:hover {
  -o-transition: 0.25s;
  -ms-transition: 0.25s;
  -moz-transition: 0.25s;
  -webkit-transition: 0.25s;
  /* ...and now for the proper property */
  transition: 0.25s;
  background-color: rgba(138, 165, 255, 0.15) !important;
}

.ellipsesRadius {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.heading {
  flex-wrap: nowrap;
  display: flex;
  max-height: 85px !important;
  min-height: 70px !important;
}
.MessagingPanel {
  width: 80%;
  max-height: 89vh;
  height: 100%;
  //overflow:hidden;
  background-color: #fff !important;
}
.NotificationManagerPanel {
  height: 100%;
  max-height: 88vh;
  width: 68%;
}
.ChatPanel {
  height: 100%;
  max-height: 88vh;
  width: 100%;
  border-radius: 8px !important;
}
.character-counter {
  width: 0px;
  height: 0px;
  visibility: hidden !important;
}
.loading-container {
  margin-top: 250px;
  display: flex;
  justify-content: center;
}
.initial {
  height: 500px;
  background-color: #f5f4fa;
}
.shallow {
  margin-top: 0px !important;
  height: 100%;
  width: 100%;
  background-color: #f5f4fa;
}
div#Modal-0.modal.open {
  height: 48%;
}

#modal-w-50 {
  width: 50%;
}

input[type='file'] {
  display: none;
}
.file-upload-modal {
  width: 500px !important;
  height: 500px !important;
}

.text-wide {
  left: 7px !important;
  font-size: 14px !important;
}
.circle-wide {
  border-radius: 45% !important;
  width: 45px !important;
}
.resize {
  height: 3vh !important;
  width: 3vh !important;
}
.resizeText {
  font-size: 1.4vh !important;
  top: 0.6vh !important;
  left: 1.1vh !important;
}
.circleBase {
  border-radius: 50%;
}
.tiny {
  width: 5px;
  height: 5px;
}
.small-alert {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #eb5757;
}
.med-alert {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #eb5757;
}
.preloader-wrapper.small {
  width: 30px;
  height: 30px;
}
.big-alert {
  border-radius: 12px !important;
  height: 16px;
  background: #d3d3d3;
  border: #d3d3d3;
  padding: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.big-alert-text {
  font-size: 12px;
  line-height: 18px !important;
}
.standard {
  width: 30px;
  height: 30px;
  background: #d3d3d3;
  border: #d3d3d3;
  text-align: center;
  position: relative;
}
.standard-item:hover {
  background-color: #fbfbfb !important;
}
.closing:hover {
  background-color: #ff902c;
}
.smallCircle {
  width: 15px;
  height: 15px;
  background: #d3d3d3;
  border: #d3d3d3;
  color: #4264ea;
  text-align: center;
  position: relative;
}
.verySmallCircle {
  width: 10px;
  height: 10px;
  background: #d3d3d3;
  border: #d3d3d3;
  color: #4264ea;
  text-align: center;
  position: relative;
  margin-bottom: 6px;
}
.orange {
  background: #ff902c;
  color: white;
  border: none;
}
.invisibleItem {
  background-color: transparent !important;
}
.favorites {
  background: #dadada;
  color: #ff902c;
  border: none;
}

.circleText {
  font-weight: 800;
  position: absolute;
  top: 4px;
  left: 11px;
  font-size: 15px;
  line-height: 23px !important;
}
.circleTextMed {
  font-weight: 300;
  position: absolute;
  top: 0px;
  left: 6px;
  font-size: 13px;
  line-height: 23px !important;
  color: #fff;
}
.circleTextIcon {
  position: absolute;
  top: 4px;
  left: 3px;
}
.header {
  background-color: #fff;
}
.chatselector {
  margin-top: 0 !important;
  padding-top: 10px !important;
  background-color: #f5f4fa !important;
}

.backgroundCard {
  min-width: 801px !important;
  width: 81vw;
  height: 93.5vh;
  background-color: #fff;
  border-bottom: 0.5px solid #ffffff;
  overflow: hidden;
}
.fullWidthBG {
  width: 96vw !important;
}
.myClientsHeader {
  height: 9%;
  font-weight: 300;
}
.SignInFormPanel {
  margin: 60px !important;
}
.SignUpFormPanel {
  width: 920px !important;
  margin: 60px;
}

.intercom-launcher-frame {
  display: none !important;
}

.intercom-lightweight-app-launcher {
  display: none !important;
}

title:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: 'Advisory Portal';
}
title {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: 'Advisory Portal' !important;
}
.myClientsSort {
  position: relative;
  float: right;
  padding-right: 20px;
  padding-top: 10px;
  color: #4264ea;
  font-weight: 600;
  font-size: 12px;
}

.insights {
  background-color: #1b1bad;
  color: #fff;
}
.inbox {
  background-color: #4264ea;
  color: #fff;
}
.bottom-padded {
  padding-bottom: 40px !important;
}
.leftPad {
  left: 36% !important;
}
.breakdown-operator {
  float: right;
  font-size: 42px;
  line-height: 32px;
  padding-right: 20px;
}
.breakdown-lhs {
  float: left;
  font-weight: 600;
  color: #4264ea;
  font-size: 24px;
  width: 33.3%;
}
.breakdown-rhs {
  float: right;
  font-weight: 600;
  color: #a7a7a7;
  paddingtop: 5px;
  font-size: 18px;
  width: 33.3%;
}
//action centre
.actionCenterCard {
  height: 100%;
  background-color: #f5f4fa;
}
.actionCenterItem {
}
.ac-border-urgent {
  border-left: 12px solid #4264ea;
}
.ac-border-warning {
}
.ac-border-standard {
  border-left: 12px solid #828282;
}
.ac-border-success {
  border-left: 12px solid #4264ea;
}
.ac-border-urgent {
  border-left: 12px solid #ff7d31b3 !important;
}
.ac-border-urgent:hover {
  background-color: #fbfbfb !important;
}
.ac-border-warning {
}
.ac-border-standard:hover {
  background-color: #fbfbfb !important;
}
.ac-border-success:hover {
  background-color: #fbfbfb !important;
}
.ac-urgent {
  background-color: rgba(241, 80, 78, 0.2);
  color: #eb5757;
}
.ac-warning {
  background-color: rgba(242, 153, 74, 0.21);
  color: #f2994a;
}
.ac-standard {
  background-color: rgba(130, 130, 130, 0.2);
  color: #828282;
}
.ac-success {
  background-color: rgba(39, 174, 96, 0.2);
  color: #27ae60;
}
.actionCenterItem:hover {
  border: 2px solid #4264ea !important;
}
.borderless {
  border-bottom: none !important;
}
//messaging panel
.messagePanelContainer {
  color: #4264ea;
  font-size: 20px;
  font-weight: 500;
  padding-left: 0;
}
.messagePanelContentHolder {
  height: 60px;
  background-color: #f5f4fa;
}
.messagePanelCard {
  height: 80%;
  padding: 0px 0px 0px 0px;
  background-color: #fff;
}
.messagePanelQuestion {
  float: right;
  background-color: #4264ea;
  border-radius: 12px;
  padding: 10px;
  margin-right: 0px;
  color: white;
}
.messagePanelAnswerConversation {
  background-color: #fafafa;
  border-radius: 12px;
  padding: 10px;
  margin-left: 25px;
  color: #505050;
  max-width: 390px;
  margin-top: 40px;
}
.metaAnswerConversation {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 10px;
  margin-left: 25px;
  color: #505050;
  max-width: 390px;
  margin-top: 40px;
}
.messagePanelAnswer {
  background-color: #dadada;
  border-radius: 12px;
  padding: 10px;
  margin-left: 25px;
  color: #505050;
  max-width: 390px;
  margin-top: 40px;
}
.message-input-container {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  width: 100%; /*height: 12.5%;*/
  float: right;
  background-color: #fff;
  position: relative;
}
.selected {
  color: #fff !important;
}
.danger {
  color: #f18130 !important;
}

.primary {
  color: #4264ea !important;
}

.userNameCell {
  padding-top: 30px;
}
.existingTableRow {
  background-color: #f5f4fa !important;
  border-radius: 0px;
}

.transparentFade {
  padding-top: 40px;
  color: #4264ea;
  overflow: hidden;
  position: relative;
  width: 200px;
  mix-blend-mode: hard-light;
}
.noInherit {
  color: #4264ea !important;
  mix-blend-mode: normal !important;
  border-color: #0f0bff !important;
  background-color: #fafafa;
}
.noInheritOpacity {
  opacity: 1 !important;
  mix-blend-mode: normal !important;
}

.transparentFade::after {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: linear-gradient(transparent, gray);
  pointer-events: none;
}

.dropdownOption {
  padding-left: 5px;
  cursor: pointer;
  margin: 5px;
  border-radius: 4px;
  color: #505050;
}
//todo greys
.businessDetailsText {
  color: #505050 !important;
}
.dropdownOptionNotification {
  font-size: 16px;
  font-weight: 500;
  padding-left: 5px;
  cursor: pointer;
  margin: 5px;
  border-radius: 4px;
  color: #7e7e7e;
}
.dropdownOptionNotification:hover {
  font-weight: 600;
  padding-left: 5px;
  cursor: pointer;
  margin: 5px;
  border-radius: 4px;
  color: #696969;
}
.dropdownOption:hover {
  background-color: white;
  opacity: 1;
}
.emptyAlerts {
  color: #d8d8d8;
  background-color: #d8d8d8;
}
.orange-hover:hover {
  background-color: #ff902c;
  color: white;
  opacity: 1;
}

.white-hover:hover {
  background-color: white !important;
}
.blue-hover:hover {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  /* ...and now for the proper property */
  transition: 0.5s;
  color: #4264ea !important;
}
.white-text-hover:hover {
  color: white !important;
}
.grey-hover:hover {
  color: #505050 !important;
}
.message-input {
  border-radius: 18px !important;
  background-color: #f5f4fa !important;
  width: 80% !important;
  padding-left: 10px !important;
  margin-top: 45px !important;
  margin-left: 10px !important;
  height: 50px !important;
  border: 1px solid #d8d8d8 !important;
  box-shadow: none !important;
}
.message-input-chat-2 {
  border-radius: 18px !important;
  background-color: #f5f4fa !important;
  width: 80% !important;
  padding-left: 10px !important;
  margin-top: 45px;
  margin-left: 10px !important;
  height: 50px !important;
  border-bottom: none !important;
  box-shadow: none !important;
  border: 1px solid #d8d8d8 !important;
}
.message-input-chat {
  border-radius: 18px !important;
  background-color: #f5f4fa !important;
  width: 80% !important;
  padding-left: 8px !important;
  margin: 10px 10px !important;
  height: 40px !important;
  border-bottom: none !important;
  box-shadow: none !important;
}
.message-send-button {
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #4264ea;
  position: absolute;
  top: 50px;
  right: 26px;
  cursor: pointer;
}
.conversation-starters-button {
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #ff902c;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
.csb-selected {
  background-color: #e9763e !important;
}
.conversationStarter {
  cursor: pointer;
  width: 150px;
  height: 40px;
  line-height: 36px;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  color: #4264ea;
  background-color: white;
  border: 1px solid transparent;
}
.conversationStarter:hover {
  border: 1px solid #4264ea;
}
.cs-selected {
  border: 2px solid #4264ea !important;
}
.buttonIsDisabled {
  background-color: #d8d8d8;
}
//signup wrapper
.sign-up {
  padding: 30px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.sign-up ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sign-up-error {
  color: #ff0000;
}

.sign-up-popup-close {
  border-radius: 25px;
  height: 50px;
  width: 50px;
  background: #4364e9;
  border: none;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
  outline: none;
}

.sign-up-popup-close:hover {
  background: #13dfe0;
}

@media only screen and (max-width: 550px) {
  .sign-up-popup-close {
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
  }
}

.welcome-screen-heading {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #545454 !important;
}

.user-details p {
  font-size: 16px !important;
  color: #545454 !important;
}
.text-field {
  display: block !important;
  width: 100% !important;
  max-width: 260px !important;
  margin: 5px auto !important;
  border-radius: 5px !important;
  padding: 10px !important;
  border: 1px solid #d1d8de !important;
  height: 40px;
  font-family: $font-body;
  font-size: 14px;
}

//signup
.sign-up-button {
  display: block;
  max-width: 280px;
  background: none;
  border-radius: 40px !important;
  border: none;
  margin: 10px !important;
  padding: 13px 0 8px;
  cursor: pointer;
  outline: none;
  height: 52px !important;
  width: 240px !important;
}

.social-sign-up-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
  transition: all 0.2s ease-in-out;
}

.xero-sign-up-icon {
  width: 26px;
  height: 27px;
  position: relative;
  top: -4px;
}

.social-sign-up-text {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 5px;
  transition: all 0.1s ease-in-out;
}

.sign-up-button {
  border-radius: 20px;
}
.sign-up-button:hover .social-sign-up-icon,
.sign-up-button:hover .social-sign-up-text {
  transform: scale(1.1);
  font-size: 20px;
}

.sign-up-button.google-sign-up {
  border: 1px solid #d1d8de;
}

.sign-up-button.facebook-sign-up {
  background: #385898;
  color: #ffffff;
}

.sign-up-button.xero-sign-up {
  border: 1px solid #d1d8de;
  padding-top: 9px;
  padding-bottom: 3px;
}

.text-field {
  display: block !important;
  width: 100% !important;
  max-width: 260px !important;
  margin: 5px auto !important;
  border-radius: 5px !important;
  padding: 10px !important;
  border: 1px solid #d1d8de !important;
  height: 40px !important;
  font-family: $font-body;
  font-size: 14px;
}

.disclaimer {
  margin: 1em 0;
  font-size: 14px;
  color: #b7b7b7;
}

.sign-up-form-title {
  margin: 0 0 1em !important;
  font-size: 20px !important;
  font-weight: bold;
}

.react-phone-number-input__phone {
  font-family: $font-body;
  border: none;
}

.help-alert-header {
  color: #0a1e70;
  font-size: 14px;
  margin-bottom: 10px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-left: 25px;
  margin-bottom: 1rem;
  width: 50vw;

  &--full {
    grid-template-columns: 1fr;
  }

  &__col {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1470px) {
  .form-row:not(.no-wrap) {
    grid-template-columns: 1fr;
  }
}

/** Styles for tags input in client settings */
.tags-input {
  width: 100%;

  & input {
    width: 100%;
    margin: 0 0 4px 0 !important;
    padding: 0 !important;
  }

  &__popover {
    &--item {
      padding: 0.2rem;
      border-radius: 0.2rem;

      &:hover:enabled {
        color: $clr-black !important;
        background-color: $clr-bg-blue !important;
        cursor: pointer;
      }

      &.bp3-active {
        background-color: $clr-bg-blue !important;
        color: $clr-black !important;
      }
    }

  }
}

.custom-tooltip {
  max-width: 275px;
  line-height: 1.5rem;
  font-weight: 400;

  & p {
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
}
